import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import tw from 'twin.macro';

const StyledMenuButton = tw(Menu.Button)`
    inline-flex
    justify-center
    2xl:w-32
    w-24
    px-3
    py-3
    text-xs
    font-medium
    text-white
    rounded
    focus:outline-none
    focus-visible:ring-2
    focus-visible:ring-white
    focus-visible:ring-opacity-75
`;

const MenuButton = ({ buttonText, buttonColor }) => (
  <div>
    <StyledMenuButton className={`bg-${buttonColor}-500`}>
      {buttonText}
      <ChevronDownIcon className="w-4 h-4 ml-1 -mr-1 text-violet-200 hover:text-violet-100" aria-hidden="true" />
    </StyledMenuButton>
  </div>
);

MenuButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonColor: PropTypes.string,
};

MenuButton.defaultProps = {
  buttonColor: 'blue',
};

export default MenuButton;
