import React from 'react';
import tw from 'twin.macro';
import footerImage from 'assets/images/layerback-people.png';

const StyledWrapper = tw.div`
    absolute
    bottom-0
    left-0
    w-screen
`;

const Footer = () => (
  <StyledWrapper>
    <img src={footerImage} alt="Footer" />
  </StyledWrapper>
);

export default Footer;
