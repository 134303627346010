/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { cardTypesSelector, findCardTypes } from 'features/admin/cardTypes/CardTypesSlice';
import Modal, {
  StyledHeading,
  StyledModalContentWrapper,
  StyledButtonsWrapper,
} from 'components/molecules/Modal/Modal';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import { StyledFieldWrapper, StyledErrorMessage } from 'templates/AuthTemplate';
import Select from 'components/atoms/Select/Select';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import { findLanguages, languageSelector } from 'features/admin/languages/LanguagesSlice';
import { findCards, updateCard } from '../CardSlice';

const schema = yup.object().shape({
  name: yup.string().required(),
});

const EditCardModal = ({ open, setOpen, item }) => {
  const { _id, name, cardType: cardTypeOld, translations } = item;

  const [selectedCardType, setSelectedCardType] = useState(cardTypeOld);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cardTypes = useSelector(cardTypesSelector);
  const languages = useSelector(languageSelector);

  const { items: cardTypesItems } = cardTypes;
  const { items: languageItems } = languages;

  useEffect(() => {
    if (cardTypesItems.length === 0) {
      dispatch(findCardTypes());
    }
    if (languageItems.length === 0) {
      dispatch(findLanguages());
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name,
    },
  });

  const onSubmit = (dataFromForm) => {
    const { name: nameFromFrom, status, ...rest } = dataFromForm;

    const updatedTranslations = [];

    languageItems.forEach((languageItem) => {
      if (rest[languageItem.code]) {
        updatedTranslations.push({
          language: languageItem._id,
          text: rest[languageItem.code],
        });
      }
    });

    const data = {
      _id,
      name: nameFromFrom,
      cardType: selectedCardType._id,
      translations: updatedTranslations,
    };

    dispatch(updateCard(data)).then(() => dispatch(findCards()));
    setOpen(false);
  };

  return (
    <Modal open={open} close={setOpen}>
      <form onSubmit={handleSubmit(onSubmit)} method="POST">
        <StyledModalContentWrapper>
          <StyledHeading as="h3">
            {t('edit_card_modal_heading')}: &quot;{name}&quot;
          </StyledHeading>

          <StyledFieldWrapper>
            <Label htmlFor="name">{t('card_modal_input_card_name')}</Label>
            <Input type="text" {...register('name')} placeholder={t('card_modal_input_card_name_placeholder')} />
            <StyledErrorMessage>{errors.name?.message}</StyledErrorMessage>
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="cardType">{t('card_modal_select_card_type')}</Label>
            <Select
              items={cardTypesItems}
              name="cardType"
              selectedItem={selectedCardType}
              setSelectedItem={setSelectedCardType}
            />
          </StyledFieldWrapper>

          {languageItems.map((languageItem) => {
            const translation = translations.find(
              (singleTranslation) => singleTranslation.language === languageItem._id,
            );
            return (
              <StyledFieldWrapper>
                <Label htmlFor={languageItem.code}>
                  {t('card_modal_input_translation_in')} {languageItem.name}
                </Label>
                <Input
                  type="text"
                  {...register(languageItem.code)}
                  defaultValue={(translation && translation.text) || null}
                  placeholder={`${t('card_modal_input_translation_in_placeholder')} ${languageItem.name}`}
                />
              </StyledFieldWrapper>
            );
          })}
        </StyledModalContentWrapper>

        <StyledButtonsWrapper>
          <ModalButton type="submit" color="blue">
            {t('game_type_modal_button_save')}
          </ModalButton>
          <ModalButton type="button" onClick={() => setOpen(false)}>
            {t('card_modal_button_cancel')}
          </ModalButton>
        </StyledButtonsWrapper>
      </form>
    </Modal>
  );
};

EditCardModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
};

export default EditCardModal;
