import axios from 'axios';
import { config } from 'constants.js';
import authHeader from './authHeader';

const register = ({ username, email, password, isOrganizationAccount }) =>
  axios.post(`${config.url.API_URL}/auth/register`, {
    username,
    email,
    password,
    isOrganizationAccount,
  });

const login = ({ username, password }) =>
  axios.post(`${config.url.API_URL}/auth/login`, {
    username,
    password,
  });

const forgotPassword = ({ email }) =>
  axios.post(`${config.url.API_URL}/auth/forgotPassword`, {
    email,
  });

const resetPassword = ({ token, password }) =>
  axios.post(`${config.url.API_URL}/auth/resetPassword`, {
    token,
    newPassword: password,
  });

const setOrganizationId = ({ organization }) =>
  axios.post(
    `${config.url.API_URL}/auth/setOrganization`,
    {
      organizationId: organization,
    },
    { headers: authHeader() },
  );

const connectWithOrganization = ({ email, userEmail, packageId }) =>
  axios.post(
    `${config.url.API_URL}/auth/connectWithOrganization`,
    {
      email,
      userEmail,
      packageId,
    },
    { headers: authHeader() },
  );

const getUserByToken = () => axios.get(`${config.url.API_URL}/auth/profile`, { headers: authHeader() });

const getUserByEmail = (email) =>
  axios.get(`${config.url.API_URL}/users/getUserByEmail?${email}`, { headers: authHeader() });

export default {
  register,
  login,
  forgotPassword,
  resetPassword,
  setOrganizationId,
  connectWithOrganization,
  getUserByToken,
  getUserByEmail,
};
