import { isLogin, isLoginAsAdmin, isLoginAsLeader } from 'features/auth/isLogin';
import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';

const StyledWrapper = tw.nav`
    md:ml-auto
    flex
    flex-wrap
    items-center
    text-base
    justify-center
`;

const NavItem = tw.span`
    mr-5
    text-white
`;

const NavMenu = () => {
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <Link to="/">
        <NavItem>{t('nav_item_home')}</NavItem>
      </Link>
      {!isLogin() && (
        <>
          <Link to={routes.login}>
            <NavItem>{t('nav_item_login')}</NavItem>
          </Link>
          <Link to={routes.register}>
            <NavItem>{t('nav_item_register')}</NavItem>
          </Link>
        </>
      )}
      {isLoginAsLeader() && (
        <>
          <Link to={routes.leaderBoard.games}>
            <NavItem>{t('nav_item_leader_account')}</NavItem>
          </Link>
        </>
      )}
      {isLoginAsAdmin() && (
        <>
          <Link to={routes.adminBoard.cards}>
            <NavItem>{t('nav_item_admin_account')}</NavItem>
          </Link>
        </>
      )}
      {isLogin() && (
        <>
          <Link to={routes.logout}>
            <NavItem>{t('nav_item_logout')}</NavItem>
          </Link>
        </>
      )}
    </StyledWrapper>
  );
};

export default NavMenu;
