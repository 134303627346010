import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { deleteGamesPackage } from 'features/admin/packages/PackagesSlice';
import Modal, {
  StyledModalContentWrapper,
  StyledButtonsWrapper,
  StyledHeading,
} from 'components/molecules/Modal/Modal';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import Paragraph from 'components/atoms/Paragraph';

const DeleteGamesPackageModal = ({ open, setOpen, name, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDeleteButtonClick = () => {
    dispatch(deleteGamesPackage(id));
    setOpen(false);
  };

  return (
    <Modal open={open} close={setOpen}>
      <StyledModalContentWrapper>
        <StyledHeading as="h3">
          {t('delete_package_modal_heading')}: &quot;{name}&quot;
        </StyledHeading>
        <Paragraph>
          {t('delete_package_modal_confirm_text')}: &quot;{name}&quot;
        </Paragraph>
      </StyledModalContentWrapper>
      <StyledButtonsWrapper>
        <ModalButton type="button" color="red" onClick={handleDeleteButtonClick}>
          {t('delete_package_modal_delete')}
        </ModalButton>
        <ModalButton type="button" onClick={() => setOpen(false)}>
          {t('delete_package_modal_cancel')}
        </ModalButton>
      </StyledButtonsWrapper>
    </Modal>
  );
};

DeleteGamesPackageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default DeleteGamesPackageModal;
