import React, { useState } from 'react';
import { Transition } from '@tailwindui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import colors from 'theme/colours';
import SelectedColorItem from './SelectedColorItem';
import SelectColorItem from './SelectColorItem';

const ColorSelect = ({ selectedColor, setSelectedColor }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className="mt-1 relative">
        <button
          onClick={() => setOpen(!open)}
          type="button"
          className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left sm:leading-7 cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
        >
          {selectedColor ? (
            <SelectedColorItem color={selectedColor} text={selectedColor} />
          ) : (
            <SelectedColorItem text="None" setOpen={setOpen} />
          )}
          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon className="h-5 w-5 text-gray-400" />
          </span>
        </button>
        <Transition
          show={open}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <ul
            className="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-scroll focus:outline-none sm:text-sm"
            tabIndex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-option-3"
          >
            <SelectColorItem
              text="None"
              key="none"
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              setOpen={setOpen}
            />
            {colors.map((color) => (
              <SelectColorItem
                color={color}
                key={color}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                setOpen={setOpen}
              />
            ))}
          </ul>
        </Transition>
      </div>
    </div>
  );
};

ColorSelect.propTypes = {
  selectedColor: PropTypes.string,
  setSelectedColor: PropTypes.func.isRequired,
};

ColorSelect.defaultProps = {
  selectedColor: null,
};

export default ColorSelect;
