/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import toast from 'react-hot-toast';
import SocketContext from 'socket/socket';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { routes } from 'routes';
import { loginUser, userSelector, clearState, getUserByToken, shareSubscription } from 'features/auth/AuthSlice';
import AuthTemplate, {
  StyledForm,
  StyledHeading,
  StyledFieldWrapper,
  StyledErrorMessage,
  StyledButtonsWrapper,
  StyledLinkWrapper,
  StyledLink,
} from 'templates/AuthTemplate';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import Loader from 'components/atoms/Loader';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const LoginPage = () => {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const user = useSelector(userSelector);
  const { isSuccess, isFetching, isError, errorMessage, getUserSuccess, getUserError, roles } = user;

  const onSubmit = (data) => {
    dispatch(loginUser(data));
    dispatch(clearState());
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(getUserByToken());
    }
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [isError]);

  const handleKeyDown = (e, submit) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submit();
    }
  };

  useEffect(() => {
    if (getUserError) {
      localStorage.removeItem('token');
      dispatch(clearState());
    }
  }, [getUserError]);

  useEffect(() => {
    if (isSuccess || getUserSuccess) {
      // check that user have shared Package
      const parsed = queryString.parse(location.search);
      if (parsed.packageId) {
        const data = {
          userId: parsed.userId,
          sharedWith: parsed.sharedWith,
          packageId: parsed.packageId,
        };
        dispatch(shareSubscription(data));
      }
      socket.auth = { token: localStorage.getItem('token') };
      socket.disconnect().connect();
      dispatch(clearState());
      if (roles.includes('admin')) {
        history.push('/admin-board/cards');
      } else if (roles.includes('organization')) {
        history.push('/leader-board/buy-package');
      } else if (roles.includes('user')) {
        history.push('/leader-board/games');
      } else {
        history.push('/');
      }
    }
  }, [isSuccess, getUserSuccess]);

  return (
    <AuthTemplate>
      <StyledHeading as="h2">{t('login_page_heading')}</StyledHeading>
      <StyledForm
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          handleKeyDown(e, handleSubmit(onSubmit));
        }}
        method="POST"
      >
        <StyledFieldWrapper>
          <Label htmlFor="email">{t('login_page_input_email')}</Label>
          <Input type="text" {...register('email')} placeholder={t('login_page_input_email_placeholder')} />
          <StyledErrorMessage>{errors.email?.message}</StyledErrorMessage>
        </StyledFieldWrapper>
        <StyledFieldWrapper>
          <Label htmlFor="password">{t('login_page_input_password')}</Label>
          <Input
            type="password"
            {...register('password')}
            placeholder={t('login_page_input_password_placeholder')}
            autoComplete="current-password"
          />
          <StyledErrorMessage>{errors.password?.message}</StyledErrorMessage>
        </StyledFieldWrapper>
        <StyledButtonsWrapper>
          <Button onClick={history.goBack}>{t('login_page_button_cancel')}</Button>
          <Button type="submit">
            {isFetching && <Loader color="white" size={4} />} {t('login_page_button_sign_in')}
          </Button>
        </StyledButtonsWrapper>
      </StyledForm>
      <StyledLinkWrapper>
        <StyledLink to={routes.register}>{t('login_page_have_not_account')}</StyledLink>
        <StyledLink to={routes.forgotPassword}>{t('login_page_forgot_password')}</StyledLink>
      </StyledLinkWrapper>
    </AuthTemplate>
  );
};

export default LoginPage;
