/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import Modal, {
  StyledHeading,
  StyledModalContentWrapper,
  StyledButtonsWrapper,
} from 'components/molecules/Modal/Modal';
import Label from 'components/atoms/Label';
import { StyledFieldWrapper } from 'templates/AuthTemplate';
import Select from 'components/atoms/Select/Select';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import { findGamesPackages, gamesPackagesSelector } from 'features/admin/packages/PackagesSlice';
import { addPackageToUser, findUsers } from '../UsersSlice';

const AddPackageModal = ({ open, setOpen, userId }) => {
  const [selectedGamesPackage, setSelectedGamesPackage] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gamesPackages = useSelector(gamesPackagesSelector);

  const { items: gamesPackagesItems } = gamesPackages;

  useEffect(() => {
    if (gamesPackagesItems.length === 0) {
      dispatch(findGamesPackages());
    }
  }, []);

  const handleAddPackage = () => {
    dispatch(addPackageToUser({ userId, gamesPackageId: selectedGamesPackage._id })).then(() => dispatch(findUsers()));
    setOpen(false);
  };

  return (
    <Modal open={open} close={setOpen}>
      <StyledModalContentWrapper>
        <StyledHeading as="h3">{t('add_package_to_user_modal_heading')}:</StyledHeading>

        <StyledFieldWrapper>
          <Label htmlFor="cardType">{t('add_package_to_user_modal_select_package_label')}</Label>
          <Select
            items={gamesPackagesItems}
            name="gamesPackages"
            selectedItem={selectedGamesPackage}
            setSelectedItem={setSelectedGamesPackage}
          />
        </StyledFieldWrapper>
      </StyledModalContentWrapper>
      <StyledButtonsWrapper>
        <ModalButton type="submit" color="blue" onClick={() => handleAddPackage()}>
          {t('add_package_to_user_modal_button_new')}
        </ModalButton>
        <ModalButton type="button" onClick={() => setOpen(false)}>
          {t('add_package_to_user_modal_button_cancel')}
        </ModalButton>
      </StyledButtonsWrapper>
    </Modal>
  );
};

AddPackageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default AddPackageModal;
