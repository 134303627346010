/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import MultipleSelect from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { cardTypesSelector, findCardTypes } from 'features/admin/cardTypes/CardTypesSlice';
import Modal, {
  StyledHeading,
  StyledModalContentWrapper,
  StyledButtonsWrapper,
} from 'components/molecules/Modal/Modal';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import { StyledFieldWrapper, StyledErrorMessage } from 'templates/AuthTemplate';
import Select from 'components/atoms/Select/Select';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import { findGameTypes, updateGameType } from 'features/admin/gameTypes/GameTypesSlice';
import { categoriesSelector, findCategories } from 'features/admin/categories/CategoriesSlice';

const schema = yup.object().shape({
  name: yup.string().required(),
  firstCardTypeStartCards: yup.number().integer().min(0).max(6),
  secondCardTypeStartCards: yup.number().integer().min(0).max(6),
  thirdCardTypeStartCards: yup.number().integer().min(0).max(6),
  instructionLink: yup.string().url(),
});

const EditGameTypeModal = ({ open, setOpen, item, languages }) => {
  const {
    _id,
    name,
    initialDecks,
    category: categoryOld,
    instructionLink,
    availableLanguages: availableLanguagesOld,
  } = item;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cardTypes = useSelector(cardTypesSelector);
  const { items: cardTypeItems } = cardTypes;

  const categories = useSelector(categoriesSelector);
  const { items: categoriesItems } = categories;

  const availableLanguagesIdsOld = availableLanguagesOld.map((availableLanguage) => availableLanguage._id);

  const [firstCardType, setFirstCardType] = useState(initialDecks[0]?.cardType || null);
  const [secondCardType, setSecondCardType] = useState(initialDecks[1]?.cardType || null);
  const [thirdCardType, setThirdCardType] = useState(initialDecks[2]?.cardType || null);
  const [category, setCategory] = useState(categoryOld);
  const [languagesIds, setLanguagesIds] = useState(availableLanguagesIdsOld);

  const options = languages.map((language) => {
    const { _id: languageId, name: languageName } = language;
    return { value: languageId, label: languageName };
  });

  const defaultOptions = availableLanguagesOld.map((availableLanguageOld) => {
    const { _id: languageId, name: languageName } = availableLanguageOld;
    return { value: languageId, label: languageName };
  });

  useEffect(() => {
    dispatch(findCardTypes());
    dispatch(findCategories());
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name,
      firstCardTypeStartCards: initialDecks[0]?.startCards || 0,
      secondCardTypeStartCards: initialDecks[1]?.startCards || 0,
      thirdCardTypeStartCards: initialDecks[2]?.startCards || 0,
      instructionLink,
    },
  });

  const onSubmit = (dataFromForm) => {
    const {
      name: nameFromFrom,
      firstCardTypeStartCards: firstCardTypeStartCardsFromForm,
      secondCardTypeStartCards: secondCardTypeStartCardsFromForm,
      thirdCardTypeStartCards: thirdCardTypeStartCardsFromForm,
      instructionLink: instructionLinkFromForm,
    } = dataFromForm;
    const newInitialDecks = [];
    if (firstCardType) {
      newInitialDecks.push({
        cardType: firstCardType,
        startCards: firstCardTypeStartCardsFromForm,
      });
    }
    if (secondCardType) {
      newInitialDecks.push({
        cardType: secondCardType,
        startCards: secondCardTypeStartCardsFromForm,
      });
    }
    if (thirdCardType) {
      newInitialDecks.push({
        cardType: thirdCardType,
        startCards: thirdCardTypeStartCardsFromForm,
      });
    }

    const updatedGameType = dispatch(
      updateGameType({
        _id,
        name: nameFromFrom,
        initialDecks: newInitialDecks,
        category: category ? category._id : null,
        instructionLink: instructionLinkFromForm,
        availableLanguages: languagesIds,
      }),
    );

    updatedGameType.then(() => {
      dispatch(findGameTypes());
    });

    setOpen(false);
  };

  const handleChangeMultiSelect = (selectedOptions) => {
    setLanguagesIds(selectedOptions.map((selectedOpiton) => selectedOpiton.value));
  };

  return (
    <Modal open={open} close={setOpen} scroll>
      <form onSubmit={handleSubmit(onSubmit)} method="POST">
        <StyledModalContentWrapper>
          <StyledHeading as="h3">
            {t('edit_game_type_modal_heading')}: &quot;{name}&quot;
          </StyledHeading>

          <StyledFieldWrapper>
            <Label htmlFor="name">{t('game_type_modal_input_game_type_name')}</Label>
            <Input
              type="text"
              {...register('name')}
              placeholder={t('game_type_modal_input_game_type_name_placeholder')}
            />
            <StyledErrorMessage>{errors.name?.message}</StyledErrorMessage>
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="firstCardType">{t('game_type_modal_select_first_card_type')}</Label>
            <Select
              items={cardTypeItems}
              name="firstCardType"
              selectedItem={firstCardType}
              setSelectedItem={setFirstCardType}
            />
          </StyledFieldWrapper>
          <StyledFieldWrapper>
            <Label htmlFor="firstCardTypeStartCards">
              {t('game_type_modal_select_first_card_type_cards_start_count')}
            </Label>
            <Input type="number" {...register('firstCardTypeStartCards')} min="0" max="6" />
            <StyledErrorMessage>{errors.firstCardTypeStartCards?.message}</StyledErrorMessage>
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="secondCardType">{t('game_type_modal_select_second_card_type')}</Label>
            <Select
              items={cardTypeItems}
              name="secondCardType"
              selectedItem={secondCardType}
              setSelectedItem={setSecondCardType}
            />
          </StyledFieldWrapper>
          <StyledFieldWrapper>
            <Label htmlFor="secondCardTypeStartCards">
              {t('game_type_modal_select_second_card_type_cards_start_count')}
            </Label>
            <Input type="number" {...register('secondCardTypeStartCards')} min="0" max="6" />
            <StyledErrorMessage>{errors.secondCardTypeStartCards?.message}</StyledErrorMessage>
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="thirdCardType">{t('game_type_modal_select_third_card_type')}</Label>
            <Select
              items={cardTypeItems}
              name="thirdCardType"
              selectedItem={thirdCardType}
              setSelectedItem={setThirdCardType}
            />
          </StyledFieldWrapper>
          <StyledFieldWrapper>
            <Label htmlFor="thirdCardTypeStartCards">
              {t('game_type_modal_select_third_card_type_cards_start_count')}
            </Label>
            <Input type="number" {...register('thirdCardTypeStartCards')} min="0" max="6" />
            <StyledErrorMessage>{errors.thirdCardTypeStartCards?.message}</StyledErrorMessage>
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="category">{t('game_type_modal_select_category')}</Label>
            <Select items={categoriesItems} name="categorires" selectedItem={category} setSelectedItem={setCategory} />
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="languages">{t('game_type_modal_multiple_select_available_languages')}</Label>
            <MultipleSelect
              name="languages"
              options={options}
              defaultValue={defaultOptions}
              isMulti
              onChange={(selectedOptions) => handleChangeMultiSelect(selectedOptions)}
            />
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="instructionLink">{t('game_type_modal_input_instruction_link')}</Label>
            <Input type="text" {...register('instructionLink')} />
            <StyledErrorMessage>{errors.instructionLink?.message}</StyledErrorMessage>
          </StyledFieldWrapper>
        </StyledModalContentWrapper>
        <StyledButtonsWrapper>
          <ModalButton type="submit" color="blue">
            {t('game_type_modal_button_save')}
          </ModalButton>
          <ModalButton type="button" onClick={() => setOpen(false)}>
            {t('game_type_modal_button_cancel')}
          </ModalButton>
        </StyledButtonsWrapper>
      </form>
    </Modal>
  );
};

EditGameTypeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  languages: PropTypes.array.isRequired,
};

export default EditGameTypeModal;
