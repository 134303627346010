/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import Modal, {
  StyledHeading,
  StyledModalContentWrapper,
  StyledButtonsWrapper,
} from 'components/molecules/Modal/Modal';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import { StyledFieldWrapper, StyledErrorMessage } from 'templates/AuthTemplate';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import { createCategory } from '../CategoriesSlice';

const schema = yup.object().shape({
  name: yup.string().required(),
});

const NewCategoryModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (dataFromForm) => {
    dispatch(createCategory(dataFromForm));
    setOpen(false);
  };

  return (
    <Modal open={open} close={setOpen}>
      <form onSubmit={handleSubmit(onSubmit)} method="POST">
        <StyledModalContentWrapper>
          <StyledHeading as="h3">{t('new_category_modal_heading')}</StyledHeading>
          <StyledFieldWrapper>
            <Label htmlFor="name">{t('new_category_modal_input_name')}</Label>
            <Input type="text" {...register('name')} placeholder={t('new_category_modal_input_name_placeholder')} />
            <StyledErrorMessage>{errors.name?.message}</StyledErrorMessage>
          </StyledFieldWrapper>
        </StyledModalContentWrapper>
        <StyledButtonsWrapper>
          <ModalButton type="submit" color="blue">
            {t('new_category_modal_button_add_new')}
          </ModalButton>
          <ModalButton type="button" onClick={() => setOpen(false)}>
            {t('new_category_modal_button_cancel')}
          </ModalButton>
        </StyledButtonsWrapper>
      </form>
    </Modal>
  );
};

NewCategoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default NewCategoryModal;
