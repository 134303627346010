import axios from 'axios';
import { config } from 'constants.js';
import authHeader from 'features/auth/authHeader';

const create = ({ name, code }) =>
  axios.post(
    `${config.url.API_URL}/languages`,
    {
      name,
      code,
    },
    { headers: authHeader() },
  );

const findOneById = ({ languageId }) =>
  axios.get(`${config.url.API_URL}/languages/${languageId}`, { headers: authHeader() });

const find = () => axios.get(`${config.url.API_URL}/languages`, { headers: authHeader() });

const update = ({ _id, name, code, status }) =>
  axios.patch(
    `${config.url.API_URL}/languages/${_id}`,
    {
      name,
      code,
      status,
    },
    { headers: authHeader() },
  );

const remove = ({ languageId }) =>
  axios.delete(`${config.url.API_URL}/languages/${languageId}`, { headers: authHeader() });

export default {
  create,
  findOneById,
  find,
  update,
  remove,
};
