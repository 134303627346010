import tw, { styled } from 'twin.macro';

const Paragraph = styled.p(({ textXs, textSm, center }) => [
  tw`
        leading-relaxed
    `,
  textXs && tw`text-xs`,
  textSm && tw`text-sm`,
  center && tw`text-center`,
]);

export default Paragraph;
