import React from 'react';
import tw from 'twin.macro';

const FlowSectionWrapper = tw.div`
grid
grid-cols-5
text-sm
gap-2
text-white
font-medium
focus:outline-none
focus-visible:ring-2
focus-visible:ring-white
focus-visible:ring-opacity-75
`;
const FlowBlock = tw.div`
grid
grid-rows-3
justify-items-center
items-center
text-sm
rounded-xl
p-3
max-h-60 
h-auto
lg:h-40
w-auto
`;
const FlowBlockTitle = tw.span`
-mt-16
lg:-mt-10
font-bold
`;
const BlockContent = tw.span`
-mt-12
lg:-mt-6
self-start
`;
const FlowBlockNumber = tw.span`
-mt-4
bg-yellow-500
text-sm
w-6
h-6
rounded-xl
font-bold
flex
justify-center
items-center
`;

const FlowSection = () => (
  <FlowSectionWrapper>
    <FlowBlock className="bg-indigo-500 text-sm">
      <FlowBlockNumber>1</FlowBlockNumber>
      <FlowBlockTitle className="text-sm">Create Game</FlowBlockTitle>
      <BlockContent className="text-sm">
        Choose game and card language(s) OBS! A game is used when it is started
      </BlockContent>
    </FlowBlock>
    <FlowBlock className="bg-gray-500">
      <FlowBlockNumber className="text-sm">2</FlowBlockNumber>
      <FlowBlockTitle className="text-sm">Copy Link</FlowBlockTitle>
      <BlockContent className="text-sm">
        Copy the link and send to the players who will attend (max 8 persons per game including Leader)
      </BlockContent>
    </FlowBlock>
    <FlowBlock className="bg-blue-500">
      <FlowBlockNumber className="text-sm">3</FlowBlockNumber>
      <FlowBlockTitle className="text-sm">Join Board</FlowBlockTitle>
      <BlockContent className="text-sm">
        Choose to join as Leader Player or Leader Fascilitator. As a player you can leave during the game.
      </BlockContent>
    </FlowBlock>
    <FlowBlock className="bg-indigo-500">
      <FlowBlockNumber className="text-sm">4</FlowBlockNumber>
      <FlowBlockTitle className="text-sm">Start/Pause/Continue</FlowBlockTitle>
      <BlockContent className="text-sm">
        When a game is started, no new players can join. Pause and restart the game if needed.
      </BlockContent>
    </FlowBlock>
    <FlowBlock className="bg-red-500">
      <FlowBlockNumber className="text-sm">5</FlowBlockNumber>
      <FlowBlockTitle className="text-sm">Delete</FlowBlockTitle>
      <BlockContent className="text-sm mt-8">Delete the game when the game is over.</BlockContent>
    </FlowBlock>
  </FlowSectionWrapper>
);

export default FlowSection;
