import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import AuthTemplate, { StyledHeading } from 'templates/AuthTemplate';
import Loader from 'components/atoms/Loader';
import Paragraph from 'components/atoms/Paragraph';
import { routes } from 'routes';

const OrderConfirmationPage = () => {
  const [redirectNow, setRedirectNow] = useState(false);
  setTimeout(() => setRedirectNow(true), 5000);

  return redirectNow ? (
    <Redirect to={routes.leaderBoard.subscriptions} />
  ) : (
    <AuthTemplate>
      <StyledHeading as="h2" className="mb-12">
        Order Processing
      </StyledHeading>
      <Loader />
      <Paragraph className="mt-8" textSm>
        You will be automatically redirected in 5 seconds.
      </Paragraph>
    </AuthTemplate>
  );
};

export default OrderConfirmationPage;
