import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import tw, { styled } from 'twin.macro';
import toast from 'react-hot-toast';
import { Grid, _ } from 'gridjs-react';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { PencilAltIcon, XIcon } from '@heroicons/react/solid';
import AdminTemplate from 'templates/AdminTemplate';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import StatusButton from 'components/atoms/StatusButton/StatusButton';
import { categoriesSelector, findCategories, clearState } from './CategoriesSlice';
import NewCategoryModal from './NewCategoryModal/NewCategoryModal';
import DeleteCategoryModal from './DeleteCategoryModal/DeleteCategoryModal';
import EditCategoryModal from './EditCategoryModal/EditCategoryModal';

const StyledHeading = tw(Heading)`
  text-2xl
  mt-5
  pt-3
`;

const StyledButtonWrapper = tw.div`
    block
    w-36
    mt-5
    mr-auto
`;

const StyledButton = styled(Button)(({ color }) => [
  tw`
      w-16
      border-0
      py-1
      px-2
      ml-2
      text-xs
    `,
  color === 'red' && tw`bg-red-500 hover:bg-red-600`,
  color === 'blue' && tw`bg-blue-500 hover:bg-blue-600`,
]);

const CategoriesPage = () => {
  const [openNewCategoryModal, setOpenNewCategoryModal] = useState(false);
  const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false);
  const [openEditCategoryModal, setOpenEditCategoryModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const categories = useSelector(categoriesSelector);
  const { isSuccess, isError, errorMessage, items } = categories;

  const itemsDTO = items.map((item) => {
    const { _id, name, status } = item;

    return {
      id: _id,
      name,
      status,
    };
  });

  useEffect(() => {
    dispatch(findCategories());
  }, []);

  useEffect(() => () => dispatch(clearState()), []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  const handleOpenEditCategoryModal = (itemToEditId) => {
    setItemToEdit(items.find((item) => item._id === itemToEditId));
    setOpenEditCategoryModal(true);
  };

  const handleOpenDeleteCategoryModal = (itemToDeleteId) => {
    setItemToDelete(items.find((item) => item._id === itemToDeleteId));
    setOpenDeleteCategoryModal(true);
  };

  return (
    <AdminTemplate>
      <>
        {openNewCategoryModal && <NewCategoryModal open={openNewCategoryModal} setOpen={setOpenNewCategoryModal} />}
        {openDeleteCategoryModal && (
          <DeleteCategoryModal
            open={openDeleteCategoryModal}
            setOpen={setOpenDeleteCategoryModal}
            id={itemToDelete._id}
            name={itemToDelete.name}
          />
        )}
        {openEditCategoryModal && (
          <EditCategoryModal open={openEditCategoryModal} setOpen={setOpenEditCategoryModal} item={itemToEdit} />
        )}
        <StyledHeading as="h2">{t('categories_page_heading')}</StyledHeading>
        <Grid
          data={() =>
            new Promise((resolve) => {
              setTimeout(() => resolve(itemsDTO), 500);
            })
          }
          className={{
            table: 'w-full',
          }}
          columns={[
            {
              id: 'id',
              name: t('categories_page_column_id'),
              hidden: true,
            },
            {
              id: 'name',
              name: t('categories_page_column_name'),
            },
            {
              id: 'status',
              name: t('categories_page_column_Status'),
              formatter: (cell) => _(<StatusButton active={cell} />),
            },
            {
              name: t('categories_page_column_options'),
              sort: false,
              formatter: (cell, row) =>
                _(
                  <>
                    <StyledButton color="blue" onClick={() => handleOpenEditCategoryModal(row.cells[0].data)}>
                      <PencilAltIcon className="w-3 mr-1" /> {t('categories_page_button_edit')}
                    </StyledButton>
                    <StyledButton color="red" onClick={() => handleOpenDeleteCategoryModal(row.cells[0].data)}>
                      <XIcon className="w-4 h-4 mr-1" /> {t('categories_page_button_delete')}
                    </StyledButton>
                  </>,
                ),
            },
          ]}
          search
          sort
          pagination={{
            enabled: true,
            limit: 15,
          }}
        />
        <StyledButtonWrapper>
          <Button onClick={() => setOpenNewCategoryModal(true)}>{t('categories_page_button_new')}</Button>
        </StyledButtonWrapper>
      </>
    </AdminTemplate>
  );
};

export default CategoriesPage;
