/* eslint-disable no-unused-vars */
import MyPlayerBoard from 'components/organisms/MyPlayerBoard/MyPlayerBoard';
import PlayerBoard from 'components/organisms/PlayerBoard/PlayerBoard';
import { gameSelector } from 'features/game/GameSlice';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import tw from 'twin.macro';

const StyledWrapper = tw.div`
    relative
    w-full
    h-full
    rounded
    border-2
    border-yellow-900

`;

const PlayerSeat = ({ player }) => {
  const { myToken } = useSelector(gameSelector);

  return (
    <StyledWrapper>
      {player && player.token === myToken && <MyPlayerBoard />}
      {player && player.token !== myToken && <PlayerBoard player={player} />}
      {!player && <div className="bg-waterstamp bg-center bg-contain bg-no-repeat w-full h-full">&nbsp;</div>}
    </StyledWrapper>
  );
};

PlayerSeat.propTypes = {
  player: PropTypes.shape({
    username: PropTypes.string,
    token: PropTypes.string,
  }),
};

PlayerSeat.defaultProps = {
  player: null,
};

export default PlayerSeat;
