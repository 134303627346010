import React, { useRef } from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import Modal, { StyledModalContentWrapper, StyledButtonsWrapper } from 'components/molecules/Modal/Modal';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { playerBoardSelector } from 'features/game/PlayerBoardSlice';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import { playersSelector } from 'features/game/GameSlice';
import Card from 'components/molecules/Card/Card';
import GiftCard from 'components/molecules/Card/GiftCard/GiftCard';

const StyledWrapper = tw.div`
    mx-auto
    flex
    flex-col
    h-full
    bg-white
    w-full
    overflow-y-auto
    md:overflow-visible
`;

const StyledCardsWrapper = styled.div(({ borderBottom }) => [
  tw`
  flex-col
  grid-cols-6
  gap-1
  py-2
  md:grid
  md:h-full
  `,
  borderBottom && tw`border-b-2 border-gray-300 border-dashed`,
]);

const BiggerPlayerBoardModal = ({ open, close }) => {
  const { t } = useTranslation();
  const cancelButtonRef = useRef(null);
  const playerBoard = useSelector(playerBoardSelector);
  const players = useSelector(playersSelector);

  const { playerToShowInBiggerModalId } = playerBoard;

  const playerToShow = players.find((player) => player._id === playerToShowInBiggerModalId);
  const { handCards, giftCards } = playerToShow || {};

  return (
    <Modal open={open} close={close} cancelButtonRef={cancelButtonRef} size="xl" scroll={false}>
      <StyledModalContentWrapper ref={cancelButtonRef}>
        <StyledWrapper>
          <StyledCardsWrapper borderBottom>
            {handCards && handCards.map(({ card, isShowed }) => <Card card={card} visible={isShowed} inModal />)}
          </StyledCardsWrapper>
          <StyledCardsWrapper>
            {giftCards &&
              giftCards.map(({ card, from }) => (
                <GiftCard card={card} actualOwnerId={playerToShowInBiggerModalId} from={from} inModal />
              ))}
          </StyledCardsWrapper>
        </StyledWrapper>
      </StyledModalContentWrapper>
      <StyledButtonsWrapper>
        <ModalButton type="button" onClick={() => close()}>
          {t('close_button')}
        </ModalButton>
      </StyledButtonsWrapper>
    </Modal>
  );
};

BiggerPlayerBoardModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default BiggerPlayerBoardModal;
