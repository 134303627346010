/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const gameSlice = createSlice({
  name: 'game',
  initialState: {
    gameId: '',
    customGameId: '',
    myName: '',
    myEmail: '',
    myPlayerId: '',
    myToken: sessionStorage.getItem('playerToken') || null,
    myHandCards: [],
    myGiftCards: [],
    myPosition: null,
    players: [],
    isJoined: false,
    isSuccess: false,
    isLeader: false,
    successMessage: '',
    isError: false,
    errorMessage: '',
    cardTypes: [],
    firstLanguage: null,
    secondLanguage: null,
    gameInstruction: null,
    isStarted: false,
    isPaused: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = '';
      state.errorMessage = '';
      return state;
    },
    updateMyPlayer: (state, { payload }) => {
      state.myName = payload.username;
      state.myEmail = payload.email;
      state.myToken = payload.token;
      state.myPlayerId = payload.id;
      state.myGiftCards = payload.giftCards;
      state.myHandCards = payload.handCards;
      state.myPosition = payload.position;
      state.isJoined = true;
      state.isSuccess = true;
      state.isLeader = payload.isLeader || false;
      return state;
    },
    updateMyPlayerToken: (state, { payload }) => {
      state.myToken = payload.token;
    },
    updatePlayers: (state, { payload }) => {
      state.players = payload;
      return state;
    },
    updateGameData: (state, { payload }) => {
      state.cardTypes = payload.cardTypes;
      state.firstLanguage = payload.firstLanguage;
      state.secondLanguage = payload.secondLanguage;
      state.gameId = payload.gameId;
      state.gameInstruction = payload.gameInstruction;
      return state;
    },
    updateGameStatus: (state, { payload }) => {
      state.isStarted = payload.isStarted;
      state.isPaused = payload.isPaused;
    },
    updateCustomGameId: (state, { payload }) => {
      state.customGameId = payload.customGameId;
      return state;
    },
    joinError: (state, { payload }) => {
      state.isError = true;
      state.isJoined = false;
      state.errorMessage = payload.message;
      return state;
    },
    leaveGame: (state) => {
      state.gameId = '';
      state.myName = '';
      state.myEmail = '';
      state.myToken = null;
      state.myPlayerId = '';
      state.myGiftCards = [];
      state.myHandCards = [];
      state.myPosition = null;
      state.isJoined = false;
      state.isLeader = false;
      return state;
    },
  },
});

export const {
  clearState,
  updateMyPlayer,
  updateMyPlayerToken,
  updatePlayers,
  updateGameData,
  updateGameStatus,
  updateCustomGameId,
  joinError,
  leaveGame,
  startGame,
  pauseGame,
  continueGame,
} = gameSlice.actions;

export const gameSelector = (state) => state.game;

export const playersSelector = (state) => state.game.players;

export const myPlayerSelector = (state) => ({
  myEmail: state.game.myEmail,
  myName: state.game.myName,
  myPlayerId: state.game.myPlayerId,
  myToken: state.game.myToken,
  myHandCards: state.game.myHandCards,
  myGiftCards: state.game.myGiftCards,
  myPosition: state.game.myPosition,
  isJoined: state.game.isJoined,
  isLeader: state.game.isLeader,
});

export const gameDataSelector = (state) => ({
  cardTypes: state.game.cardTypes,
  firstLanguage: state.game.firstLanguage,
  secondLanguage: state.game.secondLanguage,
});
