import tw from 'twin.macro';

const Label = tw.label`
    block
    w-full
    font-medium
    text-xs
    text-gray-700
    text-left
`;

export default Label;
