export const routes = {
  home: '/',
  register: '/register',
  registerOrgranization: '/register-organization',
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  assignSubscription: '/assign-subscription',
  logout: '/logout',
  leaderBoard: {
    games: '/leader-board/games',
    buyPackage: '/leader-board/buy-package',
    subscriptions: '/leader-board/subscriptions',
    instructions: '/leader-board/instructions',
    orderConfirmation: '/leader-board/order-confirmation',
    settings: '/leader-board/settings/',
    relatedPlayers: '/leader-board/relatedPlayers',
  },
  adminBoard: {
    cards: '/admin-board/cards',
    cardTypes: '/admin-board/card-types',
    gameTypes: '/admin-board/game-types',
    categories: '/admin-board/categories',
    languages: '/admin-board/languages',
    packages: '/admin-board/packages',
    users: '/admin-board/users',
  },
  standardGame: '/game/:gameId',
  joinGame: '/join-game/:gameId',
  observingGame: '/observing-game/:gameId',
};
