import React from 'react';
import PropTypes from 'prop-types';
import ColorExampleSquare from '../ColorExampleSquare/ColorExampleSquare';

const SelectedColorItem = ({ color, text }) => (
  <span className="flex items-center">
    {color && <ColorExampleSquare color={color} />}
    <span className="ml-3 block truncate">{text}</span>
  </span>
);

SelectedColorItem.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
};

SelectedColorItem.defaultProps = {
  color: null,
  text: 'None',
};

export default SelectedColorItem;
