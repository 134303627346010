import React, { useEffect, useState } from 'react';
import AdminTemplate from 'templates/AdminTemplate';
import Heading from 'components/atoms/Heading';
import tw, { styled } from 'twin.macro';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { Grid, _ } from 'gridjs-react';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import Button from 'components/atoms/Button';
import StatusButton from 'components/atoms/StatusButton/StatusButton';
import { PencilAltIcon, XIcon } from '@heroicons/react/solid';
import { cardsSelector, clearItems, clearState, findCards } from './CardSlice';
import NewCardModal from './NewCardModal/NewCardModal';
import { findLanguages, languageSelector } from '../languages/LanguagesSlice';
import 'gridjs/dist/theme/mermaid.css';
import DeleteCardModal from './DeleteCardModal/DeleteCardModal';
import EditCardModal from './EditCardModal/EditCardModal';

const StyledHeading = tw(Heading)`
  text-2xl
  mt-5
  pt-3
`;

const StyledButtonWrapper = tw.div`
    block
    w-40
    mt-5
    mr-auto
`;

const StyledButton = styled(Button)(({ color }) => [
  tw`
      w-16
      border-0
      py-1
      px-2
      ml-2
      text-xs
    `,
  color === 'red' && tw`bg-red-500 hover:bg-red-600`,
  color === 'blue' && tw`bg-blue-500 hover:bg-blue-600`,
  color === 'indigo' && tw`bg-indigo-500 hover:bg-indigo-600`,
]);

const CardsPage = () => {
  const { t } = useTranslation();
  const [openNewCardModal, setOpenNewCardModal] = useState(false);
  const [openDeleteCardModal, setOpenDeleteCardModal] = useState(false);
  const [openEditCardModal, setOpenEditCardModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);

  const dispatch = useDispatch();
  const cards = useSelector(cardsSelector);
  const { items: languages } = useSelector(languageSelector);

  const { isSuccess, isError, errorMessage, items } = cards;

  const itemsDTO = items.map((item) => {
    const { _id, name, status, cardType, translations } = item;
    const translationsNames = translations.map((translation) => {
      const languageId = translation.language;
      const language = languages.find((singleLanguage) => singleLanguage._id === languageId);
      return `${language.code}, `;
    });
    return {
      id: _id,
      name,
      status,
      cardType: cardType.name || 'None',
      translations: translationsNames,
    };
  });

  useEffect(() => {
    dispatch(findCards());
    dispatch(findLanguages());
    return () => {
      dispatch(clearItems());
    };
  }, []);

  useEffect(() => () => dispatch(clearState()), []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  const handleOpenEditCardModal = (itemToEditId) => {
    setItemToEdit(items.find((item) => item._id === itemToEditId));
    setOpenEditCardModal(true);
  };

  const handleOpenDeleteCardModal = (itemToDeleteId) => {
    setItemToDelete(items.find((item) => item._id === itemToDeleteId));
    setOpenDeleteCardModal(true);
  };

  return (
    <AdminTemplate>
      <>
        {openNewCardModal && <NewCardModal open={openNewCardModal} setOpen={setOpenNewCardModal} />}
        {openDeleteCardModal && (
          <DeleteCardModal
            open={openDeleteCardModal}
            setOpen={setOpenDeleteCardModal}
            id={itemToDelete._id}
            name={itemToDelete.name}
          />
        )}
        {openEditCardModal && (
          <EditCardModal open={openEditCardModal} setOpen={setOpenEditCardModal} item={itemToEdit} />
        )}
        <StyledHeading as="h2">{t('cards_page_heading')}</StyledHeading>
        <Grid
          data={() =>
            new Promise((resolve) => {
              setTimeout(() => resolve(itemsDTO), 500);
            })
          }
          className={{
            table: 'w-full',
          }}
          columns={[
            {
              id: 'id',
              name: t('cards_page_column_id'),
              hidden: true,
            },
            {
              id: 'name',
              name: t('cards_page_column_name'),
            },
            {
              id: 'status',
              name: t('cards_page_column_status'),
              formatter: (cell) => _(<StatusButton active={cell} />),
            },
            {
              id: 'cardType',
              name: t('cards_page_column_card_type'),
            },
            {
              id: 'translations',
              name: t('cards_page_column_transltions'),
            },
            {
              name: t('cards_page_column_options'),
              sort: false,
              formatter: (cell, row) =>
                _(
                  <>
                    <StyledButton color="blue" onClick={() => handleOpenEditCardModal(row.cells[0].data)}>
                      <PencilAltIcon className="w-3 mr-1" /> {t('cards_page_button_edit')}
                    </StyledButton>
                    <StyledButton color="red" onClick={() => handleOpenDeleteCardModal(row.cells[0].data)}>
                      <XIcon className="w-4 h-4 mr-1" /> {t('cards_page_button_delete')}
                    </StyledButton>
                  </>,
                ),
            },
          ]}
          search
          sort
          pagination={{
            enabled: true,
            limit: 15,
          }}
        />
        <StyledButtonWrapper>
          <Button color="indigo" onClick={() => setOpenNewCardModal(true)}>
            {t('cards_page_button_new_card')}
          </Button>
        </StyledButtonWrapper>
      </>
    </AdminTemplate>
  );
};

export default CardsPage;
