import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import LeaderProfile from 'components/organisms/LeaderProfile/LeaderProfile';
import Header from 'components/organisms/Header/Header';

const StyledWrapper = tw.section`
    my-auto
    p-1
    relative
    block
    items-center
`;

const StyledContainer = tw.div`
    container
    px-5
    py-5
    mx-auto
    mb-8
    bg-white
    bg-opacity-90
    rounded
    flex
    flex-row
    flex-wrap
    h-full
    overflow-scroll
`;

const StyledLeaderProfileWrapper = tw.div`
    sm:w-1/4
    text-center
    sm:pr-4
    sm:py-4
`;

const StyledLeaderBoardWrapper = tw.div`
    lg:w-3/4
    w-full
    lg:pl-4
    lg:py-6
    mb-6
    lg:mb-0
    h-full
`;

const LeaderTemplate = ({ children }) => (
  <div className="bg-wood bg-cover bg-center bg-fixed h-screen w-screen flex flex-col overflow-hidden">
    <Header />
    <StyledWrapper className="max-h-4/5">
      <StyledContainer>
        <StyledLeaderProfileWrapper>
          <LeaderProfile />
        </StyledLeaderProfileWrapper>
        <StyledLeaderBoardWrapper>{children}</StyledLeaderBoardWrapper>
      </StyledContainer>
    </StyledWrapper>
  </div>
);

LeaderTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LeaderTemplate;

export const SingleItemWrapper = tw.div`
  flex
  border-b
  border-gray-300
  py-2
  leading-8
  items-center
`;

export const HeaderItemWrapper = tw.div`
  flex
  border-b
  border-gray-500
  py-1
  mb-2
  leading-8
  items-center
`;
