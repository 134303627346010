import React, { useRef } from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import Modal, { StyledModalContentWrapper, StyledButtonsWrapper } from 'components/molecules/Modal/Modal';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import { myPlayerSelector } from 'features/game/GameSlice';
import Card from 'components/molecules/Card/Card';
import MyCard from 'components/molecules/Card/MyCard/MyCard';

const StyledWrapper = tw.div`
    mx-auto
    flex
    flex-col
    h-full
    bg-white
    w-full
    overflow-y-auto
    md:overflow-visible
    px-1
`;

const StyledCardsWrapper = styled.div(({ borderBottom }) => [
  tw`
  flex-col
  grid-cols-6
  gap-1
  h-full
  py-2
  md:grid
  `,
  borderBottom && tw`border-b-2 border-gray-300 border-dashed`,
]);

const BiggerMyPlayerBoardModal = ({ open, close }) => {
  const { t } = useTranslation();
  const cancelButtonRef = useRef(null);
  const myPlayer = useSelector(myPlayerSelector);

  const { myHandCards, myGiftCards } = myPlayer;

  return (
    <Modal open={open} close={close} cancelButtonRef={cancelButtonRef} size="xl" scroll={false}>
      <StyledModalContentWrapper>
        <StyledWrapper>
          <StyledCardsWrapper borderBottom>
            {myHandCards && myHandCards.map(({ card, isShowed }) => <MyCard card={card} isShowed={isShowed} inModal />)}
          </StyledCardsWrapper>
          <StyledCardsWrapper>
            {myGiftCards && myGiftCards.map(({ card }) => <Card card={card} visible inModal myGiftCard />)}
          </StyledCardsWrapper>
        </StyledWrapper>
      </StyledModalContentWrapper>
      <StyledButtonsWrapper>
        <ModalButton ref={cancelButtonRef} type="button" onClick={() => close()}>
          {t('close_button')}
        </ModalButton>
      </StyledButtonsWrapper>
    </Modal>
  );
};

BiggerMyPlayerBoardModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default BiggerMyPlayerBoardModal;
