/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PackagesToBuyAPI from './PackagesToBuyAPI';

export const findAll = createAsyncThunk('packages/findAll', async (thunkAPI) => {
  try {
    const response = await PackagesToBuyAPI.findAll();
    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const packagesToBuySlice = createSlice({
  name: 'buyPackages',
  initialState: {
    items: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [findAll.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findAll.pending]: (state) => {
      state.isFetching = true;
    },
    [findAll.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload
        ? payload.message
        : 'Connection Error - Your Session Has Ended - Please Login Again.';
    },
  },
});

export const { clearState } = packagesToBuySlice.actions;

export const packagesToBuySelector = (state) => state.packagesToBuy;
