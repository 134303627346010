import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import MenuButton from './MenuButton';

const StyledWrapper = tw.div`
  2xl:w-32
  w-24
  text-right
  mx-1
`;

const StyledMenuItems = styled(Menu.Items)(({ openDirection }) => [
  tw`
    absolute
    w-52
    mt-2
    bg-gray-50
    divide-y
    divide-gray-200
    border-gray-400
    border
    rounded-md
    shadow-lg
    ring-1
    ring-black
    ring-opacity-5
    focus:outline-none
    z-20
  `,
  openDirection === 'top-left' && tw`-top-4 right-1 transform -translate-y-full origin-bottom-left`,
  openDirection === 'bottom-left' && tw`-bottom-4 right-1 transform translate-y-full origin-top-left`,
]);

const DropdownMenu = ({ menuButton, buttonText, buttonColor, openDirection, children }) => (
  <StyledWrapper>
    <Menu as="div" className="relative inline-block text-left">
      {menuButton || <MenuButton buttonText={buttonText} buttonColor={buttonColor} />}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <StyledMenuItems openDirection={openDirection}>{children}</StyledMenuItems>
      </Transition>
    </Menu>
  </StyledWrapper>
);

DropdownMenu.propTypes = {
  menuButton: PropTypes.element,
  buttonText: PropTypes.string.isRequired,
  buttonColor: PropTypes.string,
  openDirection: PropTypes.string,
  children: PropTypes.node.isRequired,
};

DropdownMenu.defaultProps = {
  buttonColor: 'blue',
  menuButton: null,
  openDirection: 'bottom-right',
};

export default DropdownMenu;
