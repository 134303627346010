import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { Link } from 'react-router-dom';
import Logo from 'components/atoms/Logo';
import Heading from 'components/atoms/Heading';
import Paragraph from 'components/atoms/Paragraph';
import Button from 'components/atoms/Button';

const StyledMain = tw.div`
  h-full
  w-full
  bg-gray-500
  bg-opacity-70
  flex
  flex-col
  items-center
  justify-center
`;

const StyledLogoWrapper = tw.div`
  w-4/5
  sm:w-3/5  
  md:w-3/6
  lg:w-2/6
  2xl:w-1/4
  h-20
  mb-14
`;

const StyledAuthCard = styled.div(({ longForm }) => [
  tw`
  w-4/5
  md:w-3/6
  lg:w-2/6
  2xl:w-1/4
  2xl:h-4/6
  py-2
  bg-white
  rounded-lg
  shadow-md
  flex
  flex-col
  items-center
  justify-center
  overflow-y-auto
  `,
  longForm ? tw`h-5/6` : tw`h-4/6`,
]);

const AuthTemplate = ({ children, longForm }) => (
  <div className="bg-wood bg-cover bg-center h-screen w-screen">
    <StyledMain>
      <StyledLogoWrapper>
        <Logo />
      </StyledLogoWrapper>
      <StyledAuthCard longForm={longForm}>{children}</StyledAuthCard>
    </StyledMain>
  </div>
);

AuthTemplate.propTypes = {
  children: PropTypes.elementType.isRequired,
  longForm: PropTypes.bool,
};

AuthTemplate.defaultProps = {
  longForm: false,
};

export default AuthTemplate;

export const StyledForm = tw.form`
  flex
  flex-col
  justify-center
  items-center
  w-4/5
`;

export const StyledHeading = tw(Heading)`
  sm: text-2xl
  text-xl
  inline-block
`;

export const StyledFieldWrapper = tw.div`
  flex
  flex-col
  w-full
  mb-2
`;

export const StyledErrorMessage = tw(Paragraph)`
  inline-block
  w-full
  text-xs
  capitalize
  text-left
  text-red-400
`;

export const StyledButtonsWrapper = tw.div`
  flex
  flex-row
  justify-center
  items-center
  w-full
  text-center
`;

export const BackButton = tw(Button)`
  ml-0
  text-center

`;

export const LoginButton = tw(Button)`
  m-0
`;

export const StyledLinkWrapper = tw.div`
  flex
  flex-col
  md:flex-row
  2xl:flex-col
  w-full
`;

export const StyledLink = tw(Link)`
  flex-grow
  text-xs
  text-gray-700
  mt-4
  underline
  text-center
`;
