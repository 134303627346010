import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { routes } from 'routes';
import LeaderRoute from 'routes/LeaderRoute';
import AdminRoute from 'routes/AdminRoute';
import PublicRoute from 'routes/PublicRoute';
import LoginPage from 'features/auth/login/LoginPage';
import LogoutPage from 'features/auth/logout/LogoutPage';
import CardTypesPage from 'features/admin/cardTypes/CardTypesPage';
import CardsPage from 'features/admin/cards/CardsPage';
import GameTypesPage from 'features/admin/gameTypes/GameTypesPage';
import LanguagesPage from 'features/admin/languages/LanguagesPage';
import PackagesPage from 'features/admin/packages/PackagesPage';
import GamesListPage from 'features/leader/gamesList/GamesListPage';
import JoinGamePage from 'features/game/JoinGame/JoinGamePage';
import CategoriesPage from 'features/admin/categories/CategoriesPage';
import GamePage from 'features/game/GamePage';
import PackagesToBuyPage from 'features/leader/packagesToBuy/PackagesToBuyPage';
import InstructionsPage from 'features/leader/instructions/InstructionsPage';
import GameObservingPage from 'features/game/GameObservingPage';
import OrdersListPage from 'features/leader/orders/OrdersListPage';
import OrderConfirmationPage from 'features/leader/orders/OrderConfirmationPage';
import ForgotPasswordPage from 'features/auth/forgotPassword/ForgotPasswordPage';
import RegisterPage from 'features/auth/register/RegisterPage';
import RegisterOrganizationPage from 'features/auth/registerOrganization/RegisterOrganizationPage';
import ResetPasswordPage from 'features/auth/resetPassword/ResetPasswordPage';
import LeaderSettingsPage from 'features/leader/settings/SettingsPage';
import LeaderRelatedPlayers from 'features/leader/relatedPlayers/RelatedPlayersPage';
import assignSubscriptionPage from 'features/auth/assignSubscription/AssignSubscriptionPage';
import UsersPage from 'features/admin/users/UsersPage';

import WelcomePage from './WelcomePage';

const Root = () => (
  <BrowserRouter>
    <Switch>
      <PublicRoute exact component={WelcomePage} path={routes.home} />
      <PublicRoute component={LoginPage} path={routes.login} restricted />
      <PublicRoute component={RegisterPage} path={routes.register} restricted />
      <PublicRoute component={RegisterOrganizationPage} path={routes.registerOrgranization} restricted />
      <PublicRoute component={ForgotPasswordPage} path={routes.forgotPassword} restricted />
      <PublicRoute component={ResetPasswordPage} path={routes.resetPassword} restricted />
      <PublicRoute component={assignSubscriptionPage} path={routes.assignSubscription} />
      <PublicRoute exact component={LogoutPage} path={routes.logout} />

      <LeaderRoute component={GamesListPage} path={routes.leaderBoard.games} />
      <LeaderRoute component={PackagesToBuyPage} path={routes.leaderBoard.buyPackage} />
      <LeaderRoute component={OrdersListPage} path={routes.leaderBoard.subscriptions} />
      <LeaderRoute component={InstructionsPage} path={routes.leaderBoard.instructions} />
      <LeaderRoute component={GameObservingPage} path={routes.observingGame} />
      <LeaderRoute component={OrderConfirmationPage} path={routes.leaderBoard.orderConfirmation} />
      <LeaderRoute component={LeaderSettingsPage} path={routes.leaderBoard.settings} />
      <LeaderRoute component={LeaderRelatedPlayers} path={routes.leaderBoard.relatedPlayers} />

      <AdminRoute component={CardTypesPage} path={routes.adminBoard.cardTypes} />
      <AdminRoute component={CardsPage} path={routes.adminBoard.cards} />
      <AdminRoute component={GameTypesPage} path={routes.adminBoard.gameTypes} />
      <AdminRoute component={LanguagesPage} path={routes.adminBoard.languages} />
      <AdminRoute component={CategoriesPage} path={routes.adminBoard.categories} />
      <AdminRoute component={PackagesPage} path={routes.adminBoard.packages} />
      <AdminRoute component={UsersPage} path={routes.adminBoard.users} />

      <PublicRoute component={GamePage} path={routes.standardGame} />
      <PublicRoute component={JoinGamePage} path={routes.joinGame} />
    </Switch>
  </BrowserRouter>
);

export default Root;
