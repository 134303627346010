/* eslint-disable react/jsx-props-no-spreading */

import React, { useContext, useEffect } from 'react';
import { useDispatch , useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';

import { userSelector, connectWithOrganization } from 'features/auth/AuthSlice';
import 'translations/i18n';
import SocketContext from 'socket/socket';
import { findAllByUser } from 'features/leader/gamesList/LeaderGamesSlice';
import LeaderTemplate from 'templates/LeaderTemplate';
import LeaderBoardMenu from 'components/molecules/LeaderBoardMenu/LeaderBoardMenu';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import { StyledForm } from 'templates/AuthTemplate';

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const RelatedPlayersPage = () => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const socket = useContext(SocketContext);
  const dispatch = useDispatch();

  const Wrapper = tw.div`
  overflow-hidden
`;

  const InputWrapper = tw.div`
 w-2/5
 float-left
 `;
  const ButtonWrapper = tw.div`
 text-center
 `;

  useEffect(() => {
    dispatch(findAllByUser());
  }, []);

  const user = useSelector(userSelector);

  const onSubmit = (data) => {
    const dataToSend = {
      email: data.email,
      userEmail: user.email,
      organizationId: user.userId,
    };
    dispatch(connectWithOrganization(dataToSend));
  };

  useEffect(() => {
    socket.on('gameStatusUpdated', () => {
      dispatch(findAllByUser());
    });
    socket.on('youJoinedGameAsLeader', ({ token, customGameId }) => {
      localStorage.setItem(`leader_token_${customGameId}`, token);
      window.open(`/game/${customGameId}`, `Play As Leader GameId: ${customGameId}`);
    });
    socket.on('exception', (data) => {
      toast.error(data.message);
    });
    socket.on('error', (error) => {
      toast.error(error);
    });
    return () => {
      socket.off('gameStatusUpdated');
      socket.off('youJoinedGameAsLeader');
      socket.off('exception');
      socket.off('error');
    };
  }, []);

  return (
    <LeaderTemplate>
      <>
        <LeaderBoardMenu />
        <Wrapper>
          <StyledForm onSubmit={handleSubmit(onSubmit)} method="POST">
            <InputWrapper>
              <Input type="email" {...register('email')} placeholder={t('relatedPlayers_page_placeholder')} />
            </InputWrapper>
            <ButtonWrapper>
              <Button>{t('relatedPlayers_page_submit')}</Button>
            </ButtonWrapper>
          </StyledForm>
        </Wrapper>
      </>
    </LeaderTemplate>
  );
};

export default RelatedPlayersPage;
