import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteGameType } from 'features/admin/gameTypes/GameTypesSlice';
import Modal, {
  StyledModalContentWrapper,
  StyledButtonsWrapper,
  StyledHeading,
} from 'components/molecules/Modal/Modal';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import Paragraph from 'components/atoms/Paragraph';

const DeleteGameTypeModal = ({ open, setOpen, name, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDeleteButtonClick = (gameId) => {
    dispatch(
      deleteGameType({
        id: gameId,
      }),
    );
    setOpen(false);
  };

  return (
    <Modal open={open} close={setOpen}>
      <StyledModalContentWrapper>
        <StyledHeading as="h3">
          {t('delete_game_type_modal_heading')}: &quot;{name}&quot;
        </StyledHeading>
        <Paragraph>
          {t('delete_game_type_modal_confirm_text')}: &quot;{name}&quot;
        </Paragraph>
      </StyledModalContentWrapper>
      <StyledButtonsWrapper>
        <ModalButton type="button" color="red" onClick={() => handleDeleteButtonClick(id)}>
          {t('delete_game_type_modal_button_delete')}
        </ModalButton>
        <ModalButton type="button" onClick={() => setOpen(false)}>
          {t('delete_game_type_modal_button_cancel')}
        </ModalButton>
      </StyledButtonsWrapper>
    </Modal>
  );
};

DeleteGameTypeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default DeleteGameTypeModal;
