/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import tw, { styled } from 'twin.macro';
import toast from 'react-hot-toast';
import { Grid, _ } from 'gridjs-react';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { PencilAltIcon, XIcon } from '@heroicons/react/solid';
import AdminTemplate from 'templates/AdminTemplate';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import StatusButton from 'components/atoms/StatusButton/StatusButton';
import { clearState, findGamesPackages, gamesPackagesSelector } from './PackagesSlice';
import NewGamesPackageModal from './NewGamesPackageModal/NewGamesPackageModal';
import DeleteGamesPackageModal from './DeleteGamesPackageModal/DeleteGamesPackageModal';
import EditGamesPackageModal from './EditGamesPackageModal/EditGamesPackageModal';
import { findGameTypes, gameTypesSelector } from '../gameTypes/GameTypesSlice';

const StyledHeading = tw(Heading)`
  text-2xl
  mt-5
  pt-3
`;

const StyledButtonWrapper = tw.div`
    block
    w-40
    mt-5
    mr-auto
`;

const StyledButton = styled(Button)(({ color }) => [
  tw`
        w-16
        border-0
        py-1
        px-2
        ml-2
        text-xs
      `,
  color === 'red' && tw`bg-red-500 hover:bg-red-600`,
  color === 'blue' && tw`bg-blue-500 hover:bg-blue-600`,
]);

const PackagesPage = () => {
  const [openNewGamesPackageModal, setOpenNewGamesPackageModal] = useState(false);
  const [openDeleteGamesPackageModal, setOpenDeleteGamesPackageModal] = useState(false);
  const [openEditGamesPackageModal, setOpenEditGamesPackageModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gamesPackages = useSelector(gamesPackagesSelector);
  const { isSuccess, isError, errorMessage, items } = gamesPackages;

  const { items: gameTypes } = useSelector(gameTypesSelector);

  useEffect(() => {
    dispatch(findGamesPackages());
    dispatch(findGameTypes());
  }, []);

  useEffect(() => () => dispatch(clearState()), []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  const handleOpenEditGamesPackageModal = (itemToEditId) => {
    setItemToEdit(items.find((item) => item._id === itemToEditId));
    setOpenEditGamesPackageModal(true);
  };

  const handleOpenDeleteGamesPackageModal = (itemToDeleteId) => {
    setItemToDelete(items.find((item) => item._id === itemToDeleteId));
    setOpenDeleteGamesPackageModal(true);
  };

  return (
    <AdminTemplate>
      <>
        {openNewGamesPackageModal && (
          <NewGamesPackageModal
            open={openNewGamesPackageModal}
            setOpen={setOpenNewGamesPackageModal}
            gameTypes={gameTypes}
          />
        )}
        {openDeleteGamesPackageModal && (
          <DeleteGamesPackageModal
            open={openDeleteGamesPackageModal}
            setOpen={setOpenDeleteGamesPackageModal}
            id={itemToDelete._id}
            name={itemToDelete.name}
          />
        )}
        {openEditGamesPackageModal && (
          <EditGamesPackageModal
            open={openEditGamesPackageModal}
            setOpen={setOpenEditGamesPackageModal}
            item={itemToEdit}
            gameTypes={gameTypes}
          />
        )}
        <StyledHeading as="h2">{t('packages_page_heading')}</StyledHeading>
        <Grid
          data={() =>
            new Promise((resolve) => {
              setTimeout(() => resolve(items), 500);
            })
          }
          className={{
            table: 'w-full',
          }}
          columns={[
            {
              id: '_id',
              name: t('packages_page_column_id'),
              hidden: true,
            },
            {
              id: 'shortName',
              name: t('packages_page_column_short_name'),
            },
            {
              id: 'name',
              name: t('packages_page_column_name'),
            },
            {
              id: 'gamesCount',
              name: t('packages_page_column_games_count'),
            },
            {
              id: 'subscriptionValidityPeriod',
              name: t('packages_page_column_subscription_validity_period'),
              formatter: (cell) => _(`${cell} ${t('packages_page_column_subscription_validity_cell')}`),
            },
            {
              id: 'unitPrice',
              name: t('packages_page_column_unit_price'),
              formatter: (cell) => _(`${cell} ${t('packages_page_column_unit_price_cell')}`),
            },
            {
              id: 'taxRate',
              name: t('packages_page_column_tax_rate'),
              formatter: (cell) => _(`${cell}%`),
            },
            {
              id: 'status',
              name: t('packages_page_column_status'),
              formatter: (cell) => _(<StatusButton active={cell} />),
            },
            {
              name: t('packages_page_column_options'),
              sort: false,
              formatter: (cell, row) =>
                _(
                  <>
                    <StyledButton color="blue" onClick={() => handleOpenEditGamesPackageModal(row.cells[0].data)}>
                      <PencilAltIcon className="w-3 mr-1" /> {t('packages_page_button_edit')}
                    </StyledButton>
                    <StyledButton color="red" onClick={() => handleOpenDeleteGamesPackageModal(row.cells[0].data)}>
                      <XIcon className="w-4 h-4 mr-1" /> {t('packages_page_button_delete')}
                    </StyledButton>
                  </>,
                ),
            },
          ]}
          search
          sort
          pagination={{
            enabled: true,
            limit: 15,
          }}
        />
        <StyledButtonWrapper>
          <Button onClick={() => setOpenNewGamesPackageModal(true)}>{t('packages_page_button_new')}</Button>
        </StyledButtonWrapper>
      </>
    </AdminTemplate>
  );
};

export default PackagesPage;
