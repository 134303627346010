import React from 'react';
import PropTypes from 'prop-types';

const OrderStatus = ({ status, isDelivered }) => (
  <>
    {status === 'created' && 'Created'}
    {status === 'readyToPay' && 'Waiting For Payment'}
    {status === 'readyToShip' && !isDelivered && 'Payment Processing'}
    {status === 'readyToShip' && isDelivered && 'Delivered'}
    {status === 'expired' && 'Expired'}
    {status === 'canceled' && 'Canceled'}
  </>
);

OrderStatus.propTypes = {
  status: PropTypes.oneOf(['created', 'readyToPay', 'readyToShip', 'expired', 'canceled']).isRequired,
  isDelivered: PropTypes.bool,
};

OrderStatus.defaultProps = {
  isDelivered: false,
};

export default OrderStatus;
