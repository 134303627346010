/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import Modal, {
  StyledHeading,
  StyledModalContentWrapper,
  StyledButtonsWrapper,
} from 'components/molecules/Modal/Modal';
import { StyledFieldWrapper } from 'templates/AuthTemplate';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import { connectWithOrganization, userSelector, getUserByToken } from 'features/auth/AuthSlice';

const schema = yup.object().shape({
  email: yup.string().required(),
});

const ShareSubscriptionModal = ({ open, setOpen, subscriptionToShareId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const user = useSelector(userSelector);

  const onSubmit = (dataFromForm) => {
    const { email } = dataFromForm;
    const data = {
      packageId: subscriptionToShareId,
      email,
      userEmail: user.email,
    };
    dispatch(connectWithOrganization(data)).then((response) => {
      if (response.payload.success === false) toast.error(response.payload.message);
      else {
        setOpen(false);
        dispatch(getUserByToken());
      }
    });
  };

  return (
    <Modal open={open} close={setOpen}>
      <form onSubmit={handleSubmit(onSubmit)} method="POST">
        <StyledModalContentWrapper>
          <StyledHeading as="h3">Share Subscription</StyledHeading>
          <Label>{t('share_package_modal_text')}</Label>
          <br />
          <StyledFieldWrapper>
            <Label htmlFor="email">Share Subscription With:</Label>
            <Input type="email" {...register('email')} placeholder="type email here" />
          </StyledFieldWrapper>
        </StyledModalContentWrapper>

        <StyledButtonsWrapper>
          <ModalButton type="submit" color="blue">
            {t('relatedPlayers_page_submit')}
          </ModalButton>
          <ModalButton type="button" onClick={() => setOpen(false)}>
            {t('card_modal_button_cancel')}
          </ModalButton>
        </StyledButtonsWrapper>
      </form>
    </Modal>
  );
};

ShareSubscriptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  subscriptionToShare: PropTypes.object.isRequired,
  subscriptionToShareId: PropTypes.string.isRequired,
};

export default ShareSubscriptionModal;
