/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import tw from 'twin.macro';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { updateCardType } from 'features/admin/cardTypes/CardTypesSlice';
import Modal, {
  StyledHeading,
  StyledModalContentWrapper,
  StyledButtonsWrapper,
} from 'components/molecules/Modal/Modal';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import { StyledFieldWrapper, StyledErrorMessage } from 'templates/AuthTemplate';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import Paragraph from 'components/atoms/Paragraph';
import ImageUploadBox from 'components/atoms/ImageUploadBox/ImageUploadBox';
import Toggle from 'components/molecules/Toggle/Toggle';
import ColorSelect from 'components/atoms/Select/ColorSelect';

const StyledParagraph = tw(Paragraph)`
  mt-4
  mb-2
`;

const schema = yup.object().shape({
  name: yup.string().required(),
});

const EditCardTypeModal = ({ open, setOpen, item }) => {
  const {
    _id,
    name,
    status: statusOld,
    frontCardColor: frontCardColorOld,
    textColor: textColorOld,
    backCardColorFrom: backCardColorFromOld,
    backCardColorTo: backCardColorToOld,
    backCardColorVia: backCardColorViaOld,
    image: imageOld,
  } = item;

  const [frontCardColor, setFrontCardColor] = useState(frontCardColorOld);
  const [textColor, setTextColor] = useState(textColorOld);
  const [backCardColorFrom, setBackCardColorFrom] = useState(backCardColorFromOld);
  const [backCardColorTo, setBackCardColorTo] = useState(backCardColorToOld);
  const [backCardColorVia, setBackCardColorVia] = useState(backCardColorViaOld);
  const [status, setStatus] = useState(statusOld);
  const [image, setImage] = useState(imageOld);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name,
    },
  });

  const onSubmit = (dataFromForm) => {
    const data = {
      _id,
      ...dataFromForm,
      status,
      frontCardColor,
      textColor,
      backCardColorFrom,
      backCardColorTo,
      backCardColorVia,
      image,
    };
    dispatch(updateCardType(data));
    setOpen(false);
  };

  return (
    <Modal open={open} close={setOpen} scroll>
      <form onSubmit={handleSubmit(onSubmit)} method="POST">
        <StyledModalContentWrapper>
          <StyledHeading as="h3">
            {t('edit_card_type_modal_heading')}: &quot;{name}&quot;
          </StyledHeading>
          <StyledFieldWrapper>
            <Label htmlFor="name">{t('card_type_modal_input_name')}</Label>
            <Input type="text" {...register('name')} placeholder={t('card_type_modal_input_name_placeholder')} />
            <StyledErrorMessage>{errors.name?.message}</StyledErrorMessage>
          </StyledFieldWrapper>

          <Toggle enabled={status} setEnabled={setStatus} />

          <StyledParagraph>{t('card_type_modal_paragraph_card_front')}</StyledParagraph>
          <StyledFieldWrapper>
            <Label htmlFor="frontCardColor">{t('card_type_modal_select_front_card_background_color')}</Label>
            <ColorSelect name="frontCardColor" selectedColor={frontCardColor} setSelectedColor={setFrontCardColor} />
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="textColor">{t('card_type_modal_select_front_card_text_color')}</Label>
            <ColorSelect name="textColor" selectedColor={textColor} setSelectedColor={setTextColor} />
          </StyledFieldWrapper>

          <StyledParagraph>{t('card_type_modal_paragraph_card_back')}</StyledParagraph>
          <StyledFieldWrapper>
            <Label htmlFor="backCardColorFrom">{t('card_type_modal_select_back_card_color_from')}</Label>
            <ColorSelect
              name="backCardColorFrom"
              selectedColor={backCardColorFrom}
              setSelectedColor={setBackCardColorFrom}
            />
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="backCardColorTo">{t('card_type_modal_select_back_card_color_to')}</Label>
            <ColorSelect name="backCardColorTo" selectedColor={backCardColorTo} setSelectedColor={setBackCardColorTo} />
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="backCardColorVia">{t('card_type_modal_select_back_card_color_via')}</Label>
            <ColorSelect
              name="backCardColorVia"
              selectedColor={backCardColorVia}
              setSelectedColor={setBackCardColorVia}
            />
          </StyledFieldWrapper>

          <Label htmlFor="image-uploader">{t('card_type_modal_image_upload')}</Label>
          <ImageUploadBox image={image} setImage={setImage} />
        </StyledModalContentWrapper>

        <StyledButtonsWrapper>
          <ModalButton type="submit" color="blue">
            {t('game_type_modal_button_save')}
          </ModalButton>
          <ModalButton type="button" onClick={() => setOpen(false)}>
            {t('card_type_modal_button_cancel')}
          </ModalButton>
        </StyledButtonsWrapper>
      </form>
    </Modal>
  );
};

EditCardTypeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
};

export default EditCardTypeModal;
