/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import tw, { styled } from 'twin.macro';
import toast from 'react-hot-toast';
import { Grid, _ } from 'gridjs-react';
import lodash from 'lodash';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import Heading from 'components/atoms/Heading';
import LeaderTemplate from 'templates/LeaderTemplate';
import Button from 'components/atoms/Button';
import LeaderBoardMenu from 'components/molecules/LeaderBoardMenu/LeaderBoardMenu';
import { InformationCircleIcon, CurrencyDollarIcon, RefreshIcon } from '@heroicons/react/solid';
import { getUserByToken, userSelector } from 'features/auth/AuthSlice';
import { clearState, findUserOrders, ordersSelector } from './OrdersSlice';
import OrderStatus from './OrderStatus/OrderStatus';
import OrderDetailsModal from './OrderDetailsModal/OrderDetailsModal';
import ShareSubscriptionModal from './ShareSubscriptionModal/ShareSubscriptionModal';

const StyledHeading = tw(Heading)`
  text-2xl
  mt-5
  pt-3
  mb-0
`;
const StyledDescription = tw.p`
mb-4
`;

const StyledButton = styled(Button)(({ color }) => [
  tw`
      w-28
      border-0
      py-1
      px-2
      ml-2
      text-xs
    `,
  color === 'red' && tw`bg-red-500 hover:bg-red-600`,
  color === 'blue' && tw`bg-blue-500 hover:bg-blue-600`,
  color === 'indigo' && tw`bg-indigo-500 hover:bg-indigo-600`,
  color === 'yellow' && tw`bg-yellow-500 hover:bg-yellow-600`,
]);

const OrdersListPage = () => {
  const { t } = useTranslation();
  const [idFromPaymentGatewayToModal, setIdFromPaymentGatewayToModal] = useState(null);
  const [openOrderDetailsModal, setOpenOrderDetailsModal] = useState(false);
  const [openShareSubscriptionModal, setOpenShareSubscriptionModal] = useState(false);
  const [subscriptionToShare, setSubscriptionToShare] = useState(null);
  const [subscriptionToShareId, setSubscriptionToShareId] = useState(null);

  const dispatch = useDispatch();

  const userOrders = useSelector(ordersSelector);
  const user = useSelector(userSelector);

  const { isError, errorMessage, items } = userOrders;
  const { subscriptions, subscriptionsSharedWithMe, roles } = user;

  const activePackages = Object.entries(subscriptions).map(([key, val]) => {
    const { gamesCount, validFor } = val;
    if (gamesCount > 0 && new Date(validFor).getTime() > Date.now()) {
      const userPackage = {
        _id: key,
        ...val,
      };
      return userPackage;
    }
    return null;
  });

  /*  eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }]  */
  const activePackagesImShareing = [];

  for (let i = 0; i < activePackages.length; i++) {
    const subscription = activePackages[i];
    if (subscription && subscription.sharedWith?.length > 0) {
      activePackagesImShareing.push(subscription);
    }
  }

  const sharedPackages = Object.entries(subscriptionsSharedWithMe).map(([key, val]) => {
    const { gamesCount, validFor } = val;
    if (gamesCount > 0 && new Date(validFor).getTime() > Date.now()) {
      const userPackage = {
        _id: key,
        ...val,
      };
      return userPackage;
    }
    return null;
  });

  const itemsDTO = items.map((item) => {
    const { _id, status, isDelivered, idFromPaymentGateway, gamesPackage, createdDate } = item;

    return {
      _id,
      status,
      isDelivered,
      idFromPaymentGateway,
      gamesPackageName: gamesPackage ? gamesPackage.name : 'Empty',
      createdDate,
    };
  });

  useEffect(() => {
    dispatch(findUserOrders());
    dispatch(getUserByToken());
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [isError]);

  const handleOpenPreOrderModalClick = (selectedIdFromPaymentGateway) => {
    setIdFromPaymentGatewayToModal(selectedIdFromPaymentGateway);
    setOpenOrderDetailsModal(true);
  };

  const handelOpenShareSubscriptionModalClick = (subscriptionToBeShareId) => {
    setSubscriptionToShare(subscriptions[subscriptionToBeShareId]);
    setSubscriptionToShareId(subscriptionToBeShareId);
    setOpenShareSubscriptionModal(true);
  };

  const handleRefreshStatus = () => {
    dispatch(findUserOrders());
    dispatch(getUserByToken());
  };

  return (
    <LeaderTemplate>
      <>
        <LeaderBoardMenu isOrganization={roles.includes('organization')} />
        {openOrderDetailsModal && (
          <OrderDetailsModal
            open={openOrderDetailsModal}
            setOpen={setOpenOrderDetailsModal}
            idFromPaymentGateway={idFromPaymentGatewayToModal}
          />
        )}
        {openShareSubscriptionModal && (
          <ShareSubscriptionModal
            open={openShareSubscriptionModal}
            setOpen={setOpenShareSubscriptionModal}
            subscriptionToShare={subscriptionToShare}
            subscriptionToShareId={subscriptionToShareId}
          />
        )}
        <StyledHeading as="h2">{t('orders_list_page_heading_active_packages')}</StyledHeading>
        <Grid
          data={() =>
            new Promise((resolve) => {
              setTimeout(() => resolve(lodash.compact(activePackages)), 500);
            })
          }
          className={{
            table: 'w-full',
          }}
          columns={[
            {
              id: '_id',
              name: t('orders_list_page_column_id'),
              hidden: true,
            },
            {
              id: 'packageName',
              name: t('orders_list_page_column_package_name'),
            },
            {
              id: 'gamesCount',
              name: t('orders_list_page_column_games_count'),
            },
            {
              id: 'validFor',
              name: t('orders_list_page_column_valid_for'),
              formatter: (cell, row) => _(new Date(cell).toLocaleDateString('en-EN')),
            },
            {
              id: 'share',
              name: 'Share',
              formatter: (cell, row) =>
                _(
                  <StyledButton onClick={() => handelOpenShareSubscriptionModalClick(row.cells[0].data)} color="blue">
                    Share Package
                  </StyledButton>,
                ),
            },
          ]}
          search={false}
          sort={false}
          pagination={false}
        />

        <StyledHeading as="h2">Packages Shared With Me:</StyledHeading>
        <Grid
          data={() =>
            new Promise((resolve) => {
              setTimeout(() => resolve(lodash.compact(sharedPackages)), 500);
            })
          }
          className={{
            table: 'w-full',
          }}
          columns={[
            {
              id: '_id',
              name: t('orders_list_page_column_id'),
              hidden: true,
            },
            {
              id: 'packageName',
              name: t('orders_list_page_column_package_name'),
            },
            {
              id: 'gamesCount',
              name: t('orders_list_page_column_games_count'),
            },
            {
              id: 'validFor',
              name: t('orders_list_page_column_valid_for'),
              formatter: (cell, row) => _(new Date(cell).toLocaleDateString('en-EN')),
            },
          ]}
          search={false}
          sort={false}
          pagination={false}
        />
        <StyledHeading as="h2">Packages I&apos;m sharing:</StyledHeading>
        <StyledDescription className="text-sm">{t('orders_list_page_share_package_description')}</StyledDescription>
        <Grid
          data={() =>
            new Promise((resolve) => {
              setTimeout(() => resolve(lodash.compact(activePackagesImShareing)), 500);
            })
          }
          className={{
            table: 'w-full',
          }}
          columns={[
            {
              id: '_id',
              name: t('orders_list_page_column_id'),
              hidden: true,
            },
            {
              id: 'packageName',
              name: t('orders_list_page_column_package_name'),
            },
            {
              id: 'gamesCount',
              name: t('orders_list_page_column_games_count'),
            },
            {
              id: 'validFor',
              name: t('orders_list_page_column_valid_for'),
              formatter: (cell, row) => _(new Date(cell).toLocaleDateString('en-EN')),
            },
            {
              id: 'sharedWith',
              name: t('orders_list_page_column_shared_with'),
              formatter: (cell) => `${cell}, `,
            },
          ]}
          search={false}
          sort={false}
          pagination={false}
        />
        <StyledHeading as="h2">{t('orders_list_page_heading_order_history')}:</StyledHeading>
        <StyledDescription className="text-sm">{t('orders_list_page_order_history_description')}</StyledDescription>
        <Grid
          data={() =>
            new Promise((resolve) => {
              setTimeout(() => resolve(itemsDTO), 500);
            })
          }
          className={{
            table: 'w-full',
          }}
          columns={[
            {
              id: '_id',
              name: t('orders_list_page_column_id'),
              hidden: true,
            },
            {
              id: 'isDelivered',
              name: t('orders_list_page_column_delivered'),
              hidden: true,
            },
            {
              id: 'gamesPackageName',
              name: t('orders_list_page_column_package_name'),
            },
            {
              id: 'createdDate',
              name: t('orders_list_page_column_created_date'),
              formatter: (cell, row) => _(new Date(cell).toLocaleDateString('en-EN')),
            },
            {
              id: 'status',
              name: t('orders_list_page_column_status'),
              formatter: (cell, row) => _(<OrderStatus status={cell} isDelivered={row.cells[1].data} />),
            },
            {
              id: 'idFromPaymentGateway',
              name: 'idFromPaymentGateway',
              hidden: true,
            },
            {
              id: 'details',
              name: t('orders_list_page_column_details'),
              formatter: (cell, row) =>
                _(
                  <>
                    {row.cells[1].data === true && row.cells[4].data === 'readyToShip' && (
                      <StyledButton color="blue" onClick={() => handleOpenPreOrderModalClick(row.cells[5].data)}>
                        <InformationCircleIcon className="w-3 mr-1" /> {t('orders_list_page_button_order_details')}
                      </StyledButton>
                    )}
                    {row.cells[1].data === false && row.cells[4].data === 'created' && (
                      <StyledButton color="indigo" onClick={() => handleOpenPreOrderModalClick(row.cells[5].data)}>
                        <CurrencyDollarIcon className="w-3 mr-1" /> {t('orders_list_page_button_pay')}
                      </StyledButton>
                    )}
                    {row.cells[1].data === false && ['readyToPay', 'readyToShip'].includes(row.cells[4].data) && (
                      <StyledButton color="yellow" onClick={handleRefreshStatus}>
                        <RefreshIcon className="w-3 mr-1" /> {t('orders_list_page_button_refresh_status')}
                      </StyledButton>
                    )}
                  </>,
                ),
            },
          ]}
          search={false}
          sort
          pagination
        />
      </>
    </LeaderTemplate>
  );
};

export default OrdersListPage;
