import axios from 'axios';
import { config } from 'constants.js';
import authHeader from 'features/auth/authHeader';

const create = ({ name, translations, cardType }) =>
  axios.post(
    `${config.url.API_URL}/cards`,
    {
      name,
      translations,
      cardType,
    },
    { headers: authHeader() },
  );

const findOneById = ({ cardId }) => axios.get(`${config.url.API_URL}/cards/${cardId}`, { headers: authHeader() });

const find = () => axios.get(`${config.url.API_URL}/cards`, { headers: authHeader() });

const update = ({ _id, name, translations, cardType, status }) =>
  axios.patch(
    `${config.url.API_URL}/cards/${_id}`,
    {
      name,
      translations,
      cardType,
      status,
    },
    { headers: authHeader() },
  );

const remove = ({ cardId }) => axios.delete(`${config.url.API_URL}/cards/${cardId}`, { headers: authHeader() });

export default {
  create,
  findOneById,
  find,
  update,
  remove,
};
