export const TRANSLATIONS_EN = {
  // Shared
  close_button: 'Close',

  // Welcome Page
  welcome_heading: 'FeedbackGame.com',
  welcome_text:
    'We are currently working on digitalizing our popular Feedback game and it will soon be launched. If you are interested to know more about the game and be one of our Pilot tester, please send us your information to info@eccom.se.',

  /* ----------------- AUTH ----------------- */
  // Nav Menu
  nav_item_home: 'Home',
  nav_item_login: 'Login',
  nav_item_register: 'Register',
  nav_item_leader_account: 'Leader Account',
  nav_item_admin_account: 'Admin Account',
  nav_item_logout: 'Logout',

  // Login Page
  login_page_heading: 'Login',
  login_page_input_email: 'User Email',
  login_page_input_email_placeholder: 'example@email.com',
  login_page_input_password: 'Password',
  login_page_input_password_placeholder: 'your password',
  login_page_button_cancel: 'Cancel',
  login_page_button_sign_in: 'Sign In',
  login_page_have_not_account: 'You have not account? - Register Now!',
  login_page_forgot_password: 'Forgot Password?',

  // Register Page
  register_page_heading: 'Register',
  register_page_input_username: 'Username',
  register_page_input_username_placeholder: 'exampleUser22',
  register_page_input_email: 'Email',
  register_page_input_email_placeholder: 'example@email.com',
  register_page_input_password: 'Password',
  register_page_input_password_placeholder: 'your password',
  register_page_input_password_repeat: 'Repeat Password',
  register_page_input_password_repeat_placeholder: 'repeat password',
  register_page_button_cancel: 'Cancel',
  register_page_button_sing_up: 'Sign Up',
  register_page_already_have_an_account: 'Already have an account? Go to the Login page!',

  // Register Organization Page
  register_orgranization_page_heading: 'Register Organization Account',
  register_orgranization_page_input_username: 'Organization Name',
  register_orgranization_page_input_username_placeholder: 'exampleUser22',
  register_orgranization_page_input_email: 'Email',
  register_orgranization_page_input_email_placeholder: 'example@email.com',
  register_orgranization_page_input_password: 'Password',
  register_orgranization_page_input_password_placeholder: 'your password',
  register_orgranization_page_input_password_repeat: 'Repeat Password',
  register_orgranization_page_input_password_repeat_placeholder: 'repeat password',
  register_orgranization_page_button_cancel: 'Cancel',
  register_orgranization_page_button_sing_up: 'Sign Up',
  register_orgranization_page_already_have_an_account: 'Already have an account? Go to the Login page!',

  // Forgot Password Page
  forgot_password_page_heading: 'Forgot Password',
  forgot_password_page_input_email: 'Your Email',
  forgot_password_page_input_email_placeholder: 'your@email.com',
  forgot_password_page_spam_info:
    'We will send a message to your email account with a link allowing you to set a new password. if you do not receive a message from us within a few minutes, check the SPAM folder',
  forgot_password_page_button_cancel: 'Cancel',
  forgot_password_page_button_submit: 'Submit',
  forgot_password_page_have_not_account: 'You have not account? - Register Now!',
  forgot_password_page_login_to_account: 'Login to your account',

  // Reset Password Page
  reset_password_page_heading: 'Reset Password',
  reset_password_page_input_password: 'Password',
  reset_password_page_input_password_placeholder: 'your new password',
  reset_password_page_input_repeat_password: 'Repeat Password',
  reset_password_page_input_repeat_password_placeholder: 'repeat new password',
  reset_password_page_button_cancel: 'Cancel',
  reset_password_page_button_reset_password: 'Reset Password',
  reset_password_page_have_not_account: 'You have not account? - Register Now!',
  reset_password_page_login_to_your_account: 'Login to your account',

  /* ----------------- LEADER BOARD ----------------- */

  // Leader Nav Menu
  leader_nav_menu_your_games: 'Your Games',
  leader_nav_menu_buy_package: 'Buy Package',
  leader_nav_menu_packages_order_hisotry: 'Packages / Order History',
  leader_nav_menu_instructions: 'Instructions',

  // Leader Profile
  leader_profile_heading: 'Hi!',
  leader_profile_text: `Thank you for your purchase and Welcome to Feedbackgame. You are now the admin of the valid games. From this view you can create games, share games, subscribe and get your order history.`,
  leader_profile_text_2:
    'We hope that you will gain a lot of value by playing our game. Don´t hesitate to email us for any questions or support. If you are interested for us as the leader of a game, contact us for more information and a request at:',
  leader_profile_text_3: 'Best wishes',
  leader_profile_text_4: 'Susanne & Anna',
  leader_profile_text_5: 'Eccom - Peopleinnovation',
  leader_profile_text_email: 'hey@feedbackgame.com',
  leader_profile_your_packages: 'Your Packages',
  leader_profile_package_name: 'Package',
  leader_profile_games_count: 'Games Count',
  leader_profile_valid_for: 'Valid For',

  // Share package modal
  share_package_modal_text:
    'A package can be shared with others. Enter the email address of the person who will receive the package. The recipient receives an email with a link that requires a registration of an account and which enables creation of games.',

  // Game List Page
  leader_game_list_page_heading: 'Your Games List',
  leader_game_list_page_create_new_game: 'Create New Game',
  leader_game_list_page_th_name: 'Name',
  leader_game_list_page_th_details: 'Details',
  leader_game_list_page_th_options: 'Options',
  leader_game_list_page_td_id: 'Game ID',
  leader_game_list_page_td_status_created: 'CREATED',
  leader_game_list_page_td_status_paused: 'PAUSED',
  leader_game_list_page_td_status_active: 'STARTED',
  leader_game_list_page_td_type: 'Type',
  leader_game_list_page_td_created_at: 'Created at',
  leader_game_list_page_td_valid_for: 'Valid for',
  leader_game_list_page_td_language_1: 'Language 1',
  leader_game_list_page_td_language_2: 'Language 2',
  leader_game_list_page_td_button_copy: 'Copy Link',
  leader_game_list_page_td_button_menu: 'Join Board',
  leader_game_list_page_td_button_menu_item_leader_as_player: 'Leader as a player',
  leader_game_list_page_td_button_menu_item_leader_as_facilitator: 'Join as fascilitator',
  leader_game_list_page_td_button_start_game: 'Start Game',
  leader_game_list_page_td_button_pause_game: 'Pause Game',
  leader_game_list_page_td_button_continue_game: 'Continue',
  leader_game_list_page_td_button_delete_game: 'Delete',
  leader_game_list_page_td_info_block: `Don't forget to unblock pop-up windows. It's required for feedbackgame to work properly.`,
  leader_game_list_page_td_info_block_dont_show_again: `Don't show it again.`,

  // New Game Modal
  new_game_modal_heading: 'Add New Game',
  new_game_modal_input_game_type: 'Game Type',
  new_game_modal_input_first_language: 'First Language',
  new_game_modal_input_second_language: 'Second Language',
  new_game_modal_button_cancel: 'Cancel',
  new_game_modal_button_add_new: 'Add New',
  new_game_modal_button_buy_package: 'Buy Package',
  new_game_modal_cannot_create_game_part_1: 'You cannot create new game now.',
  new_game_modal_cannot_create_game_part_2:
    'If you want to create new game click button below "Buy Package" Section or create game from shared package.',
  new_game_modal_cannot_create_game_from_shared_package_part_1: 'You cannot create new game from shared package.',
  new_game_modal_cannot_create_game_from_shared_package_part_2:
    'If you want to create new game click button below "Buy Package" Section or create game from your own package.',

  // Delete Game Modal
  delete_game_modal_heading: 'Delete Game',
  delete_game_modal_text: 'Are you sure you want to delete the game',
  delete_game_modal_button_cancel: 'Cancel',
  delete_game_modal_button_delete: 'Delete',

  // Buy Package Page
  leader_buy_package_page_heading: 'Buy New Games Package',
  leader_buy_package_page_column_id: 'Id',
  leader_buy_package_page_column_name: 'Name',
  leader_buy_package_page_column_games_count: 'Games count',
  leader_buy_package_page_column_games_count_cell: 'games',
  leader_buy_package_page_column_games_types: 'Games Types',
  leader_buy_package_page_column_subscription_period: 'Subscription Period',
  leader_buy_package_page_column_subscription_period_cell: 'days',
  leader_buy_package_page_column_price: 'Price',
  leader_buy_package_page_column_price_cell: 'SEK',
  leader_buy_package_page_column_tax_rate: 'Tax Rate',
  leader_buy_package_page_column_buy_package: 'Buy Package',

  // Pre Order Modal
  pre_order_modal_heading: 'Your Order',
  pre_order_modal_heading_pay: 'Pay',
  pre_order_modal_package_name: 'Package Name',
  pre_order_modal_games_count: 'Games Count',
  pre_order_modal_subscription_validity_period: 'Subscription Validity Period',
  pre_order_modal_subscription_validity_period_days: 'days',
  pre_order_modal_price: 'Price',
  pre_order_modal_game_types_in_package: 'Game Types in Package',
  pre_order_modal_button_cancel: 'Cancel',
  pre_order_modal_button_go_to_payment: 'Go To Payment',

  // Orders Page
  orders_list_page_heading_active_packages: 'Active Packages:',
  orders_list_page_column_id: 'Id',
  orders_list_page_column_package_name: 'Package Name',
  orders_list_page_column_games_count: 'Available Games',
  orders_list_page_column_valid_for: 'Valid For',
  orders_list_page_column_shared_with: 'Shared With',
  orders_list_page_heading_order_history: 'Order History',
  orders_list_page_column_delivered: 'Delivered',
  orders_list_page_column_created_date: 'Created Date',
  orders_list_page_column_status: 'Status',
  orders_list_page_column_details: 'Details',
  orders_list_page_button_order_details: 'Order Details',
  orders_list_page_button_pay: 'Pay',
  orders_list_page_button_refresh_status: 'Refresh Status',
  orders_list_page_share_package_description: 'The list shows which email addresses you have shared a package with.',
  orders_list_page_order_history_description: 'The list shows what orders you have done and the status of them.',

  // Instructions Page
  instructions_page_heading: 'Game Instructions',
  instructions_page_column_id: 'Id',
  instructions_page_column_name: 'Game Type Name',
  instructions_page_column_instruction: 'Instruction',
  instructions_page_button_show_instruction: 'Show Instruction',
  instructions_page_text_no_instruction: 'No Instruction',

  // RelatedPlayers Page
  relatedPlayers_page_submit: 'Share',
  relatedPlayers_pickUpSubscriptions: 'Receive subscription',
  relatedPlayers_button_pickUp: 'pick up',
  relatedPlayers_page_placeholder: 'Type player email.',

  /* ----------------- ADMIN BOARD ----------------- */

  // Admin Nav Menu
  admin_nav_menu_game_type: 'Game Type',
  admin_nav_menu_cards: 'Cards',
  admin_nav_menu_card_types: 'Card Types',
  admin_nav_menu_language: 'Language',
  admin_nav_menu_category: 'Category',
  admin_nav_menu_packages: 'Packages',

  // Game Types Page
  game_types_page_heading: 'Game Types',
  game_types_page_column_id: 'Id',
  game_types_page_column_name: 'Name',
  game_types_page_column_status: 'Status',
  game_types_page_column_types_counts: 'Types / Counts',
  game_types_page_column_category: 'Category',
  game_types_page_column_instruction: 'Instruction',
  game_types_page_column_available_languages: 'Available Languages',
  game_types_page_column_options: 'Options',
  game_types_page_button_edit: 'Edit',
  game_types_page_button_delete: 'Delete',
  game_types_page_button_new_game_type: 'New Game Type',

  // Add / Edit Game Type Modal
  new_game_type_modal_heading: 'Add New Game Type',
  edit_game_type_modal_heading: 'Edit Game Type',
  game_type_modal_input_game_type_name: 'Game Type Name',
  game_type_modal_input_game_type_name_placeholder: 'Example: strenght (3) / weaknes (2)',
  game_type_modal_select_first_card_type: 'First Card Type',
  game_type_modal_select_first_card_type_cards_start_count: 'First Card Type Cards Start Count',
  game_type_modal_select_second_card_type: 'Second Card Type',
  game_type_modal_select_second_card_type_cards_start_count: 'Second Card Type Cards Start Count',
  game_type_modal_select_third_card_type: 'Third Card Type',
  game_type_modal_select_third_card_type_cards_start_count: 'Third Card Type Cards Start Count',
  game_type_modal_select_category: 'Category',
  game_type_modal_multiple_select_available_languages: 'Available Languages',
  game_type_modal_input_instruction_link: 'Instruction Link',
  game_type_modal_button_save: 'Save',
  game_type_modal_button_cancel: 'Cancel',

  // Delete Game Type Modal
  delete_game_type_modal_heading: 'Delete Game Type',
  delete_game_type_modal_confirm_text: 'Are you sure you want to delete the game type',
  delete_game_type_modal_button_delete: 'Delete',
  delete_game_type_modal_button_cancel: 'Cancel',

  // Cards Page
  cards_page_heading: 'Card',
  cards_page_column_id: 'Id',
  cards_page_column_name: 'Name',
  cards_page_column_status: 'Status',
  cards_page_column_card_type: 'Card Type',
  cards_page_column_transltions: 'Translations',
  cards_page_column_options: 'Options',
  cards_page_button_edit: 'Edit',
  cards_page_button_delete: 'Delete',
  cards_page_button_new_card: 'New Card',

  // Add / Edit Card Modal
  new_card_modal_heading: 'Add New Card',
  edit_card_modal_heading: 'Edit Card',
  card_modal_input_card_name: 'Card Name',
  card_modal_input_card_name_placeholder: 'Example: happy',
  card_modal_select_card_type: 'Card Type',
  card_modal_input_translation_in: 'Translation in',
  card_modal_input_translation_in_placeholder: 'Card text translation in',
  card_modal_button_add_card: 'Add Card',
  card_modal_button_cancel: 'Cancel',

  // Delete Card Modal
  delete_card_modal_heading: 'Delete Card',
  delete_card_modal_confirm_text: 'Are you sure you want to delete the game type',
  delete_card_modal_delete: 'Delete',
  delete_card_modal_cancel: 'Cancel',

  // Card Types Page
  card_types_page_heading: 'Card Types',
  card_types_page_column_id: 'Id',
  card_types_page_column_name: 'Name',
  card_types_page_column_status: 'Status',
  card_types_page_column_front_card_design: 'Front Card Design',
  card_types_page_column_back_card_design: 'Back Card Design',
  card_types_page_column_options: 'Options',
  card_types_page_button_edit: 'Edit',
  card_types_page_button_delete: 'Delete',
  card_types_page_button_new_card_type: 'New Card Type',

  // Add / Edit Card Type
  new_card_type_modal_heading: 'Add New Card Type',
  edit_card_type_modal_heading: 'Edit Card Type',
  card_type_modal_input_name: 'Unique Card Type Name',
  card_type_modal_input_name_placeholder: 'example: strengths',
  card_type_modal_paragraph_card_front: 'Card Front',
  card_type_modal_select_front_card_background_color: 'Text Color',
  card_type_modal_select_front_card_text_color: 'Text Color',
  card_type_modal_paragraph_card_back: 'Card Back',
  card_type_modal_select_back_card_color_from: 'Main Color / Gradient Color From',
  card_type_modal_select_back_card_color_to: 'Gradient Color To',
  card_type_modal_select_back_card_color_via: 'Gradient Color Via',
  card_type_modal_image_upload: 'Upload Image',
  card_type_modal_image_upload_text: 'Upload a file',
  card_type_modal_allowed_formats: 'Allowed file formats: PNG, JPG',
  card_type_modal_button_new_card_type: 'Add New',
  card_type_modal_button_cancel: 'Cancel',

  // Delete Card Type Modal
  delete_card_type_modal_heading: 'Delete Card Type',
  delete_card_type_modal_confirm_text: 'Are you sure you want to delete the card type',
  delete_card_type_modal_delete: 'Delete',
  delete_card_type_modal_cancel: 'Cancel',

  // Languages Page
  languages_page_heading: 'Languages',
  languages_page_column_id: 'Id',
  languages_page_column_name: 'Name',
  languages_page_column_status: 'Status',
  languages_page_column_code: 'Code',
  languages_page_column_options: 'Options',
  languages_page_button_edit: 'Edit',
  languages_page_button_delete: 'Delete',
  languages_page_button_new_language: 'New Language',

  // Add / Edit Language Modal
  new_language_modal_heading: 'Add New Language',
  edit_language_modal_heading: 'Edit Language',
  language_modal_input_name: 'Language Name',
  language_modal_input_name_placeholder: 'Example: english',
  language_model_input_country_code: 'International Country Code',
  language_model_input_country_code_placeholder: 'Example: en',
  language_model_button_add: 'Add Language',
  language_model_button_cancel: 'Cancel',

  // Delete Language Modal
  delete_language_modal_heading: 'Delete Language',
  delete_language_modal_confirm_text: 'Are you sure you want to delete the language',
  delete_language_modal_delete: 'Delete',
  delete_language_modal_cancel: 'Cancel',

  // Categories Page
  categories_page_heading: 'Categories',
  categories_page_column_id: 'Id',
  categories_page_column_name: 'Name',
  categories_page_column_Status: 'Status',
  categories_page_column_options: 'Options',
  categories_page_button_edit: 'Edit',
  categories_page_button_delete: 'Delete',
  categories_page_button_new: 'New Category',

  // Add / Edit Category Modal
  new_category_modal_heading: 'Add New Category',
  edit_category_modal_heading: 'Edit Category',
  new_category_modal_input_name: 'Unique Category Name',
  new_category_modal_input_name_placeholder: 'example: strengths',
  new_category_modal_button_add_new: 'Add New',
  new_category_modal_button_edit: 'Edit',
  new_category_modal_button_cancel: 'Cancel',

  // Delete Category Modal
  delete_category_modal_heading: 'Delete Category',
  delete_category_modal_confirm_text: 'Are you sure you want to delete the category',
  delete_category_modal_delete: 'Delete',
  delete_category_modal_cancel: 'Cancel',

  // Packages Page
  packages_page_heading: 'Games Packages',
  packages_page_column_id: 'Id',
  packages_page_column_short_name: 'Short Name',
  packages_page_column_name: 'Name',
  packages_page_column_games_count: 'Games Count',
  packages_page_column_subscription_validity_period: 'Subscription Validity Period',
  packages_page_column_subscription_validity_cell: 'days',
  packages_page_column_unit_price: 'Unit Price',
  packages_page_column_unit_price_cell: 'SEK',
  packages_page_column_tax_rate: 'Tax Rate',
  packages_page_column_status: 'Status',
  packages_page_column_options: 'Options',
  packages_page_button_edit: 'Edit',
  packages_page_button_delete: 'Delete',
  packages_page_button_new: 'New Games Package',

  // Add / Edit Package Modal
  new_package_modal_heading: 'Add New Card Type',
  edit_package_modal_heading: 'Edit Card Type',
  new_package_modal_multipleselect_available_game_types: 'Available Game Types in Package',
  new_package_modal_input_name: 'Game Package Name',
  new_package_modal_input_name_placeholder: 'example: Package 1 - 10 Games / 14 Days',
  new_package_modal_input_short_name: 'Short Package Name',
  new_package_modal_input_short_name_placeholder: 'example: Package 1',
  new_package_modal_input_description: 'Description',
  new_package_modal_input_description_placeholder: 'example: Package 30 Games / 30 Days ...',
  new_package_modal_input_unit_price: 'Package Price [SEK]',
  new_package_modal_input_unit_price_placeholder: 'example: 1000',
  new_package_modal_input_tax_rate: 'Tax Rate [%]',
  new_package_modal_input_tax_rate_placeholder: 'example: 1000',
  new_package_modal_input_number_of_games_in_package: 'The Number of Games in the Package',
  new_package_modal_input_number_of_games_in_package_placeholder: 'example: 10',
  new_package_modal_input_validity_period: 'Subscription Validity Period [days]',
  new_package_modal_input_validity_period_placeholder: 'example: 30',
  new_package_modal_button_new: 'Add New',
  new_package_modal_button_edit: 'Edit',
  new_package_modal_button_cancel: 'Cancel',

  // Delete Package Modal
  delete_package_modal_heading: 'Delete Games Package',
  delete_package_modal_confirm_text: 'Are you sure you want to delete the games package',
  delete_package_modal_delete: 'Delete',
  delete_package_modal_cancel: 'Cancel',

  // Users Page
  users_page_heading: 'Users',
  users_page_column_id: 'Id',
  users_page_column_username: 'Username',
  users_page_column_email: 'Email',
  users_page_column_options: 'Options',
  users_page__button_add_package: 'Add Package',
  users_page__button_delete_user: 'Delete',

  // Add Package To User For Free Modal
  add_package_to_user_modal_heading: 'Add Package To User',
  add_package_to_user_modal_select_package_label: 'Are you sure you want to add user package:',
  add_package_to_user_modal_button_new: 'Add Package',
  add_package_to_user_modal_button_cancel: 'Cancel',

  // Delete User Modal
  delete_user_modal_heading: 'Delete User',
  delete_user_modal_confirm_text: 'Are you sure you want to delete user',
  delete_user_modal_delete: 'Delete',
  delete_user_modal_cancel: 'Cancel',

  /* ----------------- GAME ----------------- */

  // Join Game Page
  join_game_page_heading: 'Join Game',
  join_game_page_game_id: 'Game Id',
  join_game_page_input_name: 'Your Name',
  join_game_page_input_name_placeholder: 'your name',
  join_game_page_input_email: 'Your Email',
  join_game_page_input_email_placeholder: 'example@email.com',
  join_game_page_button_cancel: 'Cancel',
  join_game_page_button_join_game: 'Join Game',

  // Leader Menu
  leader_menu_start_game: 'Start Game',
  leader_menu_continue_game: 'Continue Game',
  leader_menu_pause_game: 'Pause Game',

  // Deck Board
  deck_board_game_paused_info_text: 'Waiting for the leader to start the game.',
  deck_board_game_paused: 'Game Paused.',
  deck_board_game_id: 'Game ID',

  // Player Board
  player_board_is_leader_text: '(Leader)',
  player_board_start_game: 'Start Game',
  player_board_continue_game: 'Continue Game',
  player_board_pause_game: 'Pause Game',
  player_board_leave_game: 'Leave Game',
  player_board_leave_and_observe_game: 'Leave & Observe Game',
  player_board_reset_game: 'Reset Game',
  player_board_show_instruction: 'Show Instruction',

  // Card
  card_menu_item_hide: 'Hide Card',
  card_menu_item_show: 'Show Card',
  card_menu_item_send_to: 'Send To',
  card_menu_item_drop: 'Drop',

  // Drop Card Modal
  drop_card_modal_heading: 'Drop Card',
  drop_card_modal_confirm_text:
    'Are you sure you want to drop your card? This card will be permanently removed. This action cannot be undone.',
  drop_card_modal_button_cancel: 'Cancel',
  drop_card_modal_button_drop: 'Drop Card',

  // Gift Card
  gift_card_menu_item_get_card_back: 'Get Card Back',
  gift_card_menu_item_regreat_card: 'Regret Card',

  // Send Card Modal
  send_card_modal_heading: 'Send Card',
  send_card_modal_select_player: 'Select player to send card.',
  send_card_modal_select_player_null_text: 'Select Player',
  send_card_modal_button_cancel: 'Cancel',
  send_card_modal_button_send: 'Send Card',
};
