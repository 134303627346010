import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import DeckBoard from 'components/organisms/DeckBoard/DeckBoard';

const mobileContainer = styled.main`
  height: 90vh;
`;
const StyledMain = tw(mobileContainer)`
  my-auto
  p-1
  relative
  block
  lg:h-full
`;
const StyledGrid = tw.div`
  grid
  grid-cols-3
  grid-rows-3
  gap-1
  mx-auto
  h-full
`;

const TopBarDiv = styled.div`
  height: 10vh;
`;
const DeckBoardWrapper = tw(TopBarDiv)`
  lg:hidden
  flex
  w-full
`;
const GameTemplate = ({ children }) => (
  <div className="bg-wood bg-cover bg-center h-screen w-screen">
    <DeckBoardWrapper>
      <DeckBoard />
    </DeckBoardWrapper>
    <StyledMain>
      <StyledGrid>{children}</StyledGrid>
    </StyledMain>
  </div>
);

GameTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default GameTemplate;
