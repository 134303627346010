import React from 'react';
import io from 'socket.io-client';
import { config } from 'constants.js';

export const socket = io(config.url.SOCKET_URL, {
  transports: ['websocket', 'polling'],
  path: config.url.SOCKET_PATH,
  autoConnect: true,
  upgrade: true,
  withCredentials: true,
  auth: {
    token: localStorage.getItem('token'),
  },
  query: {
    playerToken: sessionStorage.getItem('playerToken') || '',
    customGameId: sessionStorage.getItem('customGameId') || '',
  },
});
const SocketContext = React.createContext(socket);

export default SocketContext;
