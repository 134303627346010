import React, { useEffect, useState } from 'react';
import AdminTemplate from 'templates/AdminTemplate';
import Heading from 'components/atoms/Heading';
import tw, { styled } from 'twin.macro';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { Grid, _ } from 'gridjs-react';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { PencilAltIcon, XIcon } from '@heroicons/react/solid';
import Button from 'components/atoms/Button';
import StatusButton from 'components/atoms/StatusButton/StatusButton';
import { findLanguages, clearState, languageSelector } from './LanguagesSlice';
import NewLanguageModal from './NewLanguageModal/NewLanguageModal';
import DeleteLanguageModal from './DeleteLanguageModal/DeleteLanguageModal';
import EditLanguageModal from './EditLanguageModal/EditLanguageModal';

const StyledHeading = tw(Heading)`
  text-2xl
  mt-5
  pt-3
`;

const StyledButtonWrapper = tw.div`
    block
    w-40
    mt-5
    mr-auto
`;

const StyledButton = styled(Button)(({ color }) => [
  tw`
      w-16
      border-0
      py-1
      px-2
      ml-2
      text-xs
    `,
  color === 'red' && tw`bg-red-500 hover:bg-red-600`,
  color === 'blue' && tw`bg-blue-500 hover:bg-blue-600`,
]);

const LanguagesPage = () => {
  const [openNewLanguageModal, setOpenNewLanguageModal] = useState(false);
  const [openDeleteLanguageModal, setOpenDeleteLanguageModal] = useState(false);
  const [openEditLanguageModal, setOpenEditLanguageModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const languages = useSelector(languageSelector);
  const { isSuccess, isError, errorMessage, items } = languages;

  const itemsDTO = items.map((item) => {
    const { _id, name, code, status } = item;

    return {
      id: _id,
      name,
      code,
      status,
    };
  });

  useEffect(() => {
    dispatch(findLanguages());
  }, []);

  useEffect(() => () => dispatch(clearState()), []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  const handleOpenEditLanguageModal = (itemToEditId) => {
    setItemToEdit(items.find((item) => item._id === itemToEditId));
    setOpenEditLanguageModal(true);
  };

  const handleOpenDeleteLanguageModal = (itemToDeleteId) => {
    setItemToDelete(items.find((item) => item._id === itemToDeleteId));
    setOpenDeleteLanguageModal(true);
  };

  return (
    <AdminTemplate>
      <>
        {openNewLanguageModal && <NewLanguageModal open={openNewLanguageModal} setOpen={setOpenNewLanguageModal} />}
        {openDeleteLanguageModal && (
          <DeleteLanguageModal
            open={openDeleteLanguageModal}
            setOpen={setOpenDeleteLanguageModal}
            id={itemToDelete._id}
            name={itemToDelete.name}
          />
        )}
        {openEditLanguageModal && (
          <EditLanguageModal open={openEditLanguageModal} setOpen={setOpenEditLanguageModal} item={itemToEdit} />
        )}
        <StyledHeading as="h2">{t('languages_page_heading')}</StyledHeading>
        <Grid
          data={() =>
            new Promise((resolve) => {
              setTimeout(() => resolve(itemsDTO), 500);
            })
          }
          className={{
            table: 'w-full',
          }}
          columns={[
            {
              id: 'id',
              name: t('languages_page_column_id'),
              hidden: true,
            },
            {
              id: 'name',
              name: t('languages_page_column_name'),
            },
            {
              id: 'status',
              name: t('languages_page_column_status'),
              formatter: (cell) => _(<StatusButton active={cell} />),
            },
            {
              id: 'code',
              name: t('languages_page_column_code'),
            },
            {
              name: t('languages_page_column_options'),
              sort: false,
              formatter: (cell, row) =>
                _(
                  <>
                    <StyledButton color="blue" onClick={() => handleOpenEditLanguageModal(row.cells[0].data)}>
                      <PencilAltIcon className="w-3 mr-1" /> {t('languages_page_button_edit')}
                    </StyledButton>
                    <StyledButton color="red" onClick={() => handleOpenDeleteLanguageModal(row.cells[0].data)}>
                      <XIcon className="w-4 h-4 mr-1" /> {t('languages_page_button_delete')}
                    </StyledButton>
                  </>,
                ),
            },
          ]}
          search
          sort
          pagination={{
            enabled: true,
            limit: 15,
          }}
        />
        <StyledButtonWrapper>
          <Button onClick={() => setOpenNewLanguageModal(true)}>{t('languages_page_button_new_language')}</Button>
        </StyledButtonWrapper>
      </>
    </AdminTemplate>
  );
};

export default LanguagesPage;
