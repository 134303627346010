import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { Popover as HeadlessPopover } from '@headlessui/react';
import PropTypes from 'prop-types';

const props = {
  placement: 'bottom',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: ({ popper: panel }) => [panel.width / 2, -20],
      },
    },
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top', 'right'],
      },
    },
  ],
};
const Popover = ({ content, target }) => {
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, props);

  return (
    <HeadlessPopover>
      <HeadlessPopover.Button as="div" className="h-full" ref={setReferenceElement}>
        {target}
      </HeadlessPopover.Button>
      <HeadlessPopover.Panel
        className="z-50"
        ref={setPopperElement}
        style={styles.popper}
        data-popper-escaped={attributes?.popper?.['data-popper-escaped']}
        data-popper-placement={attributes?.popper?.['data-popper-placement']}
        data-popper-reference-hidden={attributes?.popper?.['data-popper-reference-hidden']}
      >
        {content}
      </HeadlessPopover.Panel>
    </HeadlessPopover>
  );
};
Popover.propTypes = {
  content: PropTypes.node.isRequired,
  target: PropTypes.node.isRequired,
};

export default Popover;
