import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import Modal, { StyledModalContentWrapper, StyledButtonsWrapper } from 'components/molecules/Modal/Modal';
import ModalButton from 'components/atoms/ModalButton/ModalButton';

const InstructionModal = ({ open, close, link }) => {
  const { t } = useTranslation();
  const cancelButtonRef = useRef(null);

  return (
    <Modal open={open} close={close} cancelButtonRef={cancelButtonRef} size="xl" scroll={false}>
      <StyledModalContentWrapper>
        <h2>Game Instruction:</h2>
        <iframe title="Instruction" className="w-full h-full" src={`${link}?embedded=true`} sandbox />
      </StyledModalContentWrapper>
      <StyledButtonsWrapper>
        <ModalButton ref={cancelButtonRef} type="button" onClick={() => close(false)}>
          {t('close_button')}
        </ModalButton>
      </StyledButtonsWrapper>
    </Modal>
  );
};

InstructionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
};

export default InstructionModal;
