import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { gameDataSelector, gameSelector } from 'features/game/GameSlice';
import Logo from 'components/atoms/Logo';
import Deck from 'components/molecules/Deck/Deck';

const StyledWrapper = tw.div`
    relative
    p-1
    h-full
    flex
    flex-row
    w-full
    lg:flex-col
`;

const StyledLogoWrapper = tw.div`
    h-full
    lg:h-1/6
`;

const StyledLogo = tw(Logo)`
    my-auto
    mx-auto
    h-auto
    w-full
`;

const StyledCardDeckWrapper = tw.div`
    flex
    flex-row
    lg:h-4/6
    lg:p-2
    w-full
    h-full
`;

const StyledGameIdWrapper = tw.div`
    h-1/6
    my-auto
    text-center
    mb-2
    w-full
    items-center
    text-white
`;

const DeckAndInfoWrapper = tw.div`
    flex
    flex-col
    h-full
    w-full
`;
const DeckBoard = ({ onlyWatch }) => {
  const { t } = useTranslation();
  const game = useSelector(gameSelector);
  const { cardTypes } = useSelector(gameDataSelector);
  const { customGameId, isStarted, isPaused } = game;

  const cardTypesCount = cardTypes.length;

  return (
    <StyledWrapper>
      <StyledLogoWrapper>
        <StyledLogo center withName={false} />
      </StyledLogoWrapper>
      <DeckAndInfoWrapper>
        {isStarted && !isPaused && (
          <StyledCardDeckWrapper>
            {cardTypes &&
              cardTypes.map((cardType) => {
                const { backCardColorFrom, backCardColorTo, backCardColorVia, image, _id, frontCardColor } = cardType;
                return (
                  <Deck
                    _id={_id}
                    colorFrom={backCardColorFrom}
                    colorTo={backCardColorTo}
                    colorVia={backCardColorVia}
                    mainColor={frontCardColor}
                    image={image}
                    cardTypesCount={cardTypesCount}
                    onlyWatch={onlyWatch}
                  />
                );
              })}
          </StyledCardDeckWrapper>
        )}
        {!isStarted && <h4 className="text-white text-2xl mx-auto my-auto">{t('deck_board_game_paused_info_text')}</h4>}
        {isStarted && isPaused && (
          <h4 className="text-white text-2xl mx-auto my-auto">{t('deck_board_game_paused')}</h4>
        )}
        <StyledGameIdWrapper>
          {t('deck_board_game_id')}: {customGameId}
        </StyledGameIdWrapper>
      </DeckAndInfoWrapper>
    </StyledWrapper>
  );
};

DeckBoard.propTypes = {
  onlyWatch: PropTypes.bool,
};

DeckBoard.defaultProps = {
  onlyWatch: false,
};

export default DeckBoard;
