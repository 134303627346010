import axios from 'axios';
import { config } from 'constants.js';
import authHeader from 'features/auth/authHeader';

const create = ({
  name,
  shortName,
  description,
  unitPrice,
  taxRate,
  gamesCount,
  subscriptionValidityPeriod,
  gameTypes,
}) =>
  axios.post(
    `${config.url.API_URL}/games-packages`,
    {
      name,
      shortName,
      description,
      currency: 'sek',
      unitPrice,
      quantity: 1,
      taxRate,
      gamesCount,
      subscriptionValidityPeriod,
      gameTypes,
    },
    { headers: authHeader() },
  );

const findOneById = ({ gamesPackageId }) => axios.get(`${config.url.API_URL}/games-packages/${gamesPackageId}`);

const find = () => axios.get(`${config.url.API_URL}/games-packages`, { headers: authHeader() });

const update = ({
  _id,
  name,
  shortName,
  status,
  description,
  unitPrice,
  taxRate,
  gamesCount,
  subscriptionValidityPeriod,
  gameTypes,
}) =>
  axios.patch(
    `${config.url.API_URL}/games-packages/${_id}`,
    {
      name,
      shortName,
      status,
      description,
      unitPrice,
      taxRate,
      gamesCount,
      subscriptionValidityPeriod,
      gameTypes,
    },
    { headers: authHeader() },
  );

const remove = ({ gamesPackageId }) =>
  axios.delete(`${config.url.API_URL}/games-packages/${gamesPackageId}`, { headers: authHeader() });

export default {
  create,
  findOneById,
  find,
  update,
  remove,
};
