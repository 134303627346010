import tw, { styled } from 'twin.macro';

const ModalButton = styled.button(({ color }) => [
  tw`w-full
    inline-flex
    justify-center
    rounded-md
    border
    shadow-sm
    px-4
    py-2
    text-base
    font-medium
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    sm:ml-3
    sm:w-auto
    sm:text-sm`,
  color === 'green' && tw`border-transparent text-white bg-green-600 hover:bg-green-700 focus:ring-green-500`,
  color === 'red' && tw`border-transparent text-white bg-red-600 hover:bg-red-700 focus:ring-red-500`,
  color === 'blue' && tw`border-transparent text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500`,
  color === undefined && tw`text-gray-700 border-gray-300 bg-white hover:bg-gray-50 focus:ring-indigo-500`,
]);

export default ModalButton;
