import React, { Fragment, useRef } from 'react';
import tw, { styled } from 'twin.macro';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import Heading from 'components/atoms/Heading';

const StyledWrapper = tw.div`
  flex
  items-end
  justify-center
  h-full
  pt-4
  px-4
  pb-20
  text-center
  sm:block
  sm:p-0

`;

const StyledContentWrapper = styled.div(({ size, scroll }) => [
  tw`
    inline-block
    align-bottom
    rounded-lg
    text-left
    shadow-xl
    transform
    transition-all
    my-8
    sm:align-middle
    w-full
  `,
  size === 'xl'
    ? tw`
    max-w-3xl
    h-2/3
    2xl:max-w-7xl
    2xl:h-1/2
  `
    : tw`
    sm:max-w-lg
    2xl:max-w-2xl
  `,
  scroll === true && tw`overflow-y-auto`,
]);

const Modal = ({ open, children, size, scroll, cancelButtonRef }) => {
  const fakeRef = useRef(null);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-20 inset-0" onClose={() => null} initialFocus={cancelButtonRef || fakeRef}>
        <StyledWrapper>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <StyledContentWrapper className="max-h-9/10" size={size} scroll={scroll} ref={fakeRef}>
              {children}
            </StyledContentWrapper>
          </Transition.Child>
        </StyledWrapper>
      </Dialog>
    </Transition.Root>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  size: PropTypes.string,
  scroll: PropTypes.bool,
  cancelButtonRef: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  size: '',
  scroll: false,
};

export default Modal;

export const StyledButtonsWrapper = tw.div`
  bg-gray-50
  px-4
  py-3
  sm:px-6
  sm:flex
  sm:flex-row-reverse
`;

export const StyledModalContentWrapper = tw.div`
  bg-white
  px-4
  pt-5
  pb-4
  sm:p-6
  sm:pb-4
  h-full
`;

export const StyledHeading = tw(Heading)`
    text-lg
    leading-6
    font-medium
    text-gray-900
`;
