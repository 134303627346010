/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import Modal, {
  StyledHeading,
  StyledModalContentWrapper,
  StyledButtonsWrapper,
} from 'components/molecules/Modal/Modal';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import { StyledFieldWrapper, StyledErrorMessage } from 'templates/AuthTemplate';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import { createLanguage } from '../LanguagesSlice';

const schema = yup.object().shape({
  name: yup.string().required(),
  code: yup.string().length(2).required(),
});

const NewLanguageModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    dispatch(createLanguage(data));
    setOpen(false);
  };

  return (
    <Modal open={open} close={setOpen}>
      <form onSubmit={handleSubmit(onSubmit)} method="POST">
        <StyledModalContentWrapper>
          <StyledHeading as="h3">{t('new_language_modal_heading')}</StyledHeading>

          <StyledFieldWrapper>
            <Label htmlFor="name">{t('language_modal_input_name')}</Label>
            <Input type="text" {...register('name')} placeholder={t('language_modal_input_name_placeholder')} />
            <StyledErrorMessage>{errors.name?.message}</StyledErrorMessage>
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="name">{t('language_model_input_country_code')}</Label>
            <Input type="text" {...register('code')} placeholder={t('language_model_input_country_code_placeholder')} />
            <StyledErrorMessage>{errors.code?.message}</StyledErrorMessage>
          </StyledFieldWrapper>
        </StyledModalContentWrapper>
        <StyledButtonsWrapper>
          <ModalButton type="submit" color="blue">
            {t('language_model_button_add')}
          </ModalButton>
          <ModalButton type="button" onClick={() => setOpen(false)}>
            {t('language_model_button_cancel')}
          </ModalButton>
        </StyledButtonsWrapper>
      </form>
    </Modal>
  );
};

NewLanguageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default NewLanguageModal;
