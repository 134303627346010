import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';

const StyledWrapper = tw.div`
  w-full
  flex
  items-center
`;

const StyledCardWrapper = tw.div`
  inline-block
  w-6
  items-center
  border
  border-gray-800
  mx-auto
`;

const StyledText = tw.span`
  text-xs
`;

const CardFrontExample = ({ color, textColor }) => (
  <StyledWrapper>
    <StyledCardWrapper>
      <div className={`bg-${color} items-center text-center text-${textColor}`}>
        <StyledText>text</StyledText>
      </div>
    </StyledCardWrapper>
  </StyledWrapper>
);

CardFrontExample.propTypes = {
  color: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default CardFrontExample;
