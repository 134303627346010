/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SocketContext from 'socket/socket';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import Modal, {
  StyledHeading,
  StyledModalContentWrapper,
  StyledButtonsWrapper,
} from 'components/molecules/Modal/Modal';
import Label from 'components/atoms/Label';
import Select from 'components/atoms/Select/Select';
import { StyledFieldWrapper } from 'templates/AuthTemplate';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import { findGameTypes, gameTypesSelector } from 'features/admin/gameTypes/GameTypesSlice';
import Paragraph from 'components/atoms/Paragraph';
import { getUserByToken, userSelector } from 'features/auth/AuthSlice';
import { createGame, findAllByUser } from '../LeaderGamesSlice';

const NewGameModal = ({ open, setOpen }) => {
  const socket = useContext(SocketContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const gameTypes = useSelector(gameTypesSelector);
  const user = useSelector(userSelector);

  const { isFetching: isFetchingGameTypes, items: gameTypeItems } = gameTypes;
  const { isFetching: isFetchingUser, subscriptions: userSubscriptions } = user;

  const [filteredGameTypeItems, setFilteredGameTypeItems] = useState(gameTypeItems);
  const [gameType, setGameType] = useState(filteredGameTypeItems[0]);
  const [firstLanguage, setFirstLanguage] = useState(gameType && gameType.availableLanguages[0]);
  const [secondLanguage, setSecondLanguage] = useState(null);
  const [availableGameTypesIds, setAvailableGameTypesIds] = useState([]);

  useEffect(() => {
    dispatch(findGameTypes());
    dispatch(getUserByToken());
  }, [dispatch]);

  useEffect(() => {
    setGameType(filteredGameTypeItems[0]);
  }, [isFetchingGameTypes]);

  useEffect(() => {
    const gameTypesFromSubscriptions = [];
    if (userSubscriptions) {
      Object.values(userSubscriptions).forEach((userSubscription) => {
        if (userSubscription.gamesCount > 0) {
          gameTypesFromSubscriptions.push(...userSubscription.gameTypesIds);
        }
      });
    }
    setAvailableGameTypesIds(_.sortedUniq(_.compact(gameTypesFromSubscriptions)));
  }, [user]);

  useEffect(() => {
    const filteredGameTypes = gameTypeItems.filter((singleGameType) => {
      if (availableGameTypesIds.includes(singleGameType._id)) {
        return singleGameType;
      }
      return null;
    });
    setFilteredGameTypeItems(filteredGameTypes);
  }, [gameTypes]);

  const { handleSubmit } = useForm();

  const onSubmit = () => {
    dispatch(
      createGame({
        gameType: gameType ? gameType._id : gameTypeItems[0]._id,
        firstLanguage: firstLanguage ? firstLanguage._id : gameType?.availableLanguages[0]._id,
        secondLanguage: secondLanguage ? secondLanguage._id : null,
      }),
    ).then(() => {
      dispatch(getUserByToken());
      dispatch(findAllByUser()).then((res) => {
        const userGames = res.payload;
        userGames.forEach((userGame) => {
          const { customId } = userGame || {};
          socket.emit('startObservingGameStatus', customId);
        });
      });
    });
    setOpen(false);
  };

  const handleGoToBuyPackageSection = () => {
    setOpen(false);
    history.push('/leader-board/buy-package');
  };

  return (
    <Modal open={open} close={setOpen} scroll>
      {availableGameTypesIds.length > 0 && (
        <form onSubmit={handleSubmit(onSubmit)} method="POST">
          <StyledModalContentWrapper>
            <StyledHeading as="h3">{t('new_game_modal_heading')}</StyledHeading>
            {!isFetchingGameTypes && !isFetchingUser && (
              <>
                <StyledFieldWrapper>
                  <Label htmlFor="gameType">{t('new_game_modal_input_game_type')}</Label>
                  <Select
                    items={filteredGameTypeItems}
                    name="gameType"
                    selectedItem={gameType}
                    setSelectedItem={setGameType}
                    nullable={false}
                  />
                </StyledFieldWrapper>
                <StyledFieldWrapper>
                  <Label htmlFor="firstLanguage">{t('new_game_modal_input_first_language')}</Label>
                  <Select
                    items={gameType?.availableLanguages}
                    name="firstLanguage"
                    selectedItem={firstLanguage}
                    setSelectedItem={setFirstLanguage}
                    nullable={false}
                  />
                </StyledFieldWrapper>
                <StyledFieldWrapper>
                  <Label htmlFor="secondLanguage">{t('new_game_modal_input_second_language')}</Label>
                  <Select
                    items={gameType?.availableLanguages}
                    name="secondLanguage"
                    selectedItem={secondLanguage}
                    setSelectedItem={setSecondLanguage}
                  />
                </StyledFieldWrapper>
              </>
            )}
          </StyledModalContentWrapper>
          <StyledButtonsWrapper>
            <ModalButton type="button" onClick={() => setOpen(false)}>
              {t('new_game_modal_button_cancel')}
            </ModalButton>
            <ModalButton type="submit" color="blue">
              {t('new_game_modal_button_add_new')}
            </ModalButton>
          </StyledButtonsWrapper>
        </form>
      )}

      {availableGameTypesIds.length === 0 && (
        <form onSubmit={handleSubmit(onSubmit)} method="POST">
          <StyledModalContentWrapper>
            <StyledHeading as="h3">{t('new_game_modal_heading')}</StyledHeading>
            <Paragraph>{t('new_game_modal_cannot_create_game_part_1')}</Paragraph>
            <Paragraph>{t('new_game_modal_cannot_create_game_part_2')}</Paragraph>
          </StyledModalContentWrapper>
          <StyledButtonsWrapper>
            <ModalButton type="button" onClick={() => setOpen(false)}>
              {t('new_game_modal_button_cancel')}
            </ModalButton>
            {availableGameTypesIds.length > 0 ? (
              <ModalButton type="submit" color="blue">
                {t('new_game_modal_button_add_new')}
              </ModalButton>
            ) : (
              <ModalButton type="button" color="blue" onClick={handleGoToBuyPackageSection}>
                {t('new_game_modal_button_buy_package')}
              </ModalButton>
            )}
          </StyledButtonsWrapper>
        </form>
      )}
    </Modal>
  );
};

NewGameModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default NewGameModal;
