/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const playerBoardSlice = createSlice({
  name: 'playerBoard',
  initialState: {
    isDropCardModalOpen: false,
    cardToDropId: null,
    isSendCardModalOpen: false,
    cardToSendId: null,
    isBiggerPlayerBoardModalOpen: false,
    playerToShowInBiggerModalId: null,
    isBiggerMyPlayerBoardModalOpen: false,
    isInstructionModalOpen: false,
  },
  reducers: {
    openDropCardModal: (state, { payload }) => {
      state.isDropCardModalOpen = true;
      state.cardToDropId = payload.cardToDropId;
    },
    closeDropCardModal: (state) => {
      state.isDropCardModalOpen = false;
      state.cardToDropId = null;
    },
    openSendCardModal: (state, { payload }) => {
      state.isSendCardModalOpen = true;
      state.cardToSendId = payload.cardToSendId;
    },
    closeSendCardModal: (state) => {
      state.isSendCardModalOpen = false;
      state.cardToSendId = null;
    },
    openBiggerPlayerBoardModal: (state, { payload }) => {
      state.isBiggerPlayerBoardModalOpen = true;
      state.playerToShowInBiggerModalId = payload.playerToShowInBiggerModalId;
    },
    closeBiggerPlayerBoardModal: (state) => {
      state.isBiggerPlayerBoardModalOpen = false;
      state.playerToShowInBiggerModalId = null;
    },
    openBiggerMyPlayerBoardModal: (state) => {
      state.isBiggerMyPlayerBoardModalOpen = true;
    },
    closeBiggerMyPlayerBoardModal: (state) => {
      state.isBiggerMyPlayerBoardModalOpen = false;
    },
    openInstructionModal: (state) => {
      state.isInstructionModalOpen = true;
    },
    closeInstructionModal: (state) => {
      state.isInstructionModalOpen = false;
    },
  },
});

export const {
  openDropCardModal,
  closeDropCardModal,
  openSendCardModal,
  closeSendCardModal,
  openBiggerPlayerBoardModal,
  closeBiggerPlayerBoardModal,
  openBiggerMyPlayerBoardModal,
  closeBiggerMyPlayerBoardModal,
  openInstructionModal,
  closeInstructionModal,
} = playerBoardSlice.actions;

export const playerBoardSelector = (state) => state.playerBoard;
