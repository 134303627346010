import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import tw, { styled } from 'twin.macro';
import toast from 'react-hot-toast';
import { Grid, _ } from 'gridjs-react';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { PencilAltIcon, XIcon } from '@heroicons/react/solid';
import { findCardTypes, clearState, cardTypesSelector } from 'features/admin/cardTypes/CardTypesSlice';
import AdminTemplate from 'templates/AdminTemplate';
import Button from 'components/atoms/Button';
import NewCardTypeModal from 'features/admin/cardTypes/NewCardTypeModal/NewCardTypeModal';
import Heading from 'components/atoms/Heading';
import StatusButton from 'components/atoms/StatusButton/StatusButton';
import CardFrontExample from 'components/molecules/CardsExamples/CardFrontExample';
import CardBackExample from 'components/molecules/CardsExamples/CardBackExample';
import DeleteCardTypeModal from './DeleteCardTypeModal/DeleteCardTypeModal';
import EditCardTypeModal from './EditCardTypeModal/EditCardTypeModal';

const StyledHeading = tw(Heading)`
  text-2xl
  mt-5
  pt-3
`;

const StyledButtonWrapper = tw.div`
    block
    w-36
    mt-5
    mr-auto
`;

const StyledButton = styled(Button)(({ color }) => [
  tw`
      w-16
      border-0
      py-1
      px-2
      ml-2
      text-xs
    `,
  color === 'red' && tw`bg-red-500 hover:bg-red-600`,
  color === 'blue' && tw`bg-blue-500 hover:bg-blue-600`,
]);

const CardTypesPage = () => {
  const [openNewCardTypeModal, setOpenNewCardTypeModal] = useState(false);
  const [openDeleteCardTypeModal, setOpenDeleteCardTypeModal] = useState(false);
  const [openEditCardTypeModal, setOpenEditCardTypeModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cardTypes = useSelector(cardTypesSelector);
  const { isSuccess, isError, errorMessage, items } = cardTypes;

  const itemsDTO = items.map((item) => {
    const {
      _id,
      name,
      status,
      frontCardColor,
      textColor,
      backCardColorFrom,
      backCardColorVia,
      backCardColorTo,
      image,
    } = item;

    return {
      id: _id,
      name,
      status,
      frontCardDesign: { frontCardColor, textColor },
      backCardDesign: { backCardColorFrom, backCardColorVia, backCardColorTo, image },
    };
  });

  useEffect(() => {
    dispatch(findCardTypes());
  }, []);

  useEffect(() => () => dispatch(clearState()), []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  const handleOpenEditCardTypeModal = (itemToEditId) => {
    setItemToEdit(items.find((item) => item._id === itemToEditId));
    setOpenEditCardTypeModal(true);
  };

  const handleOpenDeleteCardTypeModal = (itemToDeleteId) => {
    setItemToDelete(items.find((item) => item._id === itemToDeleteId));
    setOpenDeleteCardTypeModal(true);
  };

  return (
    <AdminTemplate>
      <>
        {openNewCardTypeModal && <NewCardTypeModal open={openNewCardTypeModal} setOpen={setOpenNewCardTypeModal} />}
        {openDeleteCardTypeModal && (
          <DeleteCardTypeModal
            open={openDeleteCardTypeModal}
            setOpen={setOpenDeleteCardTypeModal}
            id={itemToDelete._id}
            name={itemToDelete.name}
          />
        )}
        {openEditCardTypeModal && (
          <EditCardTypeModal open={openEditCardTypeModal} setOpen={setOpenEditCardTypeModal} item={itemToEdit} />
        )}
        <StyledHeading as="h2">{t('card_types_page_heading')}</StyledHeading>
        <Grid
          data={() =>
            new Promise((resolve) => {
              setTimeout(() => resolve(itemsDTO), 500);
            })
          }
          className={{
            table: 'w-full',
          }}
          columns={[
            {
              id: 'id',
              name: t('card_types_page_column_id'),
              hidden: true,
            },
            {
              id: 'name',
              name: t('card_types_page_column_name'),
            },
            {
              id: 'status',
              name: t('card_types_page_column_status'),
              formatter: (cell) => _(<StatusButton active={cell} />),
            },
            {
              id: 'frontCardDesign',
              name: t('card_types_page_column_front_card_design'),
              sort: false,
              formatter: ({ frontCardColor, textColor }) =>
                _(<CardFrontExample color={frontCardColor} textColor={textColor} />),
            },
            {
              id: 'backCardDesign',
              name: t('card_types_page_column_back_card_design'),
              sort: false,
              formatter: ({ backCardColorFrom, backCardColorVia, backCardColorTo, image }) =>
                _(
                  <CardBackExample
                    colorFrom={backCardColorFrom}
                    colorVia={backCardColorVia}
                    colorTo={backCardColorTo}
                    image={image}
                  />,
                ),
            },
            {
              name: t('card_types_page_column_options'),
              sort: false,
              formatter: (cell, row) =>
                _(
                  <>
                    <StyledButton color="blue" onClick={() => handleOpenEditCardTypeModal(row.cells[0].data)}>
                      <PencilAltIcon className="w-3 mr-1" /> {t('card_types_page_button_edit')}
                    </StyledButton>
                    <StyledButton color="red" onClick={() => handleOpenDeleteCardTypeModal(row.cells[0].data)}>
                      <XIcon className="w-4 h-4 mr-1" /> {t('card_types_page_button_delete')}
                    </StyledButton>
                  </>,
                ),
            },
          ]}
          search
          sort
          pagination={{
            enabled: true,
            limit: 15,
          }}
        />
        <StyledButtonWrapper>
          <Button onClick={() => setOpenNewCardTypeModal(true)}>{t('card_types_page_button_new_card_type')}</Button>
        </StyledButtonWrapper>
      </>
    </AdminTemplate>
  );
};

export default CardTypesPage;
