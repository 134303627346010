import React, { useEffect, useState } from 'react';
import AdminTemplate from 'templates/AdminTemplate';
import Heading from 'components/atoms/Heading';
import tw, { styled } from 'twin.macro';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { Grid, _ } from 'gridjs-react';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { PencilAltIcon, XIcon } from '@heroicons/react/solid';
import Paragraph from 'components/atoms/Paragraph';
import Button from 'components/atoms/Button';
import { findGameTypes, clearState, gameTypesSelector } from 'features/admin/gameTypes/GameTypesSlice';
import StatusButton from 'components/atoms/StatusButton/StatusButton';
import RoundStatusButton from 'components/atoms/StatusButton/RoundStatusButton';
import NewGameTypeModal from './NewGameTypeModal/NewGameTypeModal';
import DeleteGameTypeModal from './DeleteGameTypeModal/DeleteGameTypeModal';
import EditGameTypeModal from './EditGameTypeModal/EditGameTypeModal';
import { findLanguages, languageSelector } from '../languages/LanguagesSlice';

const StyledHeading = tw(Heading)`
  text-2xl
  mt-5
  pt-3
`;

const StyledButtonWrapper = tw.div`
    block
    w-40
    mt-5
    mr-auto
`;

const StyledButton = styled(Button)(({ color }) => [
  tw`
      w-16
      border-0
      py-1
      px-2
      ml-2
      text-xs
    `,
  color === 'red' && tw`bg-red-500 hover:bg-red-600`,
  color === 'blue' && tw`bg-blue-500 hover:bg-blue-600`,
]);

const GameTypesPage = () => {
  const { t } = useTranslation();
  const [openNewGameTypeModal, setOpenNewGameTypeModal] = useState(false);
  const [openDeleteGameTypeModal, setOpenDeleteGameTypeModal] = useState(false);
  const [openEditGameTypeModal, setOpenEditGameTypeModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);

  const dispatch = useDispatch();
  const gameTypes = useSelector(gameTypesSelector);
  const { isSuccess, isError, errorMessage, items } = gameTypes;

  const languages = useSelector(languageSelector);
  const { items: languageItems } = languages;

  const itemsDTO = items.map((item) => {
    const { _id, name, status, initialDecks, category, instructionLink, availableLanguages } = item;

    const initialDecksDto = {
      deck1:
        initialDecks[0] && initialDecks[0].cardType
          ? `${initialDecks[0].cardType.name} / ${initialDecks[0].startCards}`
          : `- / -`,
      deck2:
        initialDecks[1] && initialDecks[1].cardType
          ? `${initialDecks[1].cardType.name} / ${initialDecks[1].startCards}`
          : `- / -`,
      deck3:
        initialDecks[2] && initialDecks[2].cardType
          ? `${initialDecks[2].cardType.name} / ${initialDecks[2].startCards}`
          : `- / -`,
    };

    return {
      id: _id,
      name,
      status,
      initialDecksDto,
      category: category ? category.name : '',
      instructionLink,
      availableLanguages,
    };
  });

  useEffect(() => {
    dispatch(findGameTypes());
    dispatch(findLanguages());
  }, []);

  useEffect(() => () => dispatch(clearState()), []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  const handleOpenEditGameTypeModal = (itemToEditId) => {
    setItemToEdit(items.find((item) => item._id === itemToEditId));
    setOpenEditGameTypeModal(true);
  };

  const handleOpenDeleteGameTypeModal = (itemToDeleteId) => {
    setItemToDelete(items.find((item) => item._id === itemToDeleteId));
    setOpenDeleteGameTypeModal(true);
  };

  return (
    <AdminTemplate>
      <>
        {openNewGameTypeModal && (
          <NewGameTypeModal open={openNewGameTypeModal} setOpen={setOpenNewGameTypeModal} languages={languageItems} />
        )}
        {openDeleteGameTypeModal && (
          <DeleteGameTypeModal
            open={openDeleteGameTypeModal}
            setOpen={setOpenDeleteGameTypeModal}
            id={itemToDelete._id}
            name={itemToDelete.name}
          />
        )}
        {openEditGameTypeModal && (
          <EditGameTypeModal
            open={openEditGameTypeModal}
            setOpen={setOpenEditGameTypeModal}
            item={itemToEdit}
            languages={languageItems}
          />
        )}
        <StyledHeading as="h2">{t('')}</StyledHeading>
        <Grid
          data={() =>
            new Promise((resolve) => {
              setTimeout(() => resolve(itemsDTO), 500);
            })
          }
          className={{
            table: 'w-full',
          }}
          columns={[
            {
              id: 'id',
              name: t('game_types_page_column_id'),
              hidden: true,
            },
            {
              id: 'name',
              name: t('game_types_page_column_name'),
            },
            {
              id: 'status',
              name: t('game_types_page_column_status'),
              formatter: (cell) => _(<StatusButton active={cell} />),
            },
            {
              id: 'initialDecksDto',
              name: t('game_types_page_column_types_counts'),
              sort: false,
              formatter: ({ deck1, deck2, deck3 }) =>
                _(
                  <>
                    <Paragraph center>{deck1}</Paragraph>
                    <Paragraph center>{deck2}</Paragraph>
                    <Paragraph center>{deck3}</Paragraph>
                  </>,
                ),
            },
            {
              id: 'category',
              name: t('game_types_page_column_category'),
            },
            {
              id: 'instructionLink',
              name: t('game_types_page_column_instruction'),
              formatter: (instructionLink) =>
                _(
                  <>
                    <Paragraph center>
                      {instructionLink ? <RoundStatusButton active /> : <RoundStatusButton />}
                    </Paragraph>
                  </>,
                ),
            },
            {
              id: 'availableLanguages',
              name: t('game_types_page_column_available_languages'),
              formatter: (availableLanguages) =>
                _(
                  <>
                    {' '}
                    {availableLanguages && (
                      <Paragraph center>{availableLanguages.map((language) => `${language.code}, `)}</Paragraph>
                    )}
                  </>,
                ),
            },
            {
              name: t('game_types_page_column_options'),
              sort: false,
              formatter: (cell, row) =>
                _(
                  <>
                    <StyledButton color="blue" onClick={() => handleOpenEditGameTypeModal(row.cells[0].data)}>
                      <PencilAltIcon className="w-3 mr-1" /> {t('game_types_page_button_edit')}
                    </StyledButton>
                    <StyledButton color="red" onClick={() => handleOpenDeleteGameTypeModal(row.cells[0].data)}>
                      <XIcon className="w-4 h-4 mr-1" /> {t('game_types_page_button_delete')}
                    </StyledButton>
                  </>,
                ),
            },
          ]}
          search
          sort
          pagination={{
            enabled: true,
            limit: 15,
          }}
        />
        <StyledButtonWrapper>
          <Button onClick={() => setOpenNewGameTypeModal(true)}>{t('game_types_page_button_new_game_type')}</Button>
        </StyledButtonWrapper>
      </>
    </AdminTemplate>
  );
};

export default GameTypesPage;
