import React from 'react';
import { NavLink } from 'react-router-dom';
import tw from 'twin.macro';
import { routes } from 'routes';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';

const StyledWrapper = tw.nav`
    flex
    flex-wrap
    items-center
    text-base
    justify-center
    mb-4
`;

const NavItem = tw(NavLink)`
    flex-grow
    border-b-2
    py-2
    text-lg
    px-1
    cursor-pointer
    border-gray-300
`;

const LeaderBoardMenu = () => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <NavItem to={routes.leaderBoard.games} activeStyle={tw`text-indigo-500 border-indigo-500`}>
        {t('leader_nav_menu_your_games')}
      </NavItem>
      <NavItem to={routes.leaderBoard.buyPackage} activeStyle={tw`text-indigo-500 border-indigo-500`}>
        {t('leader_nav_menu_buy_package')}
      </NavItem>
      <NavItem to={routes.leaderBoard.subscriptions} activeStyle={tw`text-indigo-500 border-indigo-500`}>
        {t('leader_nav_menu_packages_order_hisotry')}
      </NavItem>
      <NavItem to={routes.leaderBoard.instructions} activeStyle={tw`text-indigo-500 border-indigo-500`}>
        {t('leader_nav_menu_instructions')}
      </NavItem>
    </StyledWrapper>
  );
};

export default LeaderBoardMenu;
