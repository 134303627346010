/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isLoginAsAdmin } from 'features/auth/isLogin';

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (isLoginAsAdmin() ? <Component {...props} /> : <Redirect to="/login" />)} />
);

AdminRoute.propTypes = {
  component: PropTypes.func.isRequired,
  rest: PropTypes.node,
};

AdminRoute.defaultProps = {
  rest: null,
};

export default AdminRoute;
