/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import Modal, {
  StyledHeading,
  StyledModalContentWrapper,
  StyledButtonsWrapper,
} from 'components/molecules/Modal/Modal';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import { StyledFieldWrapper, StyledErrorMessage } from 'templates/AuthTemplate';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import Toggle from 'components/molecules/Toggle/Toggle';
import { updateCategory } from '../CategoriesSlice';

const schema = yup.object().shape({
  name: yup.string().required(),
});

const EditCategoryModal = ({ open, setOpen, item }) => {
  const { _id, name, status: statusOld } = item;

  const [status, setStatus] = useState(statusOld);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name,
    },
  });

  const onSubmit = (dataFromForm) => {
    const data = {
      _id,
      ...dataFromForm,
      status,
    };
    dispatch(updateCategory(data));
    setOpen(false);
  };

  return (
    <Modal open={open} close={setOpen}>
      <form onSubmit={handleSubmit(onSubmit)} method="POST">
        <StyledModalContentWrapper>
          <StyledHeading as="h3">
            {t('edit_category_modal_heading')}: &quot;{name}&quot;
          </StyledHeading>
          <StyledFieldWrapper>
            <Label htmlFor="name">{t('new_category_modal_input_name')}</Label>
            <Input type="text" {...register('name')} placeholder={t('new_category_modal_input_name_placeholder')} />
            <StyledErrorMessage>{errors.name?.message}</StyledErrorMessage>
          </StyledFieldWrapper>

          <Toggle enabled={status} setEnabled={setStatus} />
        </StyledModalContentWrapper>

        <StyledButtonsWrapper>
          <ModalButton type="submit" color="blue">
            {t('game_type_modal_button_save')}
          </ModalButton>
          <ModalButton type="button" onClick={() => setOpen(false)}>
            {t('new_category_modal_button_cancel')}
          </ModalButton>
        </StyledButtonsWrapper>
      </form>
    </Modal>
  );
};

EditCategoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
};

export default EditCategoryModal;
