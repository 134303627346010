import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { gameDataSelector } from 'features/game/GameSlice';
import styled from 'styled-components';
import Popover from 'components/molecules/Popover/Popover';
import ShownCard from '../ShownCard/ShownCard';
import CardMenu from '../CardMenu/CardMenu';

const StyledWrapper = styled.div(({ inModal }) => [
  tw`
  relative
  inline-block
  w-full
  rounded
  bg-white
  h-9
  lg:h-full
`,
  inModal && tw`md:h-full md:max-w-[10rem]`,
]);

const CardShowed = tw.div`
    absolute
    top-0
    right-0
    h-4
    w-4
    -mt-1
    -mr-1
    2xl:h-6
    2xl:w-6
    2xl:-mt-2
    2xl:-mr-2
    border-2
    border-white
    rounded-full
    bg-green-400
    z-10
`;

const MyCard = ({ card, isShowed, inModal }) => {
  const { _id: cardId, cardType: cardTypeId, translations } = card || {};

  const { cardTypes, firstLanguage, secondLanguage } = useSelector(gameDataSelector);

  const { text: firstText } =
    translations.find((translation) => translation.language === (firstLanguage && firstLanguage._id)) || {};

  const { text: secondText } =
    translations.find((translation) => translation.language === (secondLanguage && secondLanguage._id)) || {};

  const cardType = cardTypes.find((cardTypeFromAllCardTypes) => cardTypeFromAllCardTypes._id === cardTypeId);

  const { frontCardColor, textColor } = cardType || {};

  return (
    <Popover
      content={
        <CardMenu
          cardId={cardId}
          isShowed={isShowed}
          title={firstText}
          titleBackground={frontCardColor}
          titleColor={textColor}
        />
      }
      target={
        <StyledWrapper inModal={inModal}>
          {isShowed && <CardShowed />}
          <ShownCard colorMain={frontCardColor} text={firstText} textSecond={secondText} textColor={textColor} />
        </StyledWrapper>
      }
    />
  );
};

MyCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  card: PropTypes.object.isRequired,
  isShowed: PropTypes.bool,
  inModal: PropTypes.bool,
};

MyCard.defaultProps = {
  isShowed: false,
  inModal: false,
};

export default MyCard;
