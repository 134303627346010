import React, { useState } from 'react';
import tw from 'twin.macro';
import { CheckIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

const StyledWrapper = tw.li`
  cursor-default
  select-none
  relative
  py-2
  pl-3
  pr-9
`;

const IconSpan = tw.span`
  absolute
  inset-y-0
  right-0 flex
  items-center
  pr-4
`;

const SelectItem = ({ item, selectedItem, setSelectedItem, setOpen }) => {
  const [highlighted, setHighlighted] = useState(false);

  const handleOnMouseEnter = () => {
    setHighlighted(true);
  };

  const handleOnMouseLeave = () => {
    setHighlighted(false);
  };

  const handleClick = () => {
    setSelectedItem(item);
    setOpen(false);
  };

  return (
    <StyledWrapper
      id="listbox-option-0"
      className={highlighted ? `text-white bg-indigo-600` : `text-gray-900`}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={handleClick}
    >
      <div className="flex items-center">
        <span className="font-normal ml-3 block truncate capitalize">
          {(item && (item.name || item.username)) || 'None'}
        </span>
      </div>

      <IconSpan className={highlighted ? `text-white` : `text-indigo-600`}>
        {item === selectedItem && <CheckIcon className="h-5 w-5" />}
      </IconSpan>
    </StyledWrapper>
  );
};

SelectItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  selectedItem: PropTypes.object,
  setSelectedItem: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
};

SelectItem.defaultProps = {
  item: null,
  selectedItem: null,
};

export default SelectItem;
