import React, { useContext, useEffect, useState } from 'react';
import tw from 'twin.macro';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SocketContext from 'socket/socket';
import GameTemplate from 'templates/GameTemplate';
import {
  clearState,
  gameSelector,
  joinError,
  updateCustomGameId,
  updateGameData,
  updateGameStatus,
  updatePlayers,
} from 'features/game/GameSlice';
import toast from 'react-hot-toast';
import DeckBoard from 'components/organisms/DeckBoard/DeckBoard';
import PlayerSeat from 'components/molecules/PlayerSeat/PlayerSeat';
import { getUserByToken } from 'features/auth/AuthSlice';
import CornerButton from 'components/atoms/CornerButton/CornerButton';
import DropdownMenu from 'components/organisms/DropdownMenu/DropdownMenu';
import MenuItem from 'components/organisms/DropdownMenu/MenuItem';
import InstructionModal from 'components/organisms/InstructionModal/InstructionModal';
import { closeInstructionModal, openInstructionModal, playerBoardSelector } from './PlayerBoardSlice';

const StyledLeaderMenu = tw.div`
  absolute
  right-7
  bottom-7
  block
`;

const GameObservingPage = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const game = useSelector(gameSelector);
  const { isError, errorMessage, players, gameId, customGameId, isStarted, isPaused, gameInstruction } = game;

  const playerBoard = useSelector(playerBoardSelector);
  const { isInstructionModalOpen } = playerBoard;

  const [player1, setPlayer1] = useState(null);
  const [player2, setPlayer2] = useState(null);
  const [player3, setPlayer3] = useState(null);
  const [player4, setPlayer4] = useState(null);
  const [player5, setPlayer5] = useState(null);
  const [player6, setPlayer6] = useState(null);
  const [player7, setPlayer7] = useState(null);
  const [player8, setPlayer8] = useState(null);

  useEffect(() => {
    const customGameIdFromUrl = location.pathname.replace('/observing-game/', '');
    dispatch(updateCustomGameId({ customGameId: customGameIdFromUrl }));
    dispatch(getUserByToken());
  }, []);

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }
  }, [socket.connected]);

  useEffect(() => {
    socket.on('connect', () => {
      const customGameIdFromUrl = location.pathname.replace('/observing-game/', '');
      socket.emit('startObservingGame', customGameIdFromUrl);
    });

    socket.on('playersUpdated', (allPlayers) => {
      dispatch(updatePlayers(allPlayers));
    });
    socket.on('gameDataUpdated', (gameData) => {
      dispatch(updateGameData(gameData));
    });
    socket.on('gameStatusUpdated', (gameStatus) => {
      dispatch(updateGameStatus(gameStatus));
    });
    socket.on('gameDeleted', () => {
      window.close();
    });
    socket.on('exception', (data) => {
      dispatch(joinError(data));
    });
    socket.on('error', (error) => {
      toast.error(error.message);
    });

    return () => {
      socket.off('connect');
      socket.off('playersUpdated');
      socket.off('gameDataUpdated');
      socket.off('gameStatusUpdated');
      socket.off('exception');
      socket.off('error');
    };
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
      history.push(`/login`);
    }
  }, [isError]);

  useEffect(() => {
    setPlayer1(players.find((playerFromState) => playerFromState.position === 1));
    setPlayer2(players.find((playerFromState) => playerFromState.position === 2));
    setPlayer3(players.find((playerFromState) => playerFromState.position === 3));
    setPlayer4(players.find((playerFromState) => playerFromState.position === 4));
    setPlayer5(players.find((playerFromState) => playerFromState.position === 5));
    setPlayer6(players.find((playerFromState) => playerFromState.position === 6));
    setPlayer7(players.find((playerFromState) => playerFromState.position === 7));
    setPlayer8(players.find((playerFromState) => playerFromState.position === 8));
  }, [players]);

  const handleStartPauseContinueGame = () => {
    if (!isStarted) {
      socket.emit('startGame', { gameId });
    }
    if (isStarted && !isPaused) {
      socket.emit('pauseGame', { gameId });
    }
    if (isStarted && isPaused) {
      socket.emit('continueGame', { gameId });
    }
  };

  const handleShowInstructionModalClick = () => {
    dispatch(openInstructionModal());
  };

  const closeInstructiondModalFn = () => {
    dispatch(closeInstructionModal());
  };

  const handleRestartGame = () => {
    socket.emit('restartGame', customGameId);
  };

  return (
    <>
      <GameTemplate>
        <InstructionModal open={isInstructionModalOpen} close={closeInstructiondModalFn} link={gameInstruction} />
        <PlayerSeat player={player5} />
        <PlayerSeat player={player2} />
        <PlayerSeat player={player6} />
        <PlayerSeat player={player3} />
        <DeckBoard onlyWatch />
        <PlayerSeat player={player4} />
        <PlayerSeat player={player7} />
        <PlayerSeat player={player1} />
        <PlayerSeat player={player8} />
        <StyledLeaderMenu>
          <DropdownMenu menuButton={<CornerButton />} openDirection="top-left">
            <MenuItem
              text={isStarted ? (isPaused && 'Continue Game') || (!isPaused && 'Pause Game') : 'Start Game'}
              itemFunction={handleStartPauseContinueGame}
            />
            {isStarted && <MenuItem text="Reset Game" itemFunction={handleRestartGame} />}
            {gameInstruction && <MenuItem text="Show Instruction" itemFunction={handleShowInstructionModalClick} />}
          </DropdownMenu>
        </StyledLeaderMenu>
      </GameTemplate>
    </>
  );
};

export default GameObservingPage;
