import tw from 'twin.macro';

const Button = tw.button`
    bg-blue-500
    text-white
    inline-flex
    py-3
    px-4
    mx-2
    w-full
    rounded
    items-center
    text-center
    focus:outline-none
`;

export default Button;
