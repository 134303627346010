/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Modal, {
  StyledModalContentWrapper,
  StyledButtonsWrapper,
  StyledHeading,
} from 'components/molecules/Modal/Modal';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import Paragraph from 'components/atoms/Paragraph';
import SocketContext from 'socket/socket';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { playerBoardSelector } from 'features/game/PlayerBoardSlice';

const DropCardModal = ({ open, close }) => {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);

  const playerBoard = useSelector(playerBoardSelector);

  const { cardToDropId } = playerBoard;

  const handleDropCardButtonClick = () => {
    if (cardToDropId) {
      socket.emit('dropCard', cardToDropId);
    }
    close();
  };

  return (
    <Modal open={open} close={close} scroll>
      <StyledModalContentWrapper>
        <StyledHeading as="h3">{t('drop_card_modal_heading')}</StyledHeading>
        <Paragraph>{t('drop_card_modal_confirm_text')}</Paragraph>
      </StyledModalContentWrapper>
      <StyledButtonsWrapper>
        <ModalButton type="button" onClick={() => close()}>
          {t('drop_card_modal_button_cancel')}
        </ModalButton>
        <ModalButton type="button" color="red" onClick={handleDropCardButtonClick}>
          {t('drop_card_modal_button_drop')}
        </ModalButton>
      </StyledButtonsWrapper>
    </Modal>
  );
};

DropCardModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default DropCardModal;
