/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CardTypeAPI from './CardTypesAPI';

export const createCardType = createAsyncThunk(
  'cardTypes/create',
  async (
    { name, status, frontCardColor, textColor, backCardColorFrom, backCardColorVia, backCardColorTo, image },
    thunkAPI,
  ) => {
    try {
      const response = await CardTypeAPI.create({
        name,
        status,
        frontCardColor,
        textColor,
        backCardColorFrom,
        backCardColorVia,
        backCardColorTo,
        image,
      });
      const { data } = response;
      if (response.status === 201) {
        return data;
      }
      return thunkAPI.rejectWithValue(data);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const updateCardType = createAsyncThunk(
  'cardType/update',
  async (
    { _id, name, status, frontCardColor, textColor, backCardColorFrom, backCardColorVia, backCardColorTo, image },
    thunkAPI,
  ) => {
    try {
      const response = await CardTypeAPI.update({
        _id,
        name,
        status,
        frontCardColor,
        textColor,
        backCardColorFrom,
        backCardColorVia,
        backCardColorTo,
        image,
      });
      const { data } = response;
      if (response.status === 200) {
        return data;
      }
      return thunkAPI.rejectWithValue(data);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const findCardTypeById = createAsyncThunk('cardTypes/findById', async ({ id }, thunkAPI) => {
  try {
    const response = await CardTypeAPI.findOneById({ id });
    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const findCardTypes = createAsyncThunk('cardTypes/find', async (thunkAPI) => {
  try {
    const response = await CardTypeAPI.find();

    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const deleteCardType = createAsyncThunk('cardTypes/delete', async ({ id }, thunkAPI) => {
  try {
    const response = await CardTypeAPI.remove({ cardTypeId: id });

    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const cardTypesSlice = createSlice({
  name: 'cardTypes',
  initialState: {
    items: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createCardType.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = [...state.items, payload];
    },
    [createCardType.pending]: (state) => {
      state.isFetching = true;
    },
    [createCardType.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [updateCardType.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = state.items.map((item) => (item._id === payload._id ? payload : item));
    },
    [updateCardType.pending]: (state) => {
      state.isFetching = true;
    },
    [updateCardType.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [findCardTypes.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findCardTypes.pending]: (state) => {
      state.isFetching = true;
    },
    [findCardTypes.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [findCardTypeById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findCardTypeById.pending]: (state) => {
      state.isFetching = true;
    },
    [findCardTypeById.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [deleteCardType.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = state.items.filter((item) => item._id !== payload._id);
    },
    [deleteCardType.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteCardType.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
  },
});

export const { clearState } = cardTypesSlice.actions;

export const cardTypesSelector = (state) => state.cardTypes;
