/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GamesAPI from './GamesAPI';

export const createGame = createAsyncThunk(
  'leaderGame/create',
  async ({ gameType, firstLanguage, secondLanguage, fromSharedPackage }, thunkAPI) => {
    try {
      const response = await GamesAPI.create({
        gameType,
        firstLanguage,
        secondLanguage,
        fromSharedPackage,
      });
      const { data } = response;
      if (response.status === 201) {
        return data;
      }
      return thunkAPI.rejectWithValue(data);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const findGameById = createAsyncThunk('games/findById', async ({ id }, thunkAPI) => {
  try {
    const response = await GamesAPI.findOneById({ id });
    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const findAllByUser = createAsyncThunk('games/findAllByUser', async (thunkAPI) => {
  try {
    const response = await GamesAPI.findAllByUser();
    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteGame = createAsyncThunk('games/delete', async ({ id }, thunkAPI) => {
  try {
    const response = await GamesAPI.remove({ gameId: id });

    const { data } = response;
    if (response.status === 200) {
      localStorage.removeItem(`leader_token_${data.customId}`);
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const leaderGamesSlice = createSlice({
  name: 'leaderGames',
  initialState: {
    items: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createGame.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = [...state.items, payload];
    },
    [createGame.pending]: (state) => {
      state.isFetching = true;
    },
    [createGame.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [findAllByUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findAllByUser.pending]: (state) => {
      state.isFetching = true;
    },
    [findAllByUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload
        ? payload.message
        : 'Connection Error - Your Session Has Ended - Please Login Again.';
    },
    [findGameById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findGameById.pending]: (state) => {
      state.isFetching = true;
    },
    [findGameById.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [deleteGame.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = state.items.filter((item) => item._id !== payload._id);
    },
    [deleteGame.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteGame.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
  },
});

export const { clearState } = leaderGamesSlice.actions;

export const leaderGamesSelector = (state) => state.leaderGames;
