import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';

const StyledContainer = tw.section`
    text-white
    container
    mx-auto
    flex
    px-5
    py-5
    md:flex-row
    flex-col
    items-center
`;

const Container = ({ children }) => <StyledContainer>{children}</StyledContainer>;

Container.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Container;
