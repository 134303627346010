/* eslint-disable no-nested-ternary */
const prod = {
  url: {
    API_URL: 'https://digital.feedbackgame.com/api',
    SOCKET_URL: 'wss://digital.feedbackgame.com',
    SOCKET_PATH: '/ws/socket.io',
    PAYSON_CHECKOUT_URL: 'https://embedded.payson.se/checkout',
    PAYSON_PAYMENT_SCRIPT: 'https://embedded.payson.se/Content/payson.js?v2',
  },
};

const dev = {
  url: {
    API_URL: 'http://localhost:8888',
    SOCKET_URL: 'http://localhost:8888',
    SOCKET_PATH: null,
    PAYSON_CHECKOUT_URL: 'https://test-www.payson.se/embedded/checkout',
    PAYSON_PAYMENT_SCRIPT: 'https://test-www.payson.se/embedded/Content/payson.js?v2',
  },
};

export const config = process.env.NODE_ENV === 'production' ? prod : dev;
