import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import tw from 'twin.macro';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SocketContext from 'socket/socket';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import GameTemplate from 'templates/GameTemplate';
import {
  gameSelector,
  joinError,
  updateCustomGameId,
  updateGameData,
  updateGameStatus,
  updateMyPlayer,
  updateMyPlayerToken,
  updatePlayers,
} from 'features/game/GameSlice';
import toast from 'react-hot-toast';
import CornerButton from 'components/atoms/CornerButton/CornerButton';
import DropdownMenu from 'components/organisms/DropdownMenu/DropdownMenu';
import MenuItem from 'components/organisms/DropdownMenu/MenuItem';
import DeckBoard from 'components/organisms/DeckBoard/DeckBoard';
import PlayerSeat from 'components/molecules/PlayerSeat/PlayerSeat';

const StyledLeaderMenu = tw.div`
  absolute
  right-7
  bottom-7
  block
`;
const DeckBoardWrapper = tw.div`
  lg:block
  hidden
`;
const ActivePlayerSeat = tw.div`
  lg:hidden
  block
  row-span-2
`;
const HideActivePlayer = tw.div`
  lg:block
  hidden
`;

const GamePage = () => {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const game = useSelector(gameSelector);
  const { myToken, isJoined, isStarted, isPaused, isError, errorMessage, players, gameId, customGameId, isLeader } =
    game;

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }
  }, [socket.connected]);

  useEffect(() => {
    const customGameIdFromUrl = location.pathname.replace('/game/', '');
    sessionStorage.setItem('customGameId', customGameIdFromUrl);
    dispatch(updateCustomGameId({ customGameId: customGameIdFromUrl }));
    const myPlayerTokenFromSessionStorage = sessionStorage.getItem('playerToken');
    const leaderTokenFromLocalStorage = localStorage.getItem(`leader_token_${customGameIdFromUrl}`);
    if (leaderTokenFromLocalStorage) {
      sessionStorage.setItem('playerToken', leaderTokenFromLocalStorage);
      dispatch(updateMyPlayerToken({ token: leaderTokenFromLocalStorage }));
    } else if (myPlayerTokenFromSessionStorage) {
      dispatch(updateMyPlayerToken({ token: myPlayerTokenFromSessionStorage }));
    }
  }, []);

  useEffect(() => {
    socket.on('connect', () => {
      if (!isJoined && myToken && customGameId) {
        socket.emit('joinGameByToken', {
          customGameId,
          token: myToken,
        });
      } else if (isJoined && myToken && customGameId) {
        socket.emit('getMyPlayerData', {
          customGameId,
          token: myToken,
        });
      }
    });
  }, [myToken, customGameId]);

  useEffect(() => {
    socket.once('gameContinued', () => {
      if (myToken && customGameId) {
        socket.emit('joinGameByToken', {
          customGameId,
          token: myToken,
        });
      }
    });
  }, [isPaused]);

  useEffect(() => {
    socket.on('playersUpdated', (allPlayers) => {
      dispatch(updatePlayers(allPlayers));
    });
    socket.on('myPlayerUpdated', (myPlayer) => {
      if (myPlayer) {
        dispatch(updateMyPlayer(myPlayer));
        sessionStorage.setItem('playerToken', myPlayer.token);
      }
    });
    socket.on('gameDataUpdated', (gameData) => {
      dispatch(updateGameData(gameData));
    });
    socket.on('youRecivedNewCard', (yourPlayer) => {
      dispatch(updateMyPlayer(yourPlayer));
    });
    socket.on('otherPlayerGetBackCardFromYou', (yourPlayer) => {
      dispatch(updateMyPlayer(yourPlayer));
    });
    socket.on('gameStatusUpdated', (gameStatus) => {
      dispatch(updateGameStatus(gameStatus));
      socket.emit('getMyPlayerData', { customGameId, token: myToken });
    });
    socket.on('gameDeleted', () => {
      history.push('/');
    });
    socket.on('exception', (data) => {
      dispatch(joinError(data));
    });
    socket.on('error', (error) => {
      toast.error(error);
    });

    return () => {
      socket.off('connect');
      socket.off('playersUpdated');
      socket.off('myPlayerUpdated');
      socket.off('gameDataUpdated');
      socket.off('youRecivedNewCard');
      socket.off('otherPlayerGetBackCardFromYou');
      socket.off('gameStatusUpdated');
      socket.off('exception');
      socket.off('error');
    };
  }, []);

  const activePlayer = useMemo(() => {
    const me = (players || []).find((playerFromState) => playerFromState.token === myToken);
    return me || null;
  }, [players, myToken]);

  const getPlayer = useCallback(
    (playerNo) => {
      if (!players?.length || !activePlayer) return null;
      if (playerNo === activePlayer.position) {
        const seventhPositionPlayer = players.find((playerFromState) => playerFromState.position === 7);
        return seventhPositionPlayer || null;
      }
      const player = players.find((playerFromState) => playerFromState.position === playerNo);
      return player || null;
    },
    [players, activePlayer],
  );
  const player1 = useMemo(() => getPlayer(1), [getPlayer]);
  const player2 = useMemo(() => getPlayer(2), [getPlayer]);
  const player3 = useMemo(() => getPlayer(3), [getPlayer]);
  const player4 = useMemo(() => getPlayer(4), [getPlayer]);
  const player5 = useMemo(() => getPlayer(5), [getPlayer]);
  const player6 = useMemo(() => getPlayer(6), [getPlayer]);
  const player8 = useMemo(() => getPlayer(8), [getPlayer]);

  useEffect(() => {
    if (isError) {
      // sessionStorage.removeItem('playerToken');
      toast.error(errorMessage);
      // dispatch(clearState());
      // history.push(`/join-game/${customGameId}`);
    }
  }, [isError]);

  const handleStartPauseContinueGame = () => {
    if (!isStarted) {
      socket.emit('startGame', { gameId });
    }
    if (isStarted && !isPaused) {
      socket.emit('pauseGame', { gameId });
    }
    if (isStarted && isPaused) {
      socket.emit('continueGame', { gameId });
    }
  };

  return (
    <>
      <GameTemplate>
        <PlayerSeat player={player1} no="1" />
        <PlayerSeat player={player2} no="2" />
        <PlayerSeat player={player3} no="3" />
        <PlayerSeat player={player4} no="4" />
        <DeckBoardWrapper>
          <DeckBoard />
        </DeckBoardWrapper>
        <ActivePlayerSeat>
          <PlayerSeat player={activePlayer} no="7" />
        </ActivePlayerSeat>
        <PlayerSeat player={player5} no="5" />
        <PlayerSeat player={player6} no="6" />
        <HideActivePlayer>
          <PlayerSeat player={activePlayer} no="7" />
        </HideActivePlayer>
        <PlayerSeat player={player8} no="8" />
        {isLeader && isPaused && (
          <StyledLeaderMenu>
            <DropdownMenu menuButton={<CornerButton />} openDirection="top-left">
              <MenuItem
                text={
                  isStarted
                    ? (isPaused && t('leader_menu_continue_game')) || (!isPaused && t('leader_menu_pause_game'))
                    : t('leader_menu_start_game')
                }
                itemFunction={handleStartPauseContinueGame}
              />
            </DropdownMenu>
          </StyledLeaderMenu>
        )}
      </GameTemplate>
    </>
  );
};

export default GamePage;
