/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CategoriesAPI from './CategoriesAPI';

export const createCategory = createAsyncThunk('categories/create', async ({ name, status }, thunkAPI) => {
  try {
    const response = await CategoriesAPI.create({
      name,
      status,
    });
    const { data } = response;
    if (response.status === 201) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const updateCategory = createAsyncThunk('categories/update', async ({ _id, name, status }, thunkAPI) => {
  try {
    const response = await CategoriesAPI.update({
      _id,
      name,
      status,
    });
    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const findCategoryById = createAsyncThunk('categories/findById', async ({ id }, thunkAPI) => {
  try {
    const response = await CategoriesAPI.findOneById({ id });
    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const findCategories = createAsyncThunk('categories/find', async (thunkAPI) => {
  try {
    const response = await CategoriesAPI.find();

    const { data } = response;

    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const deleteCategory = createAsyncThunk('categories/delete', async ({ id }, thunkAPI) => {
  try {
    const response = await CategoriesAPI.remove({ categoryId: id });

    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    items: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createCategory.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = [...state.items, payload];
    },
    [createCategory.pending]: (state) => {
      state.isFetching = true;
    },
    [createCategory.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [updateCategory.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = state.items.map((item) => (item._id === payload._id ? payload : item));
    },
    [updateCategory.pending]: (state) => {
      state.isFetching = true;
    },
    [updateCategory.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [findCategories.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findCategories.pending]: (state) => {
      state.isFetching = true;
    },
    [findCategories.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [findCategoryById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findCategoryById.pending]: (state) => {
      state.isFetching = true;
    },
    [findCategoryById.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [deleteCategory.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = state.items.filter((item) => item._id !== payload._id);
    },
    [deleteCategory.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteCategory.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
  },
});

export const { clearState } = categoriesSlice.actions;

export const categoriesSelector = (state) => state.categories;
