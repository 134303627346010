/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { gameDataSelector, myPlayerSelector } from 'features/game/GameSlice';
import Popover from 'components/molecules/Popover/Popover';
import styled from 'styled-components';
import ShownCard from '../ShownCard/ShownCard';
import GiftCardMenu from '../GiftCardMenu/GiftCardMenu';

const StyledWrapper = styled.div(({ inModal }) => [
  tw`
  relative
  inline-block
  w-full
  rounded
  bg-white
  h-9
  lg:h-full`,
  inModal && tw`md:h-full md:max-w-[10rem]`,
]);

const GiftCard = ({ card, actualOwnerId, from, inModal }) => {
  const { _id: cardId, cardType: cardTypeId, translations } = card || {};

  const { myToken } = useSelector(myPlayerSelector);
  const { cardTypes, firstLanguage, secondLanguage } = useSelector(gameDataSelector);

  const { text: firstText } =
    translations.find((translation) => translation.language === (firstLanguage && firstLanguage._id)) || {};

  const { text: secondText } =
    translations.find((translation) => translation.language === (secondLanguage && secondLanguage._id)) || {};

  const cardType = cardTypes.find((cardTypeFromAllCardTypes) => cardTypeFromAllCardTypes._id === cardTypeId);

  const { frontCardColor, textColor } = cardType || {};

  return (
    <Popover
      content={
        <GiftCardMenu
          cardId={cardId}
          actualOwnerId={actualOwnerId}
          from={from}
          title={firstText}
          titleBackground={frontCardColor}
          titleColor={textColor}
          canCardBack={myToken === from}
        />
      }
      target={
        <StyledWrapper inModal={inModal}>
          <ShownCard colorMain={frontCardColor} text={firstText} textSecond={secondText} textColor={textColor} />
        </StyledWrapper>
      }
    />
  );
};

GiftCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  card: PropTypes.object.isRequired,
  from: PropTypes.string.isRequired,
  actualOwnerId: PropTypes.string,
  inModal: PropTypes.bool,
};

GiftCard.defaultProps = {
  actualOwnerId: null,
  inModal: false,
};

export default GiftCard;
