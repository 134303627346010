/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Modal, {
  StyledModalContentWrapper,
  StyledButtonsWrapper,
  StyledHeading,
} from 'components/molecules/Modal/Modal';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import { StyledFieldWrapper } from 'templates/AuthTemplate';
import SocketContext from 'socket/socket';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { playerBoardSelector } from 'features/game/PlayerBoardSlice';
import { myPlayerSelector, playersSelector } from 'features/game/GameSlice';
import Label from 'components/atoms/Label';
import Select from 'components/atoms/Select/Select';

const SendCardModal = ({ open, close }) => {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);

  const playerBoard = useSelector(playerBoardSelector);
  const { cardToSendId } = playerBoard;

  const players = useSelector(playersSelector);
  const myPlayer = useSelector(myPlayerSelector);

  const playersWithoutMe = players.filter((player) => player.token !== myPlayer.myToken);

  const [receiver, setReceiver] = useState(null);

  const handleSendCardButtonClick = () => {
    if (cardToSendId && receiver) {
      socket.emit('sendCard', { cardId: cardToSendId, receiverId: receiver._id });
    }
    close();
  };

  const getReceiverIfExist = () => {
    if (receiver) {
      const exist = playersWithoutMe.find(({ username }) => username === receiver.username);
      if (exist) return receiver;
    }
    return null;
  };

  return (
    <Modal open={open} close={close}>
      <StyledModalContentWrapper>
        <StyledHeading as="h3">{t('send_card_modal_heading')}</StyledHeading>
        <StyledFieldWrapper>
          <Label htmlFor="gameType">{t('send_card_modal_select_player')}</Label>
          <Select
            items={playersWithoutMe}
            name="receiver"
            selectedItem={getReceiverIfExist()}
            setSelectedItem={setReceiver}
            nullable={false}
            nullText={t('send_card_modal_select_player_null_text')}
          />
        </StyledFieldWrapper>
      </StyledModalContentWrapper>
      <StyledButtonsWrapper>
        <ModalButton type="button" onClick={() => close()}>
          {t('send_card_modal_button_cancel')}
        </ModalButton>
        <ModalButton type="button" color="blue" onClick={handleSendCardButtonClick}>
          {t('send_card_modal_button_send')}
        </ModalButton>
      </StyledButtonsWrapper>
    </Modal>
  );
};

SendCardModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default SendCardModal;
