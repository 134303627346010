import jwtDecode from 'jwt-decode';

export const isLogin = () => {
  const jwt = localStorage.getItem('token');
  if (jwt) {
    try {
      const decodedJwt = jwtDecode(jwt);
      const expiration = new Date(decodedJwt.exp);
      const now = new Date();
      if (expiration.getTime() < now.getTime()) {
        return true;
      }
    } catch {
      return false;
    }
  }
  return false;
};

export const isLoginAsLeader = () => {
  const jwt = localStorage.getItem('token');
  if (jwt) {
    try {
      const decodedJwt = jwtDecode(jwt);
      const expiration = new Date(decodedJwt.exp);
      const now = new Date();
      if (expiration.getTime() < now.getTime() && decodedJwt.roles.includes('user')) {
        return true;
      }
    } catch {
      return false;
    }
  }
  return false;
};

export const isLoginAsAdmin = () => {
  const jwt = localStorage.getItem('token');
  if (jwt) {
    try {
      const decodedJwt = jwtDecode(jwt);
      const expiration = new Date(decodedJwt.exp);
      const now = new Date();
      if (expiration.getTime() < now.getTime() && decodedJwt.roles.includes('admin')) {
        return true;
      }
    } catch {
      return false;
    }
  }
  return false;
};
