/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import tw from 'twin.macro';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getUserByToken, setOrganizationId, userSelector } from 'features/auth/AuthSlice';
import Input from 'components/atoms/Input';
import Label from 'components/atoms/Label';
import { useTranslation } from 'react-i18next';
import {
  StyledButtonsWrapper,
  StyledErrorMessage,
  StyledFieldWrapper,
  StyledForm,
  StyledHeading,
} from 'templates/AuthTemplate';
import LeaderBoardMenu from 'components/molecules/LeaderBoardMenu/LeaderBoardMenu';
import LeaderTemplate from 'templates/LeaderTemplate';
import Button from 'components/atoms/Button';
import Loader from 'components/atoms/Loader';

const StyledButton = tw(Button)`
  w-40
  mr-auto
`;

const schema = yup.object().shape({
  organization: yup.string(),
});

const LeaderSettingsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const { organization, roles } = user;

  useEffect(() => {
    dispatch(getUserByToken());
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      organization,
    },
  });

  const onSubmit = (data) => {
    dispatch(setOrganizationId(data));
  };

  return (
    <LeaderTemplate>
      <>
        <LeaderBoardMenu />
        <StyledHeading as="h2">Leader Settings:</StyledHeading>
        {roles.includes('organization') ? (
          ''
        ) : (
          <StyledForm onSubmit={handleSubmit(onSubmit)} method="POST">
            <StyledFieldWrapper>
              <Label htmlFor="organization">Organization ID:</Label>
              <Input
                type="text"
                {...register('organization')}
                placeholder="Submit ID if your account is connected with organization."
              />
              <StyledErrorMessage>{errors.organization?.message}</StyledErrorMessage>
            </StyledFieldWrapper>
            <StyledButtonsWrapper>
              <StyledButton>{false && <Loader color="white" size={4} />} Save</StyledButton>
            </StyledButtonsWrapper>
          </StyledForm>
        )}
      </>
    </LeaderTemplate>
  );
};

export default LeaderSettingsPage;
