import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

const StyledWrapper = tw.div`
  w-full
  flex
  items-center
`;

const StyledStatusButton = styled.p(({ active }) => [
  tw`
    text-white
    text-xs
    inline-block
    w-14
    py-0.5
    px-1
    mx-auto
    rounded-xl
    text-center
    `,
  active ? tw`bg-green-500` : tw`bg-red-500`,
]);

const StyledSpan = tw.span`
    mx-auto
`;

const StatusButton = ({ active }) => (
  <StyledWrapper>
    <StyledStatusButton active={active}>
      <StyledSpan>{active ? 'Active' : 'Inactive'}</StyledSpan>
    </StyledStatusButton>
  </StyledWrapper>
);

StatusButton.propTypes = {
  active: PropTypes.bool,
};

StatusButton.defaultProps = {
  active: false,
};

export default StatusButton;
