import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import Header from 'components/organisms/Header/Header';
import Footer from 'components/atoms/Footer';

const StyledMain = tw.main`my-auto`;

const WelcomeTemplate = ({ children }) => (
  <div className="bg-wood bg-cover bg-center h-screen w-screen">
    <Header />
    <StyledMain>{children}</StyledMain>
    <Footer />
  </div>
);

WelcomeTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default WelcomeTemplate;
