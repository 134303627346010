import tw from 'twin.macro';

const Input = tw.input`
    w-full
    bg-gray-100
    bg-opacity-50
    rounded
    border
    border-gray-300
    focus:ring-2
    focus:ring-indigo-200
    focus:border-indigo-500
    text-base
    outline-none
    text-gray-900
    py-1
    px-3
    mb-1
    leading-8
    transition-colors
    duration-200
    ease-in-out
`;

export default Input;
