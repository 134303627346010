import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import Modal, { StyledModalContentWrapper } from 'components/molecules/Modal/Modal';
import 'translations/i18n';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import Paragraph from 'components/atoms/Paragraph';
import { useTranslation } from 'react-i18next';

const InputCheckbox = tw.input`
relative
ml-2
`;
const Link = tw.a`
text-blue-400
`;
const InputLabel = tw.label`
relative
left-0
ml-2
text-xs
`;
const ButtonWrapper = tw.div`
flex
flex-row
items-center
relative
p-4
bg-white
`;

const DeleteGameModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const [selectedInput, setSelectedINput] = useState(false);

  const saveAcceptedInfo = () => {
    const d = new Date();
    d.setTime(d.getTime() + 14 * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `acceptedInfo=true;${expires};path=/`;
    setOpen(false);
  };
  return (
    <Modal open={open} close={setOpen}>
      <StyledModalContentWrapper>
        <Paragraph>
          {t('leader_game_list_page_td_info_block')}
          Click{' '}
          <Link href="https://www.isc.upenn.edu/how-to/configuring-your-web-browser-allow-pop-windows" target="_blank">
            here
          </Link>{' '}
          to read more.
        </Paragraph>
      </StyledModalContentWrapper>
      <ButtonWrapper>
        <InputCheckbox type="checkbox" onClick={() => setSelectedINput(!selectedInput)} />
        <InputLabel>{t('leader_game_list_page_td_info_block_dont_show_again')}</InputLabel>
        <div className="ml-auto mr-4">
          <ModalButton
            type="button"
            className="!ml-auto"
            onClick={() => (selectedInput ? saveAcceptedInfo() : setOpen(false))}
          >
            ok
          </ModalButton>
        </div>
      </ButtonWrapper>
    </Modal>
  );
};
DeleteGameModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default DeleteGameModal;
