/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import Modal, {
  StyledHeading,
  StyledModalContentWrapper,
  StyledButtonsWrapper,
} from 'components/molecules/Modal/Modal';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import PaysonPayment from 'utils/payson/PaysonPayment';
import Loader from 'components/atoms/Loader';

const StyledSpanBold = tw.span`
  font-semibold
`;

const StyledListItem = tw.li`
  text-gray-600
  text-sm
  list-disc
  list-inside
`;

const OrderDetailsModal = ({ open, setOpen, idFromPaymentGateway }) => (
  <Modal open={open} close={setOpen} scroll>
    <StyledModalContentWrapper>
      <>
        <StyledHeading as="h3">Pay:</StyledHeading>
        {!idFromPaymentGateway && <Loader />}
        {idFromPaymentGateway && <PaysonPayment idFromPaymentGateway={idFromPaymentGateway} />}
        <p>{idFromPaymentGateway}</p>
      </>
    </StyledModalContentWrapper>
    <StyledButtonsWrapper>
      <ModalButton type="button" onClick={() => setOpen(false)}>
        Cancel
      </ModalButton>
    </StyledButtonsWrapper>
  </Modal>
);

OrderDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  idFromPaymentGateway: PropTypes.string.isRequired,
};

export default OrderDetailsModal;
