import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteGame } from 'features/leader/gamesList/LeaderGamesSlice';
import Modal, {
  StyledModalContentWrapper,
  StyledButtonsWrapper,
  StyledHeading,
} from 'components/molecules/Modal/Modal';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import Paragraph from 'components/atoms/Paragraph';
import { getUserByToken } from 'features/auth/AuthSlice';

const DeleteGameModal = ({ open, setOpen, customId, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDeleteButtonClick = () => {
    dispatch(deleteGame({ id })).then(() => {
      dispatch(getUserByToken());
    });
    setOpen(false);
  };

  return (
    <Modal open={open} close={setOpen}>
      <StyledModalContentWrapper>
        <StyledHeading as="h3">
          {t('delete_game_modal_heading')}: &quot;{customId}&quot;
        </StyledHeading>
        <Paragraph>
          {t('delete_game_modal_text')}: &quot;{customId}&quot;
        </Paragraph>
      </StyledModalContentWrapper>
      <StyledButtonsWrapper>
        <ModalButton type="button" onClick={() => setOpen(false)}>
          {t('delete_game_modal_button_cancel')}
        </ModalButton>
        <ModalButton type="button" color="red" onClick={() => handleDeleteButtonClick(id)}>
          {t('delete_game_modal_button_delete')}
        </ModalButton>
      </StyledButtonsWrapper>
    </Modal>
  );
};
DeleteGameModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  customId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default DeleteGameModal;
