import React, { useState } from 'react';
import tw from 'twin.macro';
import { CheckIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import ColorExampleSquare from '../ColorExampleSquare/ColorExampleSquare';

const StyledWrapper = tw.li`
  cursor-default
  select-none
  relative
  py-2
  pl-3
  pr-9
`;

const IconSpan = tw.span`
  absolute
  inset-y-0
  right-0 flex
  items-center
  pr-4
`;

const SelectColorItem = ({ color, text, selectedColor, setSelectedColor, setOpen }) => {
  const [highlighted, setHighlighted] = useState(false);

  const handleOnMouseEnter = () => {
    setHighlighted(true);
  };

  const handleOnMouseLeave = () => {
    setHighlighted(false);
  };

  const handleClick = () => {
    setSelectedColor(color);
    setOpen(false);
  };

  return (
    <StyledWrapper
      id="listbox-option-0"
      className={highlighted ? `text-white bg-indigo-600` : `text-gray-900`}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={handleClick}
    >
      <div className="flex items-center">
        {color && <ColorExampleSquare color={color} />}
        <span className="font-normal ml-3 block truncate">{text || color}</span>
      </div>

      <IconSpan className={highlighted ? `text-white` : `text-indigo-600`}>
        {color === selectedColor && <CheckIcon className="h-5 w-5" />}
      </IconSpan>
    </StyledWrapper>
  );
};

SelectColorItem.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  selectedColor: PropTypes.string,
  setSelectedColor: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
};

SelectColorItem.defaultProps = {
  text: null,
  color: null,
  selectedColor: null,
};

export default SelectColorItem;
