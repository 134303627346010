import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import Paragraph from 'components/atoms/Paragraph';

export const StyledCardWrapper = tw.div`
  relative
  inline-block
  h-full
  w-full
  rounded
  bg-white
`;

export const StyledCard = tw.div`
    absolute
    flex
    flex-col
    items-center
    justify-center
    text-center
    px-1
    py-2
    my-auto
    w-full
    h-full
    overflow-hidden
    rounded
    lg:p-0
`;

const ShownCard = ({ text, textSecond, colorMain, textColor }) => (
  <StyledCardWrapper>
    <StyledCard className={`bg-${colorMain} text-${textColor}`}>
      <div className="text-xs xs:text-m s:text-sm m:text-base lg:text-xs 2xl:text-sm break-all">{text}</div>
      {textSecond && (
        <Paragraph className="text-xs xs:text-m s:text-sm m:text-base lg:text-xs 2xl:text-sm break-all">
          {textSecond}
        </Paragraph>
      )}
    </StyledCard>
  </StyledCardWrapper>
);

ShownCard.propTypes = {
  text: PropTypes.string.isRequired,
  colorMain: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  textSecond: PropTypes.string,
};

ShownCard.defaultProps = {
  textSecond: null,
  textColor: 'white',
};

export default ShownCard;
