/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { routes } from 'routes';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { userSelector, clearState, resetPassword } from 'features/auth/AuthSlice';
import AuthTemplate, {
  StyledForm,
  StyledHeading,
  StyledFieldWrapper,
  StyledErrorMessage,
  StyledButtonsWrapper,
  StyledLinkWrapper,
  StyledLink,
} from 'templates/AuthTemplate';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import Loader from 'components/atoms/Loader';

const schema = yup.object().shape({
  password: yup.string().min(6).required(),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const user = useSelector(userSelector);
  const { isSuccess, isFetching, isError, errorMessage } = user;

  const onSubmit = (data) => {
    const { password } = data;
    const parsed = queryString.parse(location.search);
    const { token } = parsed;
    const resetPasswordData = {
      token,
      password,
    };
    dispatch(resetPassword(resetPasswordData));
  };

  useEffect(() => () => dispatch(clearState()), []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
      history.push('/');
    }
  }, [isError, isSuccess]);

  return (
    <AuthTemplate>
      <StyledHeading as="h2">{t('reset_password_page_heading')}</StyledHeading>
      <StyledForm onSubmit={handleSubmit(onSubmit)} method="POST">
        <StyledFieldWrapper>
          <Label htmlFor="password">{t('reset_password_page_input_password')}</Label>
          <Input
            type="password"
            {...register('password')}
            placeholder={t('reset_password_page_input_password_placeholder')}
            autoComplete="off"
          />
          <StyledErrorMessage>{errors.password?.message}</StyledErrorMessage>
        </StyledFieldWrapper>

        <StyledFieldWrapper>
          <Label htmlFor="passwordConfirmation">{t('reset_password_page_input_repeat_password')}</Label>
          <Input
            type="password"
            {...register('passwordConfirmation')}
            placeholder={t('reset_password_page_input_repeat_password_placeholder')}
            autoComplete="off"
          />
          <StyledErrorMessage>{errors.passwordConfirmation?.message}</StyledErrorMessage>
        </StyledFieldWrapper>
        <StyledButtonsWrapper>
          <Button onClick={() => history.push(routes.home)}>{t('reset_password_page_button_cancel')}</Button>
          <Button>
            {isFetching && <Loader />} {t('reset_password_page_button_reset_password')}
          </Button>
        </StyledButtonsWrapper>
      </StyledForm>
      <StyledLinkWrapper>
        <StyledLink to={routes.register}>{t('reset_password_page_have_not_account')}</StyledLink>
        <StyledLink to={routes.login}>{t('reset_password_page_login_to_your_account')}</StyledLink>
      </StyledLinkWrapper>
    </AuthTemplate>
  );
};

export default ResetPasswordPage;
