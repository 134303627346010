import React from 'react';
import PropTypes from 'prop-types';

const SelectedItem = ({ text }) => (
  <span className="flex items-center">
    <span className="ml-3 block truncate capitalize">{text}</span>
  </span>
);

SelectedItem.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SelectedItem;
