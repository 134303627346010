/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef } from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import SocketContext from 'socket/socket';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { StyledMenuTitle } from '../CardMenu/CardMenu';

const StyledMenu = tw.div`
    origin-top-left
    left-1/2
    top-1/2
    mt-2
    min-w-[10rem]
    rounded-md
    shadow-lg
    bg-white
    ring-1
    ring-black
    ring-opacity-5
    divide-y
    divide-gray-100
    z-50
`;

const StyledGruppedItems = tw.div`
    py-1
`;

const StyledMenuItem = tw.p`
    block
    px-4
    py-2
    text-sm
    text-gray-700
    hover:bg-gray-100
    hover:text-gray-900
`;

const MyGiftCardMenu = ({ cardId, title, titleBackground, titleColor }) => {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);

  const handleRegreatCardClick = () => {
    socket.emit('regreatCard', cardId);
    /* TODO  */
  };

  return (
    <StyledMenu role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
      <StyledMenuTitle className={`bg-${titleBackground}`} style={{ color: titleColor }}>
        {title}
      </StyledMenuTitle>
      <StyledGruppedItems>
        <StyledMenuItem onClick={handleRegreatCardClick}>{t('gift_card_menu_item_regreat_card')}</StyledMenuItem>
      </StyledGruppedItems>
    </StyledMenu>
  );
};

MyGiftCardMenu.propTypes = {
  cardId: PropTypes.string.isRequired,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleBackground: PropTypes.string,
};
MyGiftCardMenu.defaultProps = {
  title: null,
  titleColor: null,
  titleBackground: null,
};
export default MyGiftCardMenu;
