/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PackagesAPI from './PackagesAPI';

export const createGamesPackage = createAsyncThunk(
  'gamesPackages/create',
  async (
    { name, shortName, description, unitPrice, taxRate, gamesCount, subscriptionValidityPeriod, gameTypes },
    thunkAPI,
  ) => {
    try {
      const response = await PackagesAPI.create({
        name,
        shortName,
        description,
        unitPrice,
        taxRate,
        gamesCount,
        subscriptionValidityPeriod,
        gameTypes,
      });
      const { data } = response;
      if (response.status === 201) {
        return data;
      }
      return thunkAPI.rejectWithValue(data);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const updateGamesPackage = createAsyncThunk(
  'gamesPackages/update',
  async (
    {
      _id,
      name,
      shortName,
      status,
      description,
      unitPrice,
      taxRate,
      gamesCount,
      subscriptionValidityPeriod,
      gameTypes,
    },
    thunkAPI,
  ) => {
    try {
      const response = await PackagesAPI.update({
        _id,
        name,
        shortName,
        status,
        description,
        unitPrice,
        taxRate,
        gamesCount,
        subscriptionValidityPeriod,
        gameTypes,
      });
      const { data } = response;
      if (response.status === 200) {
        return data;
      }
      return thunkAPI.rejectWithValue(data);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const findGamesPackageById = createAsyncThunk('gamesPackages/findById', async ({ id }, thunkAPI) => {
  try {
    const response = await PackagesAPI.findOneById({ id });
    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const findGamesPackages = createAsyncThunk('gamesPackages/find', async (thunkAPI) => {
  try {
    const response = await PackagesAPI.find();

    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const deleteGamesPackage = createAsyncThunk('gamesPackages/delete', async (id, thunkAPI) => {
  try {
    const response = await PackagesAPI.remove({ gamesPackageId: id });

    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const gamesPackagesSlice = createSlice({
  name: 'gamesPackages',
  initialState: {
    items: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createGamesPackage.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = [...state.items, payload];
    },
    [createGamesPackage.pending]: (state) => {
      state.isFetching = true;
    },
    [createGamesPackage.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [updateGamesPackage.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = state.items.map((item) => (item._id === payload._id ? payload : item));
    },
    [updateGamesPackage.pending]: (state) => {
      state.isFetching = true;
    },
    [updateGamesPackage.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [findGamesPackages.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findGamesPackages.pending]: (state) => {
      state.isFetching = true;
    },
    [findGamesPackages.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [findGamesPackageById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findGamesPackageById.pending]: (state) => {
      state.isFetching = true;
    },
    [findGamesPackageById.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [deleteGamesPackage.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = state.items.filter((item) => item._id !== payload._id);
    },
    [deleteGamesPackage.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteGamesPackage.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
  },
});

export const { clearState } = gamesPackagesSlice.actions;

export const gamesPackagesSelector = (state) => state.gamesPackages;
