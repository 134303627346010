/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import Heading from 'components/atoms/Heading';
import Paragraph from 'components/atoms/Paragraph';
import { clearState, getUserByToken, userSelector } from 'features/auth/AuthSlice';
import Loader from 'components/atoms/Loader';

const StyledLeaderAvatarWrapper = tw.div`
    w-20
    h-20
    rounded-full
    inline-flex
    items-center
    justify-center
    bg-gray-200
    text-gray-400
`;

const StyledLeaderProfileWrapper = tw.div`
    flex
    flex-col
    items-center
    text-center
    justify-center
    p-3
    pt-0
`;

const StyledHeading = tw(Heading)`
    text-xl
    mt-5
`;

const StyledDivider = tw.div`
    w-12
    h-1
    bg-indigo-500
    rounded
    mt-1
    mb-3
`;

const Link = tw.a`
hover:text-blue-500
text-decoration-line: underline
`;

const LeaderProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [userPackages, setUserPackages] = useState([]);
  const [sharedPackages, setSharedPackages] = useState([]);

  const user = useSelector(userSelector);
  const { isError, isSuccess, isFetching, errorMessage, username, email, subscriptions, subscriptionsSharedWithMe } =
    user || {};

  useEffect(() => {
    dispatch(getUserByToken());
  }, []);

  useEffect(() => {
    if (subscriptions) {
      setUserPackages(Object.values(subscriptions));
    }
    if (subscriptionsSharedWithMe) {
      setSharedPackages(Object.values(subscriptionsSharedWithMe));
    }
  }, [user]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      localStorage.removeItem('token');
      dispatch(clearState());
      history.push('/login');
    }

    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  return (
    <div className="h-full">
      <StyledLeaderProfileWrapper>
        <StyledHeading as="h2">
          {t('leader_profile_heading')} {username}
        </StyledHeading>
        <Paragraph className="mb-2 text-sm">Your User email: {email}</Paragraph>
        <StyledDivider />
        <Paragraph className="mb-2 text-sm">{t('leader_profile_text')}</Paragraph>
        <Paragraph className="text-sm mb-2">
          {t('leader_profile_text_2')}{' '}
          <Link className="text-sm" href="/#">
            {t('leader_profile_text_email')}
          </Link>
        </Paragraph>
        <Paragraph className="text-sm">{t('leader_profile_text_3')}</Paragraph>
        <Paragraph className="text-sm">{t('leader_profile_text_4')}</Paragraph>
        <Paragraph className="text-sm">{t('leader_profile_text_5')}</Paragraph>

        <StyledHeading as="h2">{t('leader_profile_your_packages')}:</StyledHeading>
        <StyledDivider />
        <div className="'h-64' overflow-y-auto px-4 text-sm">
          {isFetching && <Loader />}
          {!isFetching &&
            userPackages.map((userPackage) => {
              const { packageName, gamesCount, validFor } = userPackage;
              if (gamesCount > 0 && new Date(validFor).getTime() > Date.now()) {
                return (
                  <div className="border-t border-b border-gray-400 mb-6 w-full py-1">
                    <Paragraph>
                      {t('leader_profile_package_name')}: {packageName}
                    </Paragraph>
                    <Paragraph>
                      {t('leader_profile_games_count')}: {gamesCount}
                    </Paragraph>
                    <Paragraph>
                      {t('leader_profile_valid_for')}: {new Date(validFor).toLocaleString()}
                    </Paragraph>
                  </div>
                );
              }
              return null;
            })}
        </div>
        <>
          <StyledHeading as="h2">Packages Shared With Me:</StyledHeading>
          <StyledDivider />
          <div className="h-32 overflow-y-auto px-4 text-sm">
            {isFetching && <Loader />}
            {!isFetching &&
              sharedPackages.map((sharedPackage) => {
                const { packageName, gamesCount, validFor } = sharedPackage;
                if (gamesCount > 0 && new Date(validFor).getTime() > Date.now()) {
                  return (
                    <div className="border-t border-b border-gray-400 mb-6 w-full py-1">
                      <Paragraph>
                        {t('leader_profile_package_name')}: {packageName}
                      </Paragraph>
                      <Paragraph>
                        {t('leader_profile_games_count')}: {gamesCount}
                      </Paragraph>
                      <Paragraph>
                        {t('leader_profile_valid_for')}: {new Date(validFor).toLocaleString()}
                      </Paragraph>
                    </div>
                  );
                }
                return null;
              })}
          </div>
        </>
      </StyledLeaderProfileWrapper>
    </div>
  );
};

export default LeaderProfile;
