import React from 'react';
import PropTypes from 'prop-types';
import makeAsyncScriptLoader from 'react-async-script';
import { config } from 'constants.js';

const PaysonPayment = ({ idFromPaymentGateway }) => (
  <div id="paysonContainer" url={`${config.url.PAYSON_CHECKOUT_URL}?id=${idFromPaymentGateway}`} />
);

PaysonPayment.propTypes = {
  idFromPaymentGateway: PropTypes.string.isRequired,
};

export default makeAsyncScriptLoader(config.url.PAYSON_PAYMENT_SCRIPT, {
  removeOnUnmount: true,
})(PaysonPayment);
