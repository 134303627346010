import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { routes } from 'routes';
import { useDispatch } from 'react-redux';
import { logout } from '../AuthSlice';

const LogoutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
    dispatch(logout());
  }, []);

  return <Redirect to={routes.home} />;
};

export default LogoutPage;
