import axios from 'axios';
import { config } from 'constants.js';
import authHeader from 'features/auth/authHeader';

const shareSubscription = ({ userId, packageId, sharedWith }) =>
  axios.post(
    `${config.url.API_URL}/users/shareSubscription`,
    {
      userId,
      packageId,
      sharedWith,
    },
    { headers: authHeader() },
  );

export default {
  shareSubscription,
};
