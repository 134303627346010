import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@headlessui/react';
import tw, { styled } from 'twin.macro';

const StyledWrapper = tw.div`
    px-1
    py-1
`;

const StyledActivInactivButton = styled.button(({ active }) => [
  active ? tw`bg-purple-500 text-white` : tw`text-gray-900`,
]);

const StyledButton = tw(StyledActivInactivButton)`
    flex
    rounded-md
    items-center
    w-full
    px-2
    py-2
    text-sm
`;

const MenuItem = ({ text, itemFunction }) => (
  <StyledWrapper>
    <Menu.Item>
      {({ active }) => (
        <StyledButton className="group" active={active} onClick={itemFunction}>
          {text}
        </StyledButton>
      )}
    </Menu.Item>
  </StyledWrapper>
);

MenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  itemFunction: PropTypes.func.isRequired,
};

export default MenuItem;
