import React from 'react';
import { Menu } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import tw from 'twin.macro';

const StyledMenuButton = tw(Menu.Button)`
    inline-flex
    justify-center
    text-gray-700
    text-sm
    px-4
    border-0
    border-gray-700
    border-l
    h-8
    focus:outline-none
`;

const TreeDotsMenuButton = () => (
  <div>
    <StyledMenuButton>
      <DotsHorizontalIcon className="h-4 w-4 my-auto" aria-hidden="true" />
    </StyledMenuButton>
  </div>
);

export default TreeDotsMenuButton;
