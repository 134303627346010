import tw from 'twin.macro';

const Heading = tw.h1`
    sm:text-4xl
    text-3xl
    mb-2
    xl:mb-4
    font-medium
    text-gray-700
`;

export default Heading;
