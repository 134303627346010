import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { CheckIcon, XIcon } from '@heroicons/react/solid';

const StyledWrapper = tw.div`
  w-full
  flex
  items-center
`;

const StyledStatusButton = styled.p(({ active }) => [
  tw`
    text-white
    text-xs
    inline-block
    py-1
    px-1
    mx-auto
    rounded-full
    text-center
    `,
  active ? tw`bg-green-500` : tw`bg-red-500`,
]);

const StyledSpan = tw.span`
    mx-auto
`;

const RoundStatusButton = ({ active }) => (
  <StyledWrapper>
    <StyledStatusButton active={active}>
      <StyledSpan>{active ? <CheckIcon className="w-3 h-3" /> : <XIcon className="w-3 h-3" />}</StyledSpan>
    </StyledStatusButton>
  </StyledWrapper>
);

RoundStatusButton.propTypes = {
  active: PropTypes.bool,
};

RoundStatusButton.defaultProps = {
  active: false,
};

export default RoundStatusButton;
