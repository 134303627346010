import axios from 'axios';
import { config } from 'constants.js';
import authHeader from 'features/auth/authHeader';

const create = ({ gameType, firstLanguage, secondLanguage, fromSharedPackage }) =>
  axios.post(
    `${config.url.API_URL}/games`,
    {
      gameType,
      firstLanguage,
      secondLanguage,
      fromSharedPackage,
    },
    { headers: authHeader() },
  );

const findOneById = ({ gameId }) => axios.get(`${config.url.API_URL}/games/${gameId}`);

const findAllByUser = () => axios.get(`${config.url.API_URL}/games`, { headers: authHeader() });

const remove = ({ gameId }) => axios.delete(`${config.url.API_URL}/games/${gameId}`, { headers: authHeader() });

export default {
  create,
  findOneById,
  findAllByUser,
  remove,
};
