/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import AuthTemplate, {
  StyledForm,
  StyledHeading,
  StyledFieldWrapper,
  StyledErrorMessage,
  StyledButtonsWrapper,
  BackButton,
  LoginButton,
} from 'templates/AuthTemplate';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import Loader from 'components/atoms/Loader';
import SocketContext from 'socket/socket';
import {
  clearState,
  gameSelector,
  updateMyPlayer,
  joinError,
  updateGameStatus,
  updatePlayers,
  updateGameData,
} from 'features/game/GameSlice';
import Paragraph from 'components/atoms/Paragraph';

const schema = yup.object().shape({
  username: yup.string().required(),
  email: yup.string().email().required(),
});

const JoinGamePage = () => {
  const socket = useContext(SocketContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const game = useSelector(gameSelector);
  const { myToken, isSuccess, isError, errorMessage } = game;

  const [customGameId, setCustomGameId] = useState(null);

  const onSubmit = (data) => {
    socket.emit('joinGame', { customGameId, ...data });
  };

  useEffect(() => {
    const customGameIdFromUrl = location.pathname.replace('/join-game/', '');
    setCustomGameId(customGameIdFromUrl);
  }, [customGameId]);

  useEffect(() => {
    socket.on('playersUpdated', (allPlayers) => {
      dispatch(updatePlayers(allPlayers));
    });

    socket.once('myPlayerUpdated', (myPlayer) => {
      dispatch(updateMyPlayer(myPlayer));
    });
    socket.once('gameDataUpdated', (gameData) => {
      dispatch(updateGameData(gameData));
    });
    socket.once('gameStatusUpdated', (gameStatus) => {
      dispatch(updateGameStatus(gameStatus));
    });
    socket.on('exception', (data) => {
      dispatch(joinError(data));
    });
    socket.on('error', (error) => {
      toast.error(error);
    });
    return () => {
      socket.off('playersUpdated');
      socket.off('exception');
      socket.off('error');
    };
  }, []);

  const handleKeyDown = (e, submit) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submit();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      sessionStorage.setItem('playerToken', myToken);
    }
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [isSuccess, isError]);

  if (isSuccess) {
    return <Redirect to={`/game/${customGameId}`} />;
  }

  return (
    <AuthTemplate>
      <StyledHeading as="h2">{t('join_game_page_heading')}</StyledHeading>
      <Paragraph>{customGameId ? `${t('join_game_page_game_id')}: ${customGameId}` : ''}</Paragraph>
      <StyledForm
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          handleKeyDown(e, handleSubmit(onSubmit));
        }}
        method="POST"
      >
        <StyledFieldWrapper>
          <Label htmlFor="email">{t('join_game_page_input_name')}</Label>
          <Input type="text" {...register('username')} placeholder={t('join_game_page_input_name_placeholder')} />
          <StyledErrorMessage>{errors.username?.message}</StyledErrorMessage>
        </StyledFieldWrapper>
        <StyledFieldWrapper>
          <Label htmlFor="email">{t('join_game_page_input_email')}</Label>
          <Input type="text" {...register('email')} placeholder={t('join_game_page_input_email_placeholder')} />
          <StyledErrorMessage>{errors.email?.message}</StyledErrorMessage>
        </StyledFieldWrapper>
        <StyledButtonsWrapper>
          <BackButton onClick={history.goBack}>
            <span className="text-center w-full">{t('join_game_page_button_cancel')}</span>
          </BackButton>
          <LoginButton type="submit">
            {false && <Loader color="white" size={4} />}
            <span className="text-center w-full">{t('join_game_page_button_join_game')}</span>
          </LoginButton>
        </StyledButtonsWrapper>
      </StyledForm>
    </AuthTemplate>
  );
};

export default JoinGamePage;
