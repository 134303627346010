import React from 'react';
import tw from 'twin.macro';
import Logo from 'components/atoms/Logo';
import NavMenu from 'components/molecules/NavMenu/NavMenu';

const StyledLogoWrapper = tw.div`
  sm:w-full
  md:w-1/5
  2xl:w-1/6
  my-4
`;

const Header = () => (
  <header className="bg-darktop bg-cover bg-center bg-no-repeat w-full mb-4 2xl:h-64 h-44 mx-auto ">
    <div className="md:flex mx-10 py-3 h-2/3">
      <StyledLogoWrapper>
        <Logo />
      </StyledLogoWrapper>
      <NavMenu />
    </div>
  </header>
);

export default Header;
