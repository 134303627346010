import React from 'react';
import PropTypes from 'prop-types';
import DeckCard from './DeckCard';

const Deck = ({ mainColor, _id, colorFrom, colorVia, colorTo, image, cardTypesCount, onlyWatch }) => (
  <div className="inline-block h-full w-full">
    <div className="h-full mx-auto items-center">
      <DeckCard
        mainColor={mainColor}
        _id={_id}
        colorFrom={colorFrom}
        colorVia={colorVia}
        colorTo={colorTo}
        image={image}
        cardTypesCount={cardTypesCount}
        onlyWatch={onlyWatch}
      />
    </div>
  </div>
);

Deck.propTypes = {
  mainColor: PropTypes.string,
  _id: PropTypes.string.isRequired,
  colorFrom: PropTypes.string,
  colorVia: PropTypes.string,
  colorTo: PropTypes.string,
  image: PropTypes.string,
  cardTypesCount: PropTypes.number,
  onlyWatch: PropTypes.bool,
};

Deck.defaultProps = {
  mainColor: null,
  colorFrom: null,
  colorVia: null,
  colorTo: null,
  image: null,
  cardTypesCount: 2,
  onlyWatch: false,
};

export default Deck;
