import React, { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { Grid, _ } from 'gridjs-react';
import 'translations/i18n';
import { useTranslation } from 'react-i18next';
import { PlusIcon, XIcon } from '@heroicons/react/solid';
import AdminTemplate from 'templates/AdminTemplate';
import Heading from 'components/atoms/Heading';
import Button from 'components/atoms/Button';
import Paragraph from 'components/atoms/Paragraph';
import { clearState, findUsers, usersSelector } from './UsersSlice';
import AddPackageModal from './AddPackageModal/AddPackageModal';
import DeleteUserModal from './DeleteUserModal/DeleteUserModal';

const StyledHeading = tw(Heading)`
  text-2xl
  mt-5
  pt-3
`;

const StyledButton = styled(Button)(({ color }) => [
  tw`
        w-32
        border-0
        py-1
        px-2
        ml-2
        text-xs
      `,
  color === 'red' && tw`bg-red-500 hover:bg-red-600`,
  color === 'blue' && tw`bg-blue-500 hover:bg-blue-600`,
]);

const UsersPage = () => {
  const [openAddPackageModal, setOpenAddPackageModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [userId, setUserId] = useState(null);

  const [itemToDelete, setItemToDelete] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const users = useSelector(usersSelector);
  const { isSuccess, isError, errorMessage, items } = users;

  useEffect(() => {
    dispatch(findUsers());
  }, []);

  useEffect(() => () => dispatch(clearState()), []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  const handleOpenAddPackageModal = (userToGetPackageId) => {
    setUserId(userToGetPackageId);
    setOpenAddPackageModal(true);
  };

  const handleOpenDeleteUserModal = (itemToDeleteId) => {
    setItemToDelete(items.find((item) => item._id === itemToDeleteId));
    setOpenDeleteUserModal(true);
  };

  return (
    <AdminTemplate>
      <>
        {openAddPackageModal && (
          <AddPackageModal open={openAddPackageModal} setOpen={setOpenAddPackageModal} userId={userId} />
        )}
        {openDeleteUserModal && (
          <DeleteUserModal
            open={openDeleteUserModal}
            setOpen={setOpenDeleteUserModal}
            id={itemToDelete._id}
            username={itemToDelete.username}
          />
        )}
        <StyledHeading as="h2">{t('users_page_heading')}</StyledHeading>
        <Grid
          data={() =>
            new Promise((resolve) => {
              setTimeout(() => resolve(items), 500);
            })
          }
          className={{
            table: 'w-full',
          }}
          columns={[
            {
              id: '_id',
              name: t('users_page_column_id'),
              hidden: true,
            },
            {
              id: 'username',
              name: t('users_page_column_username'),
            },
            {
              id: 'email',
              name: t('users_page_column_email'),
            },
            {
              id: 'subscriptions',
              name: 'Subscriptions',
              sort: false,
              formatter: (item) =>
                _(
                  <>
                    {Object.values(item).map((value) => (
                      <Paragraph textXs>
                        {value.packageName} ({value.gamesCount}),
                      </Paragraph>
                    ))}
                  </>,
                ),
            },
            {
              name: t('users_page_column_options'),
              sort: false,
              formatter: (cell, row) =>
                _(
                  <>
                    <StyledButton color="blue" onClick={() => handleOpenAddPackageModal(row.cells[0].data)}>
                      <PlusIcon className="w-3 mr-1" /> {t('users_page__button_add_package')}
                    </StyledButton>
                    <StyledButton color="red" onClick={() => handleOpenDeleteUserModal(row.cells[0].data)}>
                      <XIcon className="w-4 h-4 mr-1" /> {t('users_page__button_delete_user')}
                    </StyledButton>
                  </>,
                ),
            },
          ]}
          search
          sort
          pagination={{
            enabled: true,
            limit: 30,
          }}
        />
      </>
    </AdminTemplate>
  );
};

export default UsersPage;
