import axios from 'axios';
import { config } from 'constants.js';
import authHeader from 'features/auth/authHeader';

const find = () => axios.get(`${config.url.API_URL}/users`, { headers: authHeader() });

const remove = ({ userId }) => axios.delete(`${config.url.API_URL}/users/${userId}`, { headers: authHeader() });

const addPackageToUser = ({ userId, gamesPackageId }) =>
  axios.post(
    `${config.url.API_URL}/users/addSubsciptionForFree`,
    {
      userId,
      gamesPackageId,
    },
    { headers: authHeader() },
  );

export default {
  find,
  remove,
  addPackageToUser,
};
