/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { routes } from 'routes';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { signupUser, userSelector, clearState, getUserByToken } from 'features/auth/AuthSlice';
import AuthTemplate, {
  StyledForm,
  StyledHeading,
  StyledFieldWrapper,
  StyledErrorMessage,
  StyledButtonsWrapper,
  StyledLinkWrapper,
  StyledLink,
} from 'templates/AuthTemplate';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import Loader from 'components/atoms/Loader';

const schema = yup.object().shape({
  username: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const RegisterOrganizationPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const user = useSelector(userSelector);
  const { isSuccess, isFetching, isError, errorMessage, roles } = user;

  const onSubmit = (data) => {
    dispatch(
      signupUser({
        ...data,
        isOrganizationAccount: true,
      }),
    );
  };

  useEffect(() => {
    dispatch(getUserByToken());
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
      if (roles.includes('admin')) {
        history.push('/admin-board/cards');
      } else if (roles.includes('organization')) {
        history.push('/leader-board/buy-package');
      } else if (roles.includes('user')) {
        history.push('/leader-board/buy-package');
      } else {
        history.push('/');
      }
    }
  }, [isError, isSuccess]);

  return (
    <AuthTemplate longForm>
      <StyledHeading as="h2">{t('register_orgranization_page_heading')}</StyledHeading>

      <StyledForm onSubmit={handleSubmit(onSubmit)} method="POST">
        <StyledFieldWrapper>
          <Label htmlFor="username">{t('register_orgranization_page_input_username')}</Label>
          <Input
            {...register('username')}
            type="text"
            placeholder={t('register_orgranization_page_input_username_placeholder')}
          />
          <StyledErrorMessage>{errors.username?.message}</StyledErrorMessage>
        </StyledFieldWrapper>

        <StyledFieldWrapper>
          <Label htmlFor="email">{t('register_page_input_email')}</Label>
          <Input
            {...register('email')}
            type="text"
            placeholder={t('register_orgranization_page_input_email_placeholder')}
          />
          <StyledErrorMessage>{errors.email?.message}</StyledErrorMessage>
        </StyledFieldWrapper>

        <StyledFieldWrapper>
          <Label htmlFor="password">{t('register_orgranization_page_input_password')}</Label>
          <Input
            type="password"
            {...register('password')}
            placeholder={t('register_orgranization_page_input_password_placeholder')}
            autoComplete="off"
          />
          <StyledErrorMessage>{errors.password?.message}</StyledErrorMessage>
        </StyledFieldWrapper>

        <StyledFieldWrapper>
          <Label htmlFor="passwordConfirmation">{t('register_orgranization_page_input_password_repeat')}</Label>
          <Input
            type="password"
            {...register('passwordConfirmation')}
            placeholder={t('register_orgranization_page_input_password_repeat_placeholder')}
            autoComplete="off"
          />
          <StyledErrorMessage>{errors.passwordConfirmation?.message}</StyledErrorMessage>
        </StyledFieldWrapper>

        <StyledButtonsWrapper>
          <Button onClick={history.goBack}>{t('register_orgranization_page_button_cancel')}</Button>
          <Button>
            {isFetching && <Loader color="white" size={4} />} {t('register_orgranization_page_button_sing_up')}
          </Button>
        </StyledButtonsWrapper>
      </StyledForm>

      <StyledLinkWrapper>
        <StyledLink to={routes.login}>{t('register_orgranization_page_already_have_an_account')}</StyledLink>
        <StyledLink to={routes.register}>Register as Leader</StyledLink>
      </StyledLinkWrapper>
    </AuthTemplate>
  );
};

export default RegisterOrganizationPage;
