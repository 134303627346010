import axios from 'axios';
import { config } from 'constants.js';
import authHeader from 'features/auth/authHeader';

const create = ({ packageId }) =>
  axios.post(
    `${config.url.API_URL}/orders`,
    {
      packageId,
    },
    { headers: authHeader() },
  );

const getUserOrders = () => axios.get(`${config.url.API_URL}/orders`, { headers: authHeader() });

const getOrderById = (id) => axios.get(`${config.url.API_URL}/orders/${id}`, { headers: authHeader() });

export default {
  create,
  getUserOrders,
  getOrderById,
};
