/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isLoginAsLeader } from 'features/auth/isLogin';

const LeaderRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (isLoginAsLeader() ? <Component {...props} /> : <Redirect to="/login" />)} />
);

LeaderRoute.propTypes = {
  component: PropTypes.func.isRequired,
  rest: PropTypes.node,
};

LeaderRoute.defaultProps = {
  rest: null,
};

export default LeaderRoute;
