/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef } from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openDropCardModal, openSendCardModal } from 'features/game/PlayerBoardSlice';
import SocketContext from 'socket/socket';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import styled from 'styled-components';

const StyledMenu = tw.div`
    origin-top-left
    left-1/2
    top-1/2
    mt-2
    min-w-[10rem]
    rounded-md
    shadow-lg
    bg-white
    ring-1
    ring-black
    ring-opacity-5
    divide-y
    divide-gray-100
    z-50
`;

const StyledGruppedItems = tw.div`
    py-1
`;

const StyledMenuItem = tw.p`
    block
    px-4
    py-2
    text-sm
    text-gray-700
    hover:bg-gray-100
    hover:text-gray-900
`;
export const StyledMenuTitle = styled.p(({ onlyTitle }) => [
  tw`
    block
    rounded-md
    border-2
    border-white
    px-4
    py-2
    text-sm
    text-gray-700
    font-bold
  `,
  !onlyTitle && tw`border-b-0 rounded-b-none`,
]);

const CardMenu = ({ cardId, isShowed, title, titleBackground, titleColor }) => {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();

  const handleDropCardClick = () => {
    dispatch(openDropCardModal({ cardToDropId: cardId }));
  };

  const handleSendCardClick = () => {
    dispatch(openSendCardModal({ cardToSendId: cardId }));
  };

  const handleShowHideCardClick = () => {
    if (isShowed) {
      socket.emit('hideCard', cardId);
    } else if (!isShowed) {
      socket.emit('showCard', cardId);
    }
  };

  return (
    <StyledMenu role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
      <StyledMenuTitle className={`bg-${titleBackground}`} style={{ color: titleColor }}>
        {title}
      </StyledMenuTitle>
      <StyledGruppedItems>
        <StyledMenuItem onClick={handleShowHideCardClick}>
          {isShowed ? t('card_menu_item_hide') : t('card_menu_item_show')}
        </StyledMenuItem>
      </StyledGruppedItems>
      <StyledGruppedItems>
        <StyledMenuItem onClick={handleSendCardClick}>{t('card_menu_item_send_to')}</StyledMenuItem>
        <StyledMenuItem onClick={handleDropCardClick}>{t('card_menu_item_drop')}</StyledMenuItem>
      </StyledGruppedItems>
    </StyledMenu>
  );
};

CardMenu.propTypes = {
  cardId: PropTypes.string.isRequired,
  isShowed: PropTypes.bool.isRequired,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleBackground: PropTypes.string,
};
CardMenu.defaultProps = {
  title: null,
  titleColor: null,
  titleBackground: null,
};

export default CardMenu;
