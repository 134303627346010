import React from 'react';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import Container from 'components/atoms/Container';
import HeroImage from 'components/atoms/HeroImage';
import Heading from 'components/atoms/Heading';
import Paragraph from 'components/atoms/Paragraph';
import WelcomeTemplate from 'templates/WelcomeTemplate';

const StyledContentWrapper = tw.div`
    w-2/3
    lg:pl-24
    md:pl-16
    flex
    flex-col
    md:items-start
    md:text-left
    items-center
    text-center
`;

const StyledHeading = tw(Heading)`
  text-white
`;

const StyledParagraph = tw(Paragraph)`
  text-white
`;

const WelcomePage = () => {
  const { t } = useTranslation();
  return (
    <WelcomeTemplate>
      <Container>
        <HeroImage />
        <StyledContentWrapper>
          <StyledHeading>{t('welcome_heading')}</StyledHeading>
          <StyledParagraph>{t('welcome_text')}</StyledParagraph>
        </StyledContentWrapper>
      </Container>
    </WelcomeTemplate>
  );
};

export default WelcomePage;
