/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import axios from 'axios';
import tw from 'twin.macro';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// import toast from 'react-hot-toast';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { config } from 'constants.js';
import 'translations/i18n';
import { clearState, logout } from 'features/auth/AuthSlice';
import AuthTemplate, { StyledHeading } from 'templates/AuthTemplate';
import Button from 'components/atoms/Button';

const ButtonWrapper = tw.div`
w-24
`;

const assignSubscriptionPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  // const user = useSelector(userSelector);
  // const { isSuccess, isError, errorMessage } = user;

  const onSubmit = () => {
    const parsed = queryString.parse(location.search);
    const data = {
      userId: parsed.userId,
      sharedWith: parsed.email,
      packageId: parsed.packageId,
    };

    axios
      .post(`${config.url.API_URL}/users/findOneByEmail`, { email: data.sharedWith })
      .then(() => {
        sessionStorage.clear();
        localStorage.clear();
        dispatch(logout());
        history.push(`/login?userId=${data.userId}&packageId=${data.packageId}&sharedWith=${data.sharedWith}`);
      })
      .catch(() => {
        // not found user
        sessionStorage.clear();
        localStorage.clear();
        dispatch(logout());
        history.push(`/register?userId=${data.userId}&packageId=${data.packageId}&sharedWith=${data.sharedWith}`);
      });
  };

  useEffect(() => () => dispatch(clearState()), []);

  return (
    <AuthTemplate>
      <StyledHeading as="h2">{t('relatedPlayers_pickUpSubscriptions')}</StyledHeading>
      <ButtonWrapper>
        <Button onClick={() => onSubmit()}>{t('relatedPlayers_button_pickUp')}</Button>
      </ButtonWrapper>
    </AuthTemplate>
  );
};

export default assignSubscriptionPage;
