import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import tw from 'twin.macro';
import toast from 'react-hot-toast';
import { Grid, _ } from 'gridjs-react';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import Heading from 'components/atoms/Heading';
import LeaderTemplate from 'templates/LeaderTemplate';
import Paragraph from 'components/atoms/Paragraph';
import LeaderBoardMenu from 'components/molecules/LeaderBoardMenu/LeaderBoardMenu';
import { userSelector } from 'features/auth/AuthSlice';
import { clearState, findAll, packagesToBuySelector } from './PackagesToBuySlice';
import PayWithPaysonButton from './PayWithPaysonButton/PayWithPaysonButton';
import BuyPackagePreOrderModal from './BuyPackagePreOrderModal/BuyPackagePreOrderModal';

const StyledHeading = tw(Heading)`
  text-2xl
  mt-5
  pt-3
`;

const PackagesToBuyPage = () => {
  const { t } = useTranslation();
  const [openPreOrderModal, setOpenPreOrderModal] = useState(false);
  const [packageToBuy, setPackageToBuy] = useState(null);

  const dispatch = useDispatch();

  const packagesToBuy = useSelector(packagesToBuySelector);
  const { isError, errorMessage, items } = packagesToBuy;

  const user = useSelector(userSelector);
  const { roles } = user;

  useEffect(() => {
    dispatch(findAll());
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [isError]);

  const handleOpenPreOrderModalClick = (packageToBuyId) => {
    const itemToBuy = items.find((item) => item._id === packageToBuyId);
    setPackageToBuy(itemToBuy);
    setOpenPreOrderModal(true);
  };

  return (
    <LeaderTemplate>
      <>
        <LeaderBoardMenu isOrganization={roles.includes('organization')} />
        {openPreOrderModal && (
          <BuyPackagePreOrderModal
            open={openPreOrderModal}
            setOpen={setOpenPreOrderModal}
            packageToBuy={packageToBuy}
          />
        )}
        <StyledHeading as="h2">{t('leader_buy_package_page_heading')}:</StyledHeading>

        <Grid
          data={() =>
            new Promise((resolve) => {
              setTimeout(() => resolve(items), 500);
            })
          }
          className={{
            table: 'w-full',
          }}
          columns={[
            {
              id: '_id',
              name: t('leader_buy_package_page_column_id'),
              hidden: true,
            },
            {
              id: 'name',
              name: t('leader_buy_package_page_column_name'),
            },
            {
              id: 'gamesCount',
              name: t('leader_buy_package_page_column_games_count'),
              formatter: (cell) => _(`${cell} ${t('leader_buy_package_page_column_games_count_cell')}`),
            },
            {
              id: 'gameTypes',
              name: t('leader_buy_package_page_column_games_types'),
              formatter: (cell) =>
                _(<Paragraph textXs>{cell.map((gameType) => `${gameType.name.trim()}, `)}</Paragraph>),
            },
            {
              id: 'subscriptionValidityPeriod',
              name: t('leader_buy_package_page_column_subscription_period'),
              formatter: (cell) => _(`${cell} ${t('leader_buy_package_page_column_subscription_period_cell')}`),
            },
            {
              id: 'unitPrice',
              name: t('leader_buy_package_page_column_price'),
              formatter: (cell) => _(`${cell} ${t('leader_buy_package_page_column_price_cell')}`),
            },
            /*             {
              id: 'taxRate',
              name: t('leader_buy_package_page_column_tax_rate'),
              formatter: (cell) => _(`${cell}%`),
            }, */
            {
              name: t('leader_buy_package_page_column_buy_package'),
              formatter: (cell, row) =>
                _(
                  <>
                    <PayWithPaysonButton clickFn={() => handleOpenPreOrderModalClick(row.cells[0].data)} />
                  </>,
                ),
            },
          ]}
          search={false}
          sort={false}
          pagination={false}
        />
      </>
    </LeaderTemplate>
  );
};

export default PackagesToBuyPage;
