/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LanguagesAPI from './LanguagesAPI';

export const createLanguage = createAsyncThunk('languages/create', async ({ name, code }, thunkAPI) => {
  try {
    const response = await LanguagesAPI.create({
      name,
      code,
    });
    const { data } = response;
    if (response.status === 201) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const updateLanguage = createAsyncThunk('languages/update', async ({ _id, name, code, status }, thunkAPI) => {
  try {
    const response = await LanguagesAPI.update({
      _id,
      name,
      code,
      status,
    });
    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const findLanguageById = createAsyncThunk('languages/findById', async ({ id }, thunkAPI) => {
  try {
    const response = await LanguagesAPI.findOneById({ languageId: id });
    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const findLanguages = createAsyncThunk('languages/find', async (thunkAPI) => {
  try {
    const response = await LanguagesAPI.find();

    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const deleteLanguage = createAsyncThunk('languages/delete', async ({ id }, thunkAPI) => {
  try {
    const response = await LanguagesAPI.remove({ languageId: id });

    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const languagesSlice = createSlice({
  name: 'languages',
  initialState: {
    items: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createLanguage.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = [...state.items, payload];
    },
    [createLanguage.pending]: (state) => {
      state.isFetching = true;
    },
    [createLanguage.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [updateLanguage.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = state.items.map((item) => (item._id === payload._id ? payload : item));
    },
    [updateLanguage.pending]: (state) => {
      state.isFetching = true;
    },
    [updateLanguage.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [findLanguageById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findLanguageById.pending]: (state) => {
      state.isFetching = true;
    },
    [findLanguageById.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [findLanguages.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findLanguages.pending]: (state) => {
      state.isFetching = true;
    },
    [findLanguages.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [deleteLanguage.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = state.items.filter((item) => item._id !== payload._id);
    },
    [deleteLanguage.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteLanguage.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
  },
});

export const { clearState } = languagesSlice.actions;

export const languageSelector = (state) => state.languages;
