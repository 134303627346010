import React from 'react';
import tw, { styled } from 'twin.macro';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import LogoImg from 'assets/images/logotype-shadow.png';
import OnlyLogoImg from 'assets/images/only-logotype-shadow.png';

const StyledWrapper = tw.div`
    block
    items-center
    my-auto
    h-full
`;

let LogoImage;
let OnlyLogoImage;
const browserHref = window.location.href;
if (
  browserHref.indexOf('/game/') > 0 ||
  browserHref.indexOf('/observing-game/') > 0 ||
  browserHref.indexOf('join-game') > 0
) {
  LogoImage = styled.img(({ center, withName }) => [
    tw`my-auto h-full hidden lg:block`,
    center && tw`mx-auto`,
    withName && tw`block`,
  ]);
  OnlyLogoImage = styled.img(({ center, withName }) => [
    tw`my-auto h-full block pl-2 lg:hidden`,
    center && tw`mx-auto`,
    withName && tw`hidden`,
  ]);
} else {
  LogoImage = styled.img(({ center, withName }) => [
    tw`my-auto h-auto hidden lg:block`,
    center && tw`mx-auto`,
    withName && tw`block`,
  ]);
  OnlyLogoImage = styled.img(({ center, withName }) => [
    tw`my-auto h-full block pl-2 lg:hidden`,
    center && tw`mx-auto`,
    withName && tw`hidden`,
  ]);
}
const Logo = ({ center, isLink, withName }) =>
  isLink ? (
    <Link to="/">
      <StyledWrapper>
        <LogoImage src={LogoImg} alt="Logo FeedbackGame" center={center} withName={withName} />
        <OnlyLogoImage src={OnlyLogoImg} alt="Logo FeedbackGame" center={center} withName={withName} />
      </StyledWrapper>
    </Link>
  ) : (
    <StyledWrapper>
      <LogoImage src={LogoImg} alt="Logo FeedbackGame" center={center} withName={withName} />
      <OnlyLogoImage src={OnlyLogoImg} alt="Logo FeedbackGame" center={center} withName={withName} />
    </StyledWrapper>
  );

Logo.propTypes = {
  center: PropTypes.bool,
  isLink: PropTypes.bool,
  withName: PropTypes.bool,
};

Logo.defaultProps = {
  center: false,
  isLink: false,
  withName: true,
};

export default Logo;
