import React from 'react';
import { NavLink } from 'react-router-dom';
import tw from 'twin.macro';
import { routes } from 'routes';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';

const StyledWrapper = tw.nav`
    flex
    flex-wrap
    items-center
    text-base
    justify-center
    mb-4
`;

const NavItem = tw(NavLink)`
    flex-grow
    border-b-2
    py-2
    text-lg
    px-1
    cursor-pointer
    border-gray-300
`;

const AdminBoardMenu = () => {
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <NavItem to={routes.adminBoard.gameTypes} activeStyle={tw`text-indigo-500 border-indigo-500`}>
        {t('admin_nav_menu_game_type')}
      </NavItem>
      <NavItem to={routes.adminBoard.cards} activeStyle={tw`text-indigo-500 border-indigo-500`}>
        {t('admin_nav_menu_cards')}
      </NavItem>
      <NavItem to={routes.adminBoard.cardTypes} activeStyle={tw`text-indigo-500 border-indigo-500`}>
        {t('admin_nav_menu_card_types')}
      </NavItem>
      <NavItem to={routes.adminBoard.languages} activeStyle={tw`text-indigo-500 border-indigo-500`}>
        {t('admin_nav_menu_language')}
      </NavItem>
      <NavItem to={routes.adminBoard.categories} activeStyle={tw`text-indigo-500 border-indigo-500`}>
        {t('admin_nav_menu_category')}
      </NavItem>
      <NavItem to={routes.adminBoard.packages} activeStyle={tw`text-indigo-500 border-indigo-500`}>
        {t('admin_nav_menu_packages')}
      </NavItem>
      <NavItem to={routes.adminBoard.users} activeStyle={tw`text-indigo-500 border-indigo-500`}>
        Users
      </NavItem>
    </StyledWrapper>
  );
};

export default AdminBoardMenu;
