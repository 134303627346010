import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { userSelector } from 'features/auth/AuthSlice';
import tw from 'twin.macro';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import SocketContext from 'socket/socket';
import Button from 'components/atoms/Button';
import { findAllByUser, leaderGamesSelector } from 'features/leader/gamesList/LeaderGamesSlice';
import Heading from 'components/atoms/Heading';
import Paragraph from 'components/atoms/Paragraph';
import Loader from 'components/atoms/Loader';
import LeaderTemplate, { HeaderItemWrapper } from 'templates/LeaderTemplate';
import LeaderBoardMenu from 'components/molecules/LeaderBoardMenu/LeaderBoardMenu';
import SingleGameItem from './SingleGameItem/SingleGameItem';
import NewGameModal from './NewGameModal/NewGameModal';
import NewGameFromSharedPackageModal from './NewGameFromSharedPackageModal/NewGameFromSharedPackageModal';
import FlowSection from './FlowSection/FlowSection';
import InfoModal from './InfoModal/InfoModal';

const StyledGamesWrapper = tw.div`
  mb-5
  h-full
  flex
  flex-col
`;

const StyledHeadingWrapper = tw.div`
  flex
  flex-row
`;

const StyledHeading = tw(Heading)`
  text-2xl
  mt-5
  pt-3
`;

const StyledButtonWrapper = tw.div`
    flex
    mt-10
    mr-auto
`;

const StyledButton = tw(Button)`
  mx-2
  bg-indigo-500
  hover:bg-indigo-600
`;

const StyledHeaderItem = tw.div`
  font-semibold
  text-center
`;

const GamesListPage = () => {
  const { t } = useTranslation();
  const [openNewGameModal, setOpenNewGameModal] = useState(false);
  const [openNewGameFromSharedPackageModal, setOpenNewGameFromSharedPackageModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(true);

  const socket = useContext(SocketContext);
  const dispatch = useDispatch();

  const games = useSelector(leaderGamesSelector);

  const { isFetching, items } = games;

  const checkUserAccptedInfo = () => {
    const acceptedInfo = 'acceptedInfo=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(acceptedInfo) === 0) {
        return c.substring(acceptedInfo.length, c.length);
      }
    }
    return 'false';
  };

  useEffect(() => {
    dispatch(findAllByUser()).then((res) => {
      const userGames = res.payload;
      userGames.forEach((userGame) => {
        const { customId } = userGame || {};
        socket.emit('startObservingGameStatus', customId);
      });
    });
    const userAcceptedInfo = checkUserAccptedInfo();
    if (userAcceptedInfo === 'true') setOpenInfoModal(false);
  }, []);

  useEffect(() => {
    socket.on('gameStatusUpdated', () => {
      dispatch(findAllByUser());
    });
    socket.on('youJoinedGameAsLeader', ({ token, customGameId }) => {
      localStorage.setItem(`leader_token_${customGameId}`, token);
      window.open(`/game/${customGameId}`, `Play As Leader GameId: ${customGameId}`);
    });
    socket.on('exception', (data) => {
      toast.error(data.message);
    });
    socket.on('error', (error) => {
      toast.error(error);
    });
    return () => {
      socket.off('gameStatusUpdated');
      socket.off('youJoinedGameAsLeader');
      socket.off('exception');
      socket.off('error');
    };
  }, []);

  return (
    <LeaderTemplate>
      <>
        <LeaderBoardMenu />
        {openNewGameModal && <NewGameModal open={openNewGameModal} setOpen={setOpenNewGameModal} />}
        {openInfoModal && <InfoModal open={openInfoModal} setOpen={setOpenInfoModal} />}
        {openNewGameFromSharedPackageModal && (
          <NewGameFromSharedPackageModal
            open={openNewGameFromSharedPackageModal}
            setOpen={setOpenNewGameFromSharedPackageModal}
          />
        )}
        <StyledGamesWrapper>
          <StyledHeadingWrapper>
            <StyledHeading as="h2">{t('leader_game_list_page_heading')}:</StyledHeading>
          </StyledHeadingWrapper>
          <HeaderItemWrapper>
            <StyledHeaderItem className="w-1/6">
              <Paragraph textSm>{t('leader_game_list_page_th_name')}</Paragraph>
            </StyledHeaderItem>
            <StyledHeaderItem className="w-1/3">
              <Paragraph textSm>{t('leader_game_list_page_th_details')}</Paragraph>
            </StyledHeaderItem>
            <StyledHeaderItem className="w-1/2">
              <Paragraph textSm>{t('leader_game_list_page_th_options')}</Paragraph>
            </StyledHeaderItem>
          </HeaderItemWrapper>
          <div className="h-full flex flex-col">
            {isFetching ? (
              <Loader />
            ) : (
              items.map((item, index) => <SingleGameItem item={item} key={item.customId} index={index} />)
            )}
            <StyledButtonWrapper>
              <StyledButton onClick={() => setOpenNewGameModal(true)}>
                {t('leader_game_list_page_create_new_game')}
              </StyledButton>
              <StyledButton onClick={() => setOpenNewGameFromSharedPackageModal(true)}>
                New Game From Shared Package
              </StyledButton>
            </StyledButtonWrapper>
            <StyledHeading as="h2">Game flow:</StyledHeading>
            <FlowSection />
          </div>
        </StyledGamesWrapper>
      </>
    </LeaderTemplate>
  );
};

export default GamesListPage;
