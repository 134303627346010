import React from 'react';
import tw, { styled } from 'twin.macro';
import PlayerBar from 'components/molecules/PlayerBar/PlayerBar';
import Card from 'components/molecules/Card/Card';
import { useDispatch, useSelector } from 'react-redux';
import { myPlayerSelector } from 'features/game/GameSlice';
import MyCard from 'components/molecules/Card/MyCard/MyCard';
import DropCardModal from 'components/molecules/Card/DropCardModal/DropCardModal';
import { closeDropCardModal, closeSendCardModal, playerBoardSelector } from 'features/game/PlayerBoardSlice';
import SendCardModal from 'components/molecules/Card/SendCardModal/SendCardModal';

const StyledWrapper = tw.div`
  mx-auto
  flex
  flex-col
  h-full
  bg-white
  w-full
  overflow-y-auto lg:overflow-visible
`;

const CardsWrapper = styled.div(({ overflow, borderBottom }) => [
  tw`
    grid-cols-6
    flex-row
    gap-1
    lg:h-1/2
    px-1
    py-1
    lg:grid
  `,
  overflow === 'scroll' ? tw`overflow-visible lg:overflow-hidden` : tw`overflow-visible`,
  borderBottom && tw`border-b-2 border-gray-300 border-dashed`,
]);

const StyledNameArea = tw.div`
  flex-none
  h-8
`;

const MyPlayerBoard = () => {
  const dispatch = useDispatch();

  const { myName, myHandCards, myGiftCards, isLeader: amILeader } = useSelector(myPlayerSelector);

  const playerBoard = useSelector(playerBoardSelector);

  const { isDropCardModalOpen, isSendCardModalOpen } = playerBoard;

  const closeDropCardModalFn = () => {
    dispatch(closeDropCardModal());
  };

  const closeSendCardModalFn = () => {
    dispatch(closeSendCardModal());
  };

  return (
    <>
      <DropCardModal open={isDropCardModalOpen} close={closeDropCardModalFn} />
      <SendCardModal open={isSendCardModalOpen} close={closeSendCardModalFn} />
      <StyledWrapper>
        <CardsWrapper borderBottom>
          {myHandCards.map(({ card, isShowed }) => (
            <MyCard card={card} isShowed={isShowed} />
          ))}
        </CardsWrapper>

        <CardsWrapper overflow="scroll">
          {myGiftCards.map(({ card }) => (
            <Card card={card} visible myGiftCard />
          ))}
        </CardsWrapper>
        <StyledNameArea>
          <PlayerBar playerName={myName} myPlayer amILeader={amILeader} />
        </StyledNameArea>
      </StyledWrapper>
    </>
  );
};

export default MyPlayerBoard;
