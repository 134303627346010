/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthAPI from './AuthAPI';
import SubscriptionsAPI from './SubscriptionsAPI';

export const signupUser = createAsyncThunk(
  'users/signupUser',
  async ({ username, email, password, isOrganizationAccount }, thunkAPI) => {
    try {
      const response = await AuthAPI.register({
        username,
        email,
        password,
        isOrganizationAccount,
      });

      const { data } = response;
      if (response.status === 201) {
        localStorage.setItem('token', data.access_token);

        return { ...data, username, email };
      }
      return thunkAPI.rejectWithValue(data);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const loginUser = createAsyncThunk('users/login', async ({ email, password }, thunkAPI) => {
  try {
    const response = await AuthAPI.login({
      username: email,
      password,
    });
    const { data } = response;
    if (response.status === 201) {
      localStorage.setItem('token', data.access_token);
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const forgotPassword = createAsyncThunk('users/forgotPassword', async ({ email }, thunkAPI) => {
  try {
    const response = await AuthAPI.forgotPassword({
      email,
    });
    const { data } = response;
    if (response.status === 201) {
      return true;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const resetPassword = createAsyncThunk('users/resetPassword', async ({ token, password }, thunkAPI) => {
  try {
    const response = await AuthAPI.resetPassword({
      token,
      password,
    });
    const { data } = response;
    if (response.status === 201) {
      return true;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const getUserByToken = createAsyncThunk('users/getUserByToken', async (thunkAPI) => {
  try {
    const response = await AuthAPI.getUserByToken();

    const { data } = response;

    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const getUserByEmail = createAsyncThunk('users/getUserByEmail', async ({ email }, thunkAPI) => {
  try {
    const response = await AuthAPI.getUserByEmail(email);

    const { data } = response;

    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const setOrganizationId = createAsyncThunk('users/setOrganizationId', async ({ organization }, thunkAPI) => {
  try {
    const response = await AuthAPI.setOrganizationId({ organization });

    const { data } = response;

    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const connectWithOrganization = createAsyncThunk(
  'users/connectWithOrganization',
  async ({ email, userEmail, packageId }, thunkAPI) => {
    try {
      const response = await AuthAPI.connectWithOrganization({ email, userEmail, packageId });

      const { data } = response;

      if (response.status === 200) {
        return data;
      }
      return thunkAPI.rejectWithValue(data);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const shareSubscription = createAsyncThunk(
  'users/shareSubscription',
  async ({ userId, packageId, sharedWith }, thunkAPI) => {
    try {
      const response = await SubscriptionsAPI.shareSubscription({
        userId,
        packageId,
        sharedWith,
      });
      const { data } = response;
      if (response.status === 201) {
        return data;
      }
      return thunkAPI.rejectWithValue(data);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userId: '',
    username: '',
    email: '',
    roles: [],
    subscriptions: {},
    subscriptionsSharedWithMe: {},
    token: '',
    organization: '',
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    getUserSuccess: false,
    getUserError: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.getUserSuccess = false;
      state.getUserError = false;

      return state;
    },

    logout: (state) => {
      state.userId = '';
      state.email = '';
      state.username = '';
      state.roles = [];
      state.subscriptions = {};
      state.token = '';
      state.organization = '';
      state.availableGames = 0;
      state.availableCategories = 0;
      state.subscriptionValidFor = '';
      state.purchasedCategories = [];
    },
  },
  extraReducers: {
    [signupUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.userId = payload.userId;
      state.email = payload.email;
      state.username = payload.username;
      state.roles = payload.roles;
      state.token = payload.access_token;
      state.subscriptions = payload.subscriptions;
    },
    [signupUser.pending]: (state) => {
      state.isFetching = true;
    },
    [signupUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.userId = payload.userId;
      state.email = payload.email;
      state.username = payload.username;
      state.roles = payload.roles;
      state.token = payload.access_token;
      state.organization = payload.organization;
      state.subscriptions = payload.subscriptions;
      state.subscriptionsSharedWithMe = payload.subscriptionsSharedWithMe;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [forgotPassword.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [forgotPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [resetPassword.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [resetPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [getUserByToken.pending]: (state) => {
      state.isFetching = true;
    },
    [getUserByToken.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.email = payload.email;
      state.username = payload.username;
      state.roles = payload.roles;
      state.userId = payload._id;
      state.subscriptions = payload.subscriptions;
      state.subscriptionsSharedWithMe = payload.subscriptionsSharedWithMe;
      state.organization = payload.organization;
    },
    [getUserByToken.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.getUserError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [shareSubscription.pending]: (state) => {
      state.isFetching = true;
    },
    [shareSubscription.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.subscriptions = payload.subscriptions;
    },
    [shareSubscription.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.getUserError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
  },
});

export const { clearState, logout } = authSlice.actions;

export const userSelector = (state) => state.user;
