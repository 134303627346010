/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import tw, { styled } from 'twin.macro';
import toast from 'react-hot-toast';
import { Grid, _ } from 'gridjs-react';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import Heading from 'components/atoms/Heading';
import LeaderTemplate from 'templates/LeaderTemplate';
import { clearState, findGameTypes, gameTypesSelector } from 'features/admin/gameTypes/GameTypesSlice';
import Button from 'components/atoms/Button';
import { PlayIcon } from '@heroicons/react/solid';
import InstructionModal from 'components/organisms/InstructionModal/InstructionModal';
import Paragraph from 'components/atoms/Paragraph';
import LeaderBoardMenu from 'components/molecules/LeaderBoardMenu/LeaderBoardMenu';

const StyledHeading = tw(Heading)`
  text-2xl
  mt-5
  pt-3
`;

const StyledButton = styled(Button)(() => [
  tw`
      2xl:w-40
      w-32
      border-0
      py-3
      px-3
      mx-auto
      text-xs
      items-center
      bg-blue-500
      hover:bg-blue-600
    `,
]);

const InstructionsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isInstructionModalOpen, setIsInstructionModalOpen] = useState(false);
  const [instructionToShowLink, setInstructionToShowLink] = useState('');

  const gameTypes = useSelector(gameTypesSelector);
  const { isError, errorMessage, items } = gameTypes;

  useEffect(() => {
    dispatch(findGameTypes());
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [isError]);

  const handleOpenShowInstrucionModalClick = (instructionLink) => {
    setInstructionToShowLink(instructionLink);
    setIsInstructionModalOpen(true);
  };

  return (
    <LeaderTemplate>
      <>
        <LeaderBoardMenu />
        <InstructionModal
          open={isInstructionModalOpen}
          close={setIsInstructionModalOpen}
          link={instructionToShowLink}
        />
        <StyledHeading as="h2">{t('instructions_page_heading')}:</StyledHeading>

        <Grid
          data={() =>
            new Promise((resolve) => {
              setTimeout(() => resolve(items), 500);
            })
          }
          className={{
            table: 'w-full',
          }}
          columns={[
            {
              id: '_id',
              name: t('instructions_page_column_id'),
              hidden: true,
            },
            {
              id: 'name',
              name: t('instructions_page_column_name'),
            },
            {
              id: 'instructionLink',
              name: t('instructions_page_column_instruction'),
              formatter: (cell, row) =>
                _(
                  cell && cell.length >= 1 ? (
                    <StyledButton color="blue" onClick={() => handleOpenShowInstrucionModalClick(cell)}>
                      <PlayIcon className="w-3 mr-1" /> {t('instructions_page_button_show_instruction')}
                    </StyledButton>
                  ) : (
                    <Paragraph>{t('instructions_page_text_no_instruction')}</Paragraph>
                  ),
                ),
            },
          ]}
          search={false}
          sort={false}
          pagination={false}
        />
      </>
    </LeaderTemplate>
  );
};

export default InstructionsPage;
