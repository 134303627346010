import React, { useContext, useState } from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import DropdownMenu from 'components/organisms/DropdownMenu/DropdownMenu';
import MenuItem from 'components/organisms/DropdownMenu/MenuItem';
import TreeDotsMenuButton from 'components/organisms/DropdownMenu/TreeDotsMenuButton/TreeDotsMenuButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeBiggerMyPlayerBoardModal,
  closeInstructionModal,
  openBiggerMyPlayerBoardModal,
  openInstructionModal,
  playerBoardSelector,
} from 'features/game/PlayerBoardSlice';
import BiggerMyPlayerBoardModal from 'components/organisms/BiggerMyPlayerBoardModal/BiggerMyPlayerBoardModal';
import { gameSelector } from 'features/game/GameSlice';
import SocketContext from 'socket/socket';
import InstructionModal from 'components/organisms/InstructionModal/InstructionModal';

const StyledWrapper = styled.div(({ myPlayer }) => [
  tw`
    mt-1
    h-8
    absolute
    bottom-0
    w-full
    flex
    bg-opacity-100
    z-10
  `,
  myPlayer ? tw`bg-green-300` : tw`bg-yellow-200`,
]);

const StyledPlayerName = tw.div`
    px-4
    text-xs
    leading-8
    font-semibold
    text-left
    capitalize
    cursor-pointer
    truncate
`;

const StyledLeaderMenu = tw.div`
  absolute
  right-0
  inline-block
  text-left
`;

const PlayerBar = ({ playerName, myPlayer, isLeader, amILeader }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);

  // eslint-disable-next-line no-unused-vars
  const [openDirection, setOpenDirection] = useState('top-left');

  const playerBoard = useSelector(playerBoardSelector);
  const { isBiggerMyPlayerBoardModalOpen, isInstructionModalOpen } = playerBoard;

  const game = useSelector(gameSelector);
  const { gameId, customGameId, isStarted, isPaused, gameInstruction } = game;

  const handleStartPauseContinueGame = () => {
    if (!isStarted) {
      socket.emit('startGame', { gameId });
    }
    if (isStarted && !isPaused) {
      socket.emit('pauseGame', { gameId });
    }
    if (isStarted && isPaused) {
      socket.emit('continueGame', { gameId });
    }
  };

  const handleShowBiggerMyPlayerBoardClick = () => {
    if (myPlayer) {
      dispatch(openBiggerMyPlayerBoardModal());
    }
  };

  const closeBiggerMyPlayerBoardModalFn = () => {
    dispatch(closeBiggerMyPlayerBoardModal());
  };

  const handleShowInstructionModalClick = () => {
    dispatch(openInstructionModal());
  };

  const closeInstructiondModalFn = () => {
    dispatch(closeInstructionModal());
  };

  const handleLeaveGame = () => {
    window.close();
  };

  const handleLeaveAndObserveGame = () => {
    window.open(`/observing-game/${customGameId}`, `Observing GameId: ${customGameId}`);
    window.close();
  };

  const handleRestartGame = () => {
    socket.emit('restartGame', customGameId);
  };

  return (
    <>
      <InstructionModal open={isInstructionModalOpen} close={closeInstructiondModalFn} link={gameInstruction} />
      <BiggerMyPlayerBoardModal open={isBiggerMyPlayerBoardModalOpen} close={closeBiggerMyPlayerBoardModalFn} />
      <StyledWrapper myPlayer={myPlayer}>
        <StyledPlayerName onClick={handleShowBiggerMyPlayerBoardClick}>
          {playerName} {isLeader && t('player_board_is_leader_text')}
        </StyledPlayerName>
        {amILeader && (
          <StyledLeaderMenu>
            <DropdownMenu menuButton={<TreeDotsMenuButton />} openDirection="top-left">
              <MenuItem
                text={
                  isStarted
                    ? (isPaused && t('player_board_continue_game')) || (!isPaused && t('player_board_pause_game'))
                    : t('player_board_start_game')
                }
                itemFunction={handleStartPauseContinueGame}
              />
              <MenuItem text={t('player_board_leave_game')} itemFunction={handleLeaveGame} />
              <MenuItem text={t('player_board_leave_and_observe_game')} itemFunction={handleLeaveAndObserveGame} />
              {isStarted && <MenuItem text={t('player_board_reset_game')} itemFunction={handleRestartGame} />}
              {gameInstruction && (
                <MenuItem text={t('player_board_show_instruction')} itemFunction={handleShowInstructionModalClick} />
              )}
            </DropdownMenu>
          </StyledLeaderMenu>
        )}
      </StyledWrapper>
    </>
  );
};

PlayerBar.propTypes = {
  playerName: PropTypes.string.isRequired,
  myPlayer: PropTypes.bool,
  isLeader: PropTypes.bool,
  amILeader: PropTypes.bool,
};

PlayerBar.defaultProps = {
  myPlayer: false,
  isLeader: false,
  amILeader: false,
};

export default PlayerBar;
