import React from 'react';
import PropTypes from 'prop-types';

const PayWithPaysonButton = ({ clickFn }) => (
  <button className="cursor-pointer w-48 mx-auto" type="button" onClick={clickFn}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 300 80"
      enableBackground="new 0 0 300 80"
    >
      <g>
        <path
          fill="#083153"
          d="M293,53c0,1.657-1.343,3-3,3H11c-1.657,0-3-1.343-3-3V5c0-1.657,1.343-3,3-3h279c1.657,0,3,1.343,3,3V53z"
        />
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M58.879,20.22h4.44c1.473,0,2.599,0.334,3.376,1.002c0.3,0.259,0.552,0.624,0.757,1.095   s0.307,1.082,0.307,1.832s-0.14,1.459-0.419,2.128c-0.28,0.668-0.611,1.18-0.993,1.535c-0.382,0.355-0.832,0.641-1.351,0.859   c-0.805,0.341-1.609,0.512-2.414,0.512h-2.313L59.78,33.93c-0.041,0.437-0.47,0.655-1.289,0.655h-0.696   c-0.777,0-1.166-0.218-1.166-0.655l1.351-12.891C58.034,20.493,58.334,20.22,58.879,20.22z M62.522,26.481   c0.532,0,0.996-0.153,1.391-0.46s0.594-0.791,0.594-1.453s-0.157-1.105-0.471-1.33c-0.314-0.225-0.689-0.338-1.125-0.338h-1.964   l-0.389,3.581H62.522z"
        />
        <path
          fill="#FFFFFF"
          d="M76.538,34.707c-1.296,0-2.081-0.511-2.353-1.535c-0.314,0.464-0.713,0.839-1.197,1.125   s-1.013,0.43-1.585,0.43c-1.01,0-1.814-0.385-2.415-1.156c-0.601-0.771-0.9-1.865-0.9-3.284c0-1.964,0.422-3.554,1.269-4.768   c0.846-1.214,1.937-1.821,3.274-1.821c0.887,0,1.712,0.361,2.476,1.084c0.123-0.409,0.252-0.668,0.389-0.778   c0.136-0.109,0.45-0.17,0.941-0.184h0.246c0.791,0,1.187,0.171,1.187,0.512l-0.368,3.56c-0.259,2.374-0.389,3.629-0.389,3.765   c0,0.437,0.205,0.655,0.614,0.655c0.109,0,0.231-0.021,0.368-0.061c0.136-0.041,0.211-0.062,0.225-0.062   c0.109,0,0.164,0.102,0.164,0.307s-0.048,0.587-0.143,1.146c-0.055,0.341-0.266,0.604-0.634,0.788   C77.336,34.615,76.947,34.707,76.538,34.707z M72.548,32.252c0.56,0,1.091-0.396,1.596-1.187c0.164-1.855,0.259-2.882,0.287-3.08   c0.027-0.198,0.109-0.645,0.246-1.34c-0.423-0.368-0.874-0.552-1.351-0.552c-0.696,0-1.231,0.345-1.606,1.033   c-0.375,0.689-0.563,1.716-0.563,3.08C71.157,31.57,71.62,32.252,72.548,32.252z"
        />
        <path
          fill="#FFFFFF"
          d="M79.3,24.333c0-0.354,0.437-0.532,1.31-0.532h0.655c0.668,0,1.037,0.144,1.105,0.43l0.798,3.786   c0.054,0.259,0.164,1.084,0.327,2.476h0.082c0.368-1.214,0.641-2.04,0.818-2.476l1.617-3.786c0.123-0.286,0.518-0.43,1.187-0.43   h0.491c0.791,0,1.187,0.144,1.187,0.43c0,0.028-0.035,0.157-0.103,0.389l-6.139,13.709c-0.123,0.286-0.519,0.43-1.187,0.43h-0.348   c-0.791,0-1.187-0.137-1.187-0.409c0-0.109,0.041-0.239,0.123-0.389l1.657-3.417C80.099,27.805,79.3,24.401,79.3,24.333z"
        />
        <path
          fill="#FFFFFF"
          d="M103.629,26.277l-0.512,5.361c0,0.45,0.205,0.675,0.614,0.675c0.109,0,0.232-0.021,0.368-0.061   c0.136-0.041,0.211-0.062,0.225-0.062c0.109,0,0.164,0.102,0.164,0.307s-0.048,0.587-0.144,1.146   c-0.055,0.341-0.266,0.604-0.634,0.788c-0.368,0.184-0.757,0.276-1.166,0.276c-1.651,0-2.476-0.866-2.476-2.599l0.471-4.911   c0-0.682-0.352-1.023-1.054-1.023c-0.703,0-1.32,0.417-1.852,1.249l-0.655,6.507c-0.041,0.437-0.471,0.655-1.289,0.655h-0.573   c-0.382,0-0.672-0.062-0.87-0.184c-0.198-0.123-0.297-0.273-0.297-0.45l0.696-6.712c0.054-0.532,0.082-1.016,0.082-1.453   l-0.041-1.146c0-0.218,0.225-0.396,0.675-0.532c0.45-0.136,1.016-0.205,1.699-0.205c0.259,0,0.426,0.112,0.501,0.337   c0.075,0.225,0.126,0.577,0.154,1.054c0.354-0.436,0.829-0.815,1.422-1.135s1.19-0.481,1.791-0.481   C102.729,23.678,103.629,24.544,103.629,26.277z"
        />
        <path
          fill="#FFFFFF"
          d="M105.573,30.226c0-1.787,0.396-3.294,1.187-4.522c0.41-0.627,0.993-1.122,1.75-1.484   c0.757-0.361,1.647-0.542,2.67-0.542c1.459,0,2.564,0.382,3.315,1.146c0.75,0.764,1.125,1.882,1.125,3.355   c0,2.074-0.498,3.68-1.494,4.819c-0.996,1.139-2.367,1.708-4.113,1.708c-1.46,0-2.565-0.382-3.315-1.146   C105.948,32.797,105.573,31.686,105.573,30.226z M110.258,32.313c0.709,0,1.265-0.344,1.668-1.033   c0.402-0.688,0.604-1.674,0.604-2.957c0-1.5-0.532-2.251-1.596-2.251c-0.723,0-1.286,0.345-1.688,1.033   c-0.402,0.689-0.604,1.682-0.604,2.977C108.642,31.57,109.181,32.313,110.258,32.313z"
        />
        <path
          fill="#FFFFFF"
          d="M118.647,23.801h0.491c0.682,0,1.043,0.144,1.084,0.43l0.962,6.302l2.21-6.302   c0.095-0.286,0.484-0.43,1.166-0.43h0.491c0.682,0,1.043,0.144,1.084,0.43l0.859,6.302l2.312-6.302   c0.109-0.286,0.498-0.43,1.167-0.43h0.082c0.805,0,1.207,0.164,1.207,0.491c0,0.096-0.021,0.211-0.062,0.348l-3.417,9.515   c-0.109,0.286-0.498,0.43-1.167,0.43h-1.002c-0.655,0-1.016-0.144-1.084-0.43l-0.859-5.75l-2.005,5.75   c-0.096,0.286-0.484,0.43-1.166,0.43h-1.003c-0.682,0-1.043-0.144-1.084-0.43l-1.576-9.515c-0.014-0.082-0.021-0.184-0.021-0.307   C117.358,23.979,117.802,23.801,118.647,23.801z"
        />
        <path
          fill="#FFFFFF"
          d="M138.843,23.801h0.491c0.682,0,1.043,0.144,1.084,0.43l0.962,6.302l2.21-6.302   c0.095-0.286,0.484-0.43,1.166-0.43h0.491c0.682,0,1.043,0.144,1.084,0.43l0.859,6.302l2.313-6.302   c0.108-0.286,0.497-0.43,1.166-0.43h0.082c0.804,0,1.207,0.164,1.207,0.491c0,0.096-0.02,0.211-0.061,0.348l-3.417,9.515   c-0.109,0.286-0.498,0.43-1.166,0.43h-1.003c-0.655,0-1.016-0.144-1.084-0.43l-0.859-5.75l-2.005,5.75   c-0.096,0.286-0.484,0.43-1.166,0.43h-1.003c-0.682,0-1.043-0.144-1.084-0.43l-1.576-9.515c-0.014-0.082-0.021-0.184-0.021-0.307   C137.554,23.979,137.998,23.801,138.843,23.801z"
        />
        <path
          fill="#FFFFFF"
          d="M154.682,23.801h0.572c0.381,0,0.672,0.062,0.869,0.184c0.197,0.123,0.297,0.273,0.297,0.45   c-0.504,4.666-0.756,7.066-0.756,7.203c0,0.45,0.203,0.675,0.613,0.675c0.109,0,0.232-0.021,0.367-0.061   c0.137-0.041,0.213-0.062,0.227-0.062c0.109,0,0.164,0.102,0.164,0.307s-0.049,0.587-0.145,1.146   c-0.055,0.341-0.266,0.604-0.635,0.788c-0.367,0.184-0.756,0.276-1.166,0.276c-1.65,0-2.475-0.866-2.475-2.599l0.777-7.653   C153.434,24.019,153.863,23.801,154.682,23.801z M155.438,22.082h-0.572c-0.383,0-0.672-0.062-0.869-0.184   c-0.199-0.123-0.297-0.273-0.297-0.45l0.184-1.678c0.041-0.437,0.471-0.655,1.289-0.655h0.572c0.383,0,0.672,0.061,0.871,0.184   c0.197,0.123,0.297,0.273,0.297,0.45l-0.186,1.678C156.686,21.864,156.256,22.082,155.438,22.082z"
        />
        <path
          fill="#FFFFFF"
          d="M158.773,26.236c-0.354,0-0.531-0.246-0.531-0.737c0-1.104,0.258-1.657,0.777-1.657h0.408l0.205-1.944   c0.041-0.437,0.471-0.655,1.289-0.655h0.574c0.381,0,0.672,0.062,0.869,0.184c0.197,0.123,0.297,0.273,0.297,0.45l-0.205,1.964   h1.207c0.137,0,0.225,0.031,0.266,0.092c0.041,0.061,0.063,0.242,0.063,0.542c0,0.3-0.105,0.668-0.318,1.105   c-0.211,0.437-0.5,0.655-0.869,0.655h-0.594c-0.381,3.561-0.572,5.361-0.572,5.402c0,0.45,0.205,0.675,0.613,0.675   c0.15,0,0.314-0.021,0.492-0.061c0.176-0.041,0.271-0.062,0.285-0.062c0.109,0,0.164,0.102,0.164,0.307s-0.047,0.587-0.143,1.146   c-0.055,0.341-0.266,0.604-0.635,0.788c-0.369,0.184-0.758,0.276-1.166,0.276c-1.773,0-2.66-0.907-2.66-2.721l0.594-5.75H158.773z"
        />
        <path
          fill="#FFFFFF"
          d="M171.01,32.108l0.471-4.911c0-0.682-0.367-1.023-1.105-1.023c-0.627,0-1.213,0.389-1.76,1.167l-0.695,6.589   c-0.041,0.437-0.471,0.655-1.289,0.655h-0.572c-0.383,0-0.672-0.062-0.869-0.184c-0.199-0.123-0.297-0.273-0.297-0.45l1.494-14.18   c0.039-0.437,0.469-0.655,1.289-0.655h0.572c0.381,0,0.672,0.061,0.869,0.184c0.197,0.123,0.297,0.273,0.297,0.45l-0.553,5.32   c0.369-0.396,0.826-0.726,1.371-0.992s1.092-0.399,1.637-0.399c1.801,0,2.701,0.866,2.701,2.599l-0.512,5.361   c0,0.45,0.205,0.675,0.615,0.675c0.107,0,0.23-0.021,0.367-0.061c0.137-0.041,0.211-0.062,0.225-0.062   c0.109,0,0.164,0.102,0.164,0.307s-0.047,0.587-0.143,1.146c-0.055,0.341-0.266,0.604-0.635,0.788   c-0.369,0.184-0.758,0.276-1.166,0.276C171.836,34.707,171.01,33.841,171.01,32.108z"
        />
        <path
          fill="#FFFFFF"
          d="M183.859,20.22h4.441c1.473,0,2.598,0.334,3.375,1.002c0.301,0.259,0.553,0.624,0.758,1.095   s0.307,1.082,0.307,1.832s-0.141,1.459-0.42,2.128s-0.609,1.18-0.992,1.535c-0.381,0.355-0.832,0.641-1.35,0.859   c-0.805,0.341-1.609,0.512-2.414,0.512h-2.313l-0.492,4.747c-0.041,0.437-0.471,0.655-1.289,0.655h-0.695   c-0.777,0-1.166-0.218-1.166-0.655l1.35-12.891C183.014,20.493,183.314,20.22,183.859,20.22z M187.502,26.481   c0.533,0,0.996-0.153,1.393-0.46c0.395-0.307,0.592-0.791,0.592-1.453s-0.156-1.105-0.471-1.33   c-0.313-0.225-0.688-0.338-1.125-0.338h-1.963l-0.391,3.581H187.502z"
        />
        <path
          fill="#FFFFFF"
          d="M201.52,34.707c-1.297,0-2.082-0.511-2.354-1.535c-0.314,0.464-0.713,0.839-1.197,1.125   s-1.014,0.43-1.586,0.43c-1.01,0-1.814-0.385-2.414-1.156c-0.602-0.771-0.9-1.865-0.9-3.284c0-1.964,0.422-3.554,1.268-4.768   c0.846-1.214,1.938-1.821,3.273-1.821c0.887,0,1.713,0.361,2.477,1.084c0.123-0.409,0.252-0.668,0.389-0.778   c0.137-0.109,0.451-0.17,0.941-0.184h0.246c0.791,0,1.186,0.171,1.186,0.512l-0.367,3.56c-0.26,2.374-0.389,3.629-0.389,3.765   c0,0.437,0.205,0.655,0.613,0.655c0.109,0,0.232-0.021,0.369-0.061c0.135-0.041,0.211-0.062,0.225-0.062   c0.109,0,0.164,0.102,0.164,0.307s-0.049,0.587-0.145,1.146c-0.055,0.341-0.266,0.604-0.633,0.788   C202.316,34.615,201.928,34.707,201.52,34.707z M197.529,32.252c0.559,0,1.09-0.396,1.596-1.187   c0.164-1.855,0.258-2.882,0.285-3.08s0.109-0.645,0.246-1.34c-0.422-0.368-0.873-0.552-1.35-0.552   c-0.697,0-1.232,0.345-1.607,1.033c-0.375,0.689-0.563,1.716-0.563,3.08C196.137,31.57,196.602,32.252,197.529,32.252z"
        />
        <path
          fill="#FFFFFF"
          d="M204.281,24.333c0-0.354,0.436-0.532,1.309-0.532h0.654c0.668,0,1.037,0.144,1.105,0.43l0.799,3.786   c0.055,0.259,0.162,1.084,0.326,2.476h0.082c0.369-1.214,0.641-2.04,0.818-2.476l1.617-3.786c0.123-0.286,0.518-0.43,1.188-0.43   h0.49c0.791,0,1.188,0.144,1.188,0.43c0,0.028-0.035,0.157-0.104,0.389l-6.139,13.709c-0.123,0.286-0.518,0.43-1.186,0.43h-0.348   c-0.793,0-1.188-0.137-1.188-0.409c0-0.109,0.041-0.239,0.123-0.389l1.656-3.417C205.078,27.805,204.281,24.401,204.281,24.333z"
        />
        <path
          fill="#FFFFFF"
          d="M218.705,26.052c-0.777,0-1.166,0.273-1.166,0.818c0,0.287,0.191,0.532,0.574,0.737   c0.381,0.205,0.797,0.378,1.248,0.521c0.449,0.144,0.865,0.434,1.248,0.87c0.381,0.437,0.572,1.003,0.572,1.699   c0,1.255-0.389,2.237-1.166,2.946c-0.777,0.709-1.795,1.064-3.049,1.064c-0.52,0-1.023-0.055-1.514-0.164   c-0.955-0.218-1.434-0.58-1.434-1.084c0-1.228,0.15-1.842,0.451-1.842c0.027,0,0.148,0.062,0.367,0.185   c0.627,0.341,1.256,0.511,1.883,0.511c0.9,0,1.352-0.341,1.352-1.023c0-0.287-0.127-0.522-0.379-0.706   c-0.254-0.184-0.557-0.337-0.91-0.46c-0.355-0.123-0.711-0.269-1.064-0.44c-0.355-0.17-0.658-0.443-0.91-0.818   c-0.254-0.375-0.379-0.842-0.379-1.402c0-1.214,0.375-2.148,1.125-2.803s1.725-0.982,2.926-0.982c0.736,0,1.418,0.099,2.047,0.296   c0.627,0.198,0.941,0.515,0.941,0.952c0,0.218-0.133,0.529-0.4,0.931c-0.266,0.402-0.494,0.604-0.686,0.604   c-0.027,0-0.211-0.068-0.551-0.205C219.49,26.12,219.115,26.052,218.705,26.052z"
        />
        <path
          fill="#FFFFFF"
          d="M222.695,30.226c0-1.787,0.396-3.294,1.188-4.522c0.41-0.627,0.992-1.122,1.75-1.484   c0.756-0.361,1.646-0.542,2.67-0.542c1.459,0,2.564,0.382,3.314,1.146s1.125,1.882,1.125,3.355c0,2.074-0.498,3.68-1.492,4.819   c-0.996,1.139-2.367,1.708-4.113,1.708c-1.461,0-2.564-0.382-3.314-1.146C223.07,32.797,222.695,31.686,222.695,30.226z    M227.383,32.313c0.709,0,1.264-0.344,1.666-1.033c0.402-0.688,0.605-1.674,0.605-2.957c0-1.5-0.533-2.251-1.598-2.251   c-0.723,0-1.285,0.345-1.688,1.033c-0.402,0.689-0.604,1.682-0.604,2.977C225.766,31.57,226.305,32.313,227.383,32.313z"
        />
        <path
          fill="#FFFFFF"
          d="M243.793,26.277l-0.512,5.361c0,0.45,0.203,0.675,0.613,0.675c0.109,0,0.232-0.021,0.369-0.061   c0.135-0.041,0.211-0.062,0.225-0.062c0.109,0,0.164,0.102,0.164,0.307s-0.049,0.587-0.145,1.146   c-0.055,0.341-0.266,0.604-0.633,0.788c-0.369,0.184-0.758,0.276-1.168,0.276c-1.65,0-2.475-0.866-2.475-2.599l0.471-4.911   c0-0.682-0.352-1.023-1.055-1.023s-1.32,0.417-1.852,1.249l-0.654,6.507c-0.041,0.437-0.471,0.655-1.289,0.655h-0.574   c-0.381,0-0.672-0.062-0.869-0.184c-0.197-0.123-0.297-0.273-0.297-0.45l0.695-6.712c0.055-0.532,0.082-1.016,0.082-1.453   l-0.041-1.146c0-0.218,0.227-0.396,0.676-0.532c0.451-0.136,1.016-0.205,1.699-0.205c0.258,0,0.426,0.112,0.5,0.337   c0.076,0.225,0.127,0.577,0.154,1.054c0.354-0.436,0.828-0.815,1.422-1.135s1.189-0.481,1.791-0.481   C242.893,23.678,243.793,24.544,243.793,26.277z"
        />
      </g>
      <g>
        <g>
          <g>
            <g>
              <path
                fill="#F78E1E"
                d="M17.424,63.236c0.527,0.432,0.604,1.212,0.175,1.742c-0.434,0.529-1.215,0.607-1.746,0.177      c-0.531-0.433-0.605-1.214-0.176-1.746C16.112,62.882,16.891,62.8,17.424,63.236z"
              />
              <path
                fill="#F78E1E"
                d="M25.217,60.398c0.667,0.545,0.766,1.527,0.221,2.199c-0.546,0.665-1.529,0.766-2.197,0.22      c-0.666-0.546-0.769-1.529-0.221-2.198C23.566,59.952,24.545,59.854,25.217,60.398z"
              />
              <path
                fill="#F78E1E"
                d="M20.644,69.25c0.924,0.756,1.063,2.117,0.307,3.044c-0.757,0.929-2.117,1.062-3.044,0.307      c-0.923-0.753-1.062-2.115-0.307-3.039C18.356,68.634,19.717,68.498,20.644,69.25z"
              />
            </g>
            <g>
              <path
                fill="#00245D"
                d="M38.188,69.539l3.07,7.206h-1.686l-0.662-1.715h-3.134l-0.645,1.715h-1.604l3.053-7.206H38.188z       M36.271,73.744h2.146l-1.094-2.77L36.271,73.744z"
              />
              <path
                fill="#00245D"
                d="M41.876,69.539l1.773,2.75l1.808-2.75h1.761l-2.784,4.025v3.181h-1.517v-3.181l-2.751-4.025H41.876z"
              />
              <path
                fill="#00245D"
                d="M50.33,70.824c-0.792,0-1.164,0.282-1.164,0.944c0,0.521,0.32,0.782,0.953,0.782h2.007      c1.608,0,2.298,0.531,2.298,2.038c0,1.514-0.813,2.156-2.519,2.156h-4.036v-1.288h3.796c0.803,0,1.174-0.186,1.174-0.808      c0-0.644-0.34-0.832-1.074-0.832h-1.786c-1.647,0-2.399-0.626-2.399-2.068c0-1.565,0.814-2.21,2.529-2.21h3.965v1.285H50.33      L50.33,70.824z"
              />
              <path
                fill="#00245D"
                d="M58.862,69.4c2.447,0,3.593,1.191,3.593,3.742c0,2.55-1.146,3.753-3.593,3.753      c-2.478,0-3.592-1.203-3.592-3.823C55.271,70.592,56.445,69.4,58.862,69.4z M56.927,73.082c0,1.758,0.58,2.568,1.936,2.568      c1.336,0,1.947-0.811,1.947-2.508c0-1.695-0.611-2.507-1.947-2.507C57.562,70.636,56.927,71.447,56.927,73.082z"
              />
              <path
                fill="#00245D"
                d="M65.408,69.539l2.948,5.179l-0.071-5.179h1.546v7.206h-1.756l-3.103-5.446l0.07,5.446h-1.554v-7.206      H65.408z"
              />
            </g>
          </g>
          <g>
            <path
              fill="#F78E1E"
              d="M28.87,66.402c-2.904,0-5.258,2.356-5.258,5.262c0,2.266,1.44,4.202,3.456,4.938v-7.031h3.292     c0.982,0,1.676,0.077,2.115,0.359c0.645,0.412,0.995,1.095,0.995,1.988c0,0.861-0.27,1.477-0.833,1.875     c-0.613,0.434-1.315,0.462-2.218,0.462h-1.838v2.653c0.097,0.006,0.191,0.007,0.288,0.007c2.901,0,5.258-2.352,5.258-5.257     C34.128,68.759,31.771,66.402,28.87,66.402z"
            />
            <path
              fill="#F78E1E"
              d="M31.785,71.919c0-0.802-0.442-1.064-1.448-1.064h-1.755v2.125h1.848     C31.343,72.979,31.785,72.642,31.785,71.919z"
            />
          </g>
        </g>
      </g>
    </svg>
  </button>
);

PayWithPaysonButton.propTypes = {
  clickFn: PropTypes.func.isRequired,
};

export default PayWithPaysonButton;
