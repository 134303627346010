/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import MultipleSelect from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { createGamesPackage, findGamesPackages } from 'features/admin/packages/PackagesSlice';
import Modal, {
  StyledHeading,
  StyledModalContentWrapper,
  StyledButtonsWrapper,
} from 'components/molecules/Modal/Modal';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import { StyledFieldWrapper, StyledErrorMessage } from 'templates/AuthTemplate';
import ModalButton from 'components/atoms/ModalButton/ModalButton';

const schema = yup.object().shape({
  name: yup.string().required(),
  shortName: yup.string().required(),
  description: yup.string().notRequired(),
  unitPrice: yup.number().positive().required(),
  taxRate: yup.number().min(0).max(100),
  gamesCount: yup.number().integer().required(),
  subscriptionValidityPeriod: yup.number().integer().required(),
});

const NewGamesPackageModal = ({ open, setOpen, gameTypes }) => {
  const [gameTypesIds, setGameTypesIds] = useState([]);

  const options = gameTypes.map((gameType) => {
    const { _id, name } = gameType;
    return { value: _id, label: name };
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (dataFromForm) => {
    const data = {
      ...dataFromForm,
      gameTypes: gameTypesIds,
    };
    dispatch(createGamesPackage(data));
    dispatch(findGamesPackages());
    setOpen(false);
  };

  const handleChangeMultiSelect = (selectedOptions) => {
    setGameTypesIds(selectedOptions.map((selectedOpiton) => selectedOpiton.value));
  };

  return (
    <Modal open={open} close={setOpen} scroll>
      <form onSubmit={handleSubmit(onSubmit)} method="POST">
        <StyledModalContentWrapper>
          <StyledHeading as="h3">{t('new_package_modal_heading')}</StyledHeading>

          <StyledFieldWrapper>
            <Label htmlFor="gameTypes">{t('new_package_modal_multipleselect_available_game_types')}</Label>
            <MultipleSelect
              name="gameTypes"
              options={options}
              isMulti
              onChange={(selectedOptions) => handleChangeMultiSelect(selectedOptions)}
            />
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="name">{t('new_package_modal_input_name')}</Label>
            <Input type="text" {...register('name')} placeholder={t('new_package_modal_input_name_placeholder')} />
            <StyledErrorMessage>{errors.name?.message}</StyledErrorMessage>
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="shortName">{t('new_package_modal_input_short_name')}</Label>
            <Input
              type="text"
              {...register('shortName')}
              placeholder={t('new_package_modal_input_short_name_placeholder')}
            />
            <StyledErrorMessage>{errors.shortName?.message}</StyledErrorMessage>
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="description">{t('new_package_modal_input_description')}</Label>
            <Input
              type="text"
              {...register('description')}
              placeholder={t('new_package_modal_input_description_placeholder')}
            />
            <StyledErrorMessage>{errors.description?.message}</StyledErrorMessage>
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="unitPrice">{t('new_package_modal_input_unit_price')}</Label>
            <Input
              type="number"
              {...register('unitPrice')}
              placeholder={t('new_package_modal_input_unit_price_placeholder')}
            />
            <StyledErrorMessage>{errors.unitPrice?.message}</StyledErrorMessage>
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="taxRate">{t('new_package_modal_input_tax_rate')}</Label>
            <Input
              type="number"
              {...register('taxRate')}
              placeholder={t('new_package_modal_input_tax_rate_placeholder')}
            />
            <StyledErrorMessage>{errors.taxRate?.message}</StyledErrorMessage>
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="gamesCount">{t('new_package_modal_input_number_of_games_in_package')}</Label>
            <Input
              type="number"
              {...register('gamesCount')}
              placeholder={t('new_package_modal_input_number_of_games_in_package_placeholder')}
            />
            <StyledErrorMessage>{errors.gamesCount?.message}</StyledErrorMessage>
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="subscriptionValidityPeriod">{t('new_package_modal_input_validity_period')}</Label>
            <Input
              type="number"
              {...register('subscriptionValidityPeriod')}
              placeholder={t('new_package_modal_input_validity_period_placeholder')}
            />
            <StyledErrorMessage>{errors.subscriptionValidityPeriod?.message}</StyledErrorMessage>
          </StyledFieldWrapper>
        </StyledModalContentWrapper>
        <StyledButtonsWrapper>
          <ModalButton type="submit" color="blue">
            {t('new_package_modal_button_new')}
          </ModalButton>
          <ModalButton type="button" onClick={() => setOpen(false)}>
            {t('new_package_modal_button_cancel')}
          </ModalButton>
        </StyledButtonsWrapper>
      </form>
    </Modal>
  );
};

NewGamesPackageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  gameTypes: PropTypes.array,
};

NewGamesPackageModal.defaultProps = {
  gameTypes: [],
};

export default NewGamesPackageModal;
