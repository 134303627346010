import axios from 'axios';
import { config } from 'constants.js';
import authHeader from 'features/auth/authHeader';

const create = ({ name, initialDecks, category, instructionLink, availableLanguages }) =>
  axios.post(
    `${config.url.API_URL}/game-types`,
    {
      name,
      initialDecks,
      category,
      instructionLink,
      availableLanguages,
    },
    { headers: authHeader() },
  );

const findOneById = ({ gameTypeId }) =>
  axios.get(`${config.url.API_URL}/game-types/${gameTypeId}`, { headers: authHeader() });

const find = () => axios.get(`${config.url.API_URL}/game-types`, { headers: authHeader() });

const update = ({ _id, name, initialDecks, category, instructionLink, availableLanguages }) =>
  axios.patch(
    `${config.url.API_URL}/game-types/${_id}`,
    {
      name,
      initialDecks,
      category,
      instructionLink,
      availableLanguages,
    },
    { headers: authHeader() },
  );

const remove = ({ gameTypeId }) =>
  axios.delete(`${config.url.API_URL}/game-types/${gameTypeId}`, { headers: authHeader() });

export default {
  create,
  findOneById,
  find,
  update,
  remove,
};
