/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CardsAPI from './CardsAPI';

export const createCard = createAsyncThunk('cards/create', async ({ name, translations, cardType }, thunkAPI) => {
  try {
    const response = await CardsAPI.create({
      name,
      translations,
      cardType,
    });
    const { data } = response;
    if (response.status === 201) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const updateCard = createAsyncThunk(
  'cards/update',
  async ({ _id, name, translations, cardType, status }, thunkAPI) => {
    try {
      const response = await CardsAPI.update({
        _id,
        name,
        translations,
        cardType,
        status,
      });
      const { data } = response;
      if (response.status === 200) {
        return data;
      }
      return thunkAPI.rejectWithValue(data);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const findCardById = createAsyncThunk('cards/findById', async ({ id }, thunkAPI) => {
  try {
    const response = await CardsAPI.findOneById({ id });
    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const findCards = createAsyncThunk('cards/find', async (thunkAPI) => {
  try {
    const response = await CardsAPI.find();

    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const deleteCard = createAsyncThunk('cards/delete', async ({ id }, thunkAPI) => {
  try {
    const response = await CardsAPI.remove({ cardId: id });

    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const cardsSlice = createSlice({
  name: 'cards',
  initialState: {
    items: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
    clearItems: (state) => {
      state.items = [];
      return state;
    },
  },
  extraReducers: {
    [createCard.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = [...state.items, payload];
    },
    [createCard.pending]: (state) => {
      state.isFetching = true;
    },
    [createCard.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [updateCard.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = state.items.map((item) => (item._id === payload._id ? payload : item));
    },
    [updateCard.pending]: (state) => {
      state.isFetching = true;
    },
    [updateCard.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [findCardById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findCardById.pending]: (state) => {
      state.isFetching = true;
    },
    [findCardById.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [findCards.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findCards.pending]: (state) => {
      state.isFetching = true;
    },
    [findCards.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [deleteCard.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = state.items.filter((item) => item._id !== payload._id);
    },
    [deleteCard.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteCard.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
  },
});

export const { clearState, clearItems } = cardsSlice.actions;

export const cardsSelector = (state) => state.cards;
