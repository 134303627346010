import axios from 'axios';
import { config } from 'constants.js';
import authHeader from 'features/auth/authHeader';

const create = ({
  name,
  status,
  frontCardColor,
  textColor,
  backCardColorFrom,
  backCardColorVia,
  backCardColorTo,
  image,
}) =>
  axios.post(
    `${config.url.API_URL}/card-types`,
    {
      name,
      status,
      frontCardColor,
      textColor,
      backCardColorFrom,
      backCardColorVia,
      backCardColorTo,
      image,
    },
    { headers: authHeader() },
  );

const findOneById = ({ cardTypeId }) => axios.get(`${config.url.API_URL}/card-types/${cardTypeId}`);

const find = () => axios.get(`${config.url.API_URL}/card-types`);

const update = ({
  _id,
  name,
  status,
  frontCardColor,
  textColor,
  backCardColorFrom,
  backCardColorVia,
  backCardColorTo,
  image,
}) =>
  axios.patch(
    `${config.url.API_URL}/card-types/${_id}`,
    {
      name,
      status,
      frontCardColor,
      textColor,
      backCardColorFrom,
      backCardColorVia,
      backCardColorTo,
      image,
    },
    { headers: authHeader() },
  );

const remove = ({ cardTypeId }) =>
  axios.delete(`${config.url.API_URL}/card-types/${cardTypeId}`, { headers: authHeader() });

export default {
  create,
  findOneById,
  find,
  update,
  remove,
};
