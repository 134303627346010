/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GameTypesAPI from './GameTypesAPI';

export const createGameType = createAsyncThunk(
  'gameTypes/create',
  async ({ name, initialDecks, category, instructionLink, availableLanguages }, thunkAPI) => {
    try {
      const response = await GameTypesAPI.create({
        name,
        initialDecks,
        category,
        instructionLink,
        availableLanguages,
      });
      const { data } = response;
      if (response.status === 201) {
        return data;
      }
      return thunkAPI.rejectWithValue(data);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const updateGameType = createAsyncThunk(
  'gameTypes/update',
  async ({ _id, name, initialDecks, category, instructionLink, availableLanguages }, thunkAPI) => {
    try {
      const response = await GameTypesAPI.update({
        _id,
        name,
        initialDecks,
        category,
        instructionLink,
        availableLanguages,
      });
      const { data } = response;
      if (response.status === 200) {
        return data;
      }
      return thunkAPI.rejectWithValue(data);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const findGameTypeById = createAsyncThunk('gameTypes/findById', async ({ id }, thunkAPI) => {
  try {
    const response = await GameTypesAPI.findOneById({ id });
    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const findGameTypes = createAsyncThunk('gameTypes/find', async (thunkAPI) => {
  try {
    const response = await GameTypesAPI.find();

    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const deleteGameType = createAsyncThunk('gameTypes/delete', async ({ id }, thunkAPI) => {
  try {
    const response = await GameTypesAPI.remove({ gameTypeId: id });

    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const gameTypesSlice = createSlice({
  name: 'gameTypes',
  initialState: {
    items: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createGameType.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = [...state.items, payload];
    },
    [createGameType.pending]: (state) => {
      state.isFetching = true;
    },
    [createGameType.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [updateGameType.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = state.items.map((item) => (item._id === payload._id ? payload : item));
    },
    [updateGameType.pending]: (state) => {
      state.isFetching = true;
    },
    [updateGameType.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [findGameTypeById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findGameTypeById.pending]: (state) => {
      state.isFetching = true;
    },
    [findGameTypeById.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [findGameTypes.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findGameTypes.pending]: (state) => {
      state.isFetching = true;
    },
    [findGameTypes.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [deleteGameType.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = state.items.filter((item) => item._id !== payload._id);
    },
    [deleteGameType.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteGameType.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
  },
});

export const { clearState } = gameTypesSlice.actions;

export const gameTypesSelector = (state) => state.gameTypes;
