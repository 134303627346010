import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import PlayerBar from 'components/molecules/PlayerBar/PlayerBar';
import Card from 'components/molecules/Card/Card';
import GiftCard from 'components/molecules/Card/GiftCard/GiftCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeBiggerPlayerBoardModal,
  openBiggerPlayerBoardModal,
  playerBoardSelector,
} from 'features/game/PlayerBoardSlice';
import BiggerPlayerBoardModal from '../BiggerPlayerBoardModal/BiggerPlayerBoardModal';

const StyledWrapper = tw.div`
    mx-auto
    flex
    flex-col
    h-full
    bg-white
    w-full
    overflow-y-auto lg:overflow-visible
`;

const CardsWrapper = styled.div(({ overflow, borderBottom }) => [
  tw`
    flex-row
    grid-cols-6
    gap-1
    lg:h-1/2
    px-1
    py-1
    lg:grid
  `,
  overflow === 'scroll' ? tw`overflow-visible lg:overflow-hidden` : tw`overflow-visible`,
  borderBottom && tw`border-b-2 border-gray-300 border-dashed`,
]);

const StyledNameArea = tw.div`
    flex-none
    h-8
`;

const PlayerBoard = ({ player }) => {
  const dispatch = useDispatch();

  const { _id: ownerId, username, handCards, giftCards, isLeader } = player;

  const playerBoard = useSelector(playerBoardSelector);
  const { isBiggerPlayerBoardModalOpen } = playerBoard;

  const handleShowBiggerPlayerBoardClick = () => {
    dispatch(openBiggerPlayerBoardModal({ playerToShowInBiggerModalId: ownerId }));
  };

  const closeBiggerPlayerBoardModalFn = () => {
    dispatch(closeBiggerPlayerBoardModal());
  };

  return (
    <>
      <BiggerPlayerBoardModal open={isBiggerPlayerBoardModalOpen} close={closeBiggerPlayerBoardModalFn} />
      <StyledWrapper>
        <CardsWrapper borderBottom>
          {handCards.map(({ card, isShowed }) => (
            <Card card={card} visible={isShowed} />
          ))}
        </CardsWrapper>
        <CardsWrapper overflow="scroll">
          {giftCards.map(({ card, from }) => (
            <GiftCard card={card} actualOwnerId={ownerId} from={from} />
          ))}
        </CardsWrapper>
        <StyledNameArea onClick={handleShowBiggerPlayerBoardClick}>
          <PlayerBar playerName={username} isLeader={isLeader} />
        </StyledNameArea>
      </StyledWrapper>
    </>
  );
};

PlayerBoard.propTypes = {
  player: PropTypes.shape({
    _id: PropTypes.string,
    username: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    handCards: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    giftCards: PropTypes.array,
    isLeader: PropTypes.bool,
    amILeader: PropTypes.bool,
  }).isRequired,
};

export default PlayerBoard;
