/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import tw from 'twin.macro';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { createCardType } from 'features/admin/cardTypes/CardTypesSlice';
import Modal, {
  StyledHeading,
  StyledModalContentWrapper,
  StyledButtonsWrapper,
} from 'components/molecules/Modal/Modal';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import { StyledFieldWrapper, StyledErrorMessage } from 'templates/AuthTemplate';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import Paragraph from 'components/atoms/Paragraph';
import ImageUploadBox from 'components/atoms/ImageUploadBox/ImageUploadBox';
import ColorSelect from 'components/atoms/Select/ColorSelect';

const StyledParagraph = tw(Paragraph)`
  mt-4
  mb-2
`;

const schema = yup.object().shape({
  name: yup.string().required(),
});

const NewCardTypeModal = ({ open, setOpen }) => {
  const [frontCardColor, setFrontCardColor] = useState('green-400');
  const [textColor, setTextColor] = useState('gray-900');
  const [backCardColorFrom, setBackCardColorFrom] = useState(null);
  const [backCardColorTo, setBackCardColorTo] = useState(null);
  const [backCardColorVia, setBackCardColorVia] = useState(null);
  const [image, setImage] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (dataFromForm) => {
    const data = {
      ...dataFromForm,
      frontCardColor,
      textColor,
      backCardColorFrom,
      backCardColorTo,
      backCardColorVia,
      image,
    };
    dispatch(createCardType(data));
    setOpen(false);
  };

  return (
    <Modal open={open} close={setOpen} scroll>
      <form onSubmit={handleSubmit(onSubmit)} method="POST">
        <StyledModalContentWrapper>
          <StyledHeading as="h3">{t('new_card_type_modal_heading')}</StyledHeading>
          <StyledFieldWrapper>
            <Label htmlFor="name">{t('card_type_modal_input_name')}</Label>
            <Input type="text" {...register('name')} placeholder={t('card_type_modal_input_name_placeholder')} />
            <StyledErrorMessage>{errors.name?.message}</StyledErrorMessage>
          </StyledFieldWrapper>

          <StyledParagraph>{t('card_type_modal_paragraph_card_front')}</StyledParagraph>
          <StyledFieldWrapper>
            <Label htmlFor="frontCardColor">{t('card_type_modal_select_front_card_background_color')}</Label>
            <ColorSelect name="frontCardColor" selectedColor={frontCardColor} setSelectedColor={setFrontCardColor} />
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="textColor">{t('card_type_modal_select_front_card_text_color')}</Label>
            <ColorSelect name="textColor" selectedColor={textColor} setSelectedColor={setTextColor} />
          </StyledFieldWrapper>

          <StyledParagraph>{t('card_type_modal_paragraph_card_back')}</StyledParagraph>
          <StyledFieldWrapper>
            <Label htmlFor="backCardColorFrom">{t('card_type_modal_select_back_card_color_from')}</Label>
            <ColorSelect
              name="backCardColorFrom"
              selectedColor={backCardColorFrom}
              setSelectedColor={setBackCardColorFrom}
            />
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="backCardColorTo">{t('card_type_modal_select_back_card_color_to')}</Label>
            <ColorSelect name="backCardColorTo" selectedColor={backCardColorTo} setSelectedColor={setBackCardColorTo} />
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Label htmlFor="backCardColorVia">{t('card_type_modal_select_back_card_color_via')}</Label>
            <ColorSelect
              name="backCardColorVia"
              selectedColor={backCardColorVia}
              setSelectedColor={setBackCardColorVia}
            />
          </StyledFieldWrapper>

          <Label htmlFor="image-uploader">{t('card_type_modal_image_upload')}</Label>
          <ImageUploadBox image={image} setImage={setImage} />
        </StyledModalContentWrapper>
        <StyledButtonsWrapper>
          <ModalButton type="submit" color="blue">
            {t('card_type_modal_button_new_card_type')}
          </ModalButton>
          <ModalButton type="button" onClick={() => setOpen(false)}>
            {t('card_type_modal_button_cancel')}
          </ModalButton>
        </StyledButtonsWrapper>
      </form>
    </Modal>
  );
};

NewCardTypeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default NewCardTypeModal;
