import React, { useContext, useState } from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import SocketContext from 'socket/socket';
import Paragraph from 'components/atoms/Paragraph';
import Button from 'components/atoms/Button';
import DeleteGameModal from 'features/leader/gamesList/DeleteGameModal/DeleteGameModal';
import { SingleItemWrapper } from 'templates/AdminTemplate';
import { XIcon, LinkIcon, PlayIcon, PauseIcon } from '@heroicons/react/solid';
import DropdownMenu from 'components/organisms/DropdownMenu/DropdownMenu';
import MenuItem from 'components/organisms/DropdownMenu/MenuItem';

const StyledWrapper = tw.div`
  leading-8
  block
`;

const StyledColumnWrapper = styled.div(({ center }) => [
  tw`
        flex
        flex-row
        w-1/3
    `,
  center && tw`text-center`,
]);

const StyledNameColumnWrapper = tw.div`
    flex
    flex-row
    w-1/6
`;

const StyledOptionsColumnWrapper = tw.div`
    flex
    flex-row
    w-1/2
`;

const StyledDetailsGroupWrapper = tw.div`
    w-1/2
`;

const StyledGameOptionsWrapper = tw.div`
    flex
    flex-row
`;

const StyledButton = styled(Button)(({ color }) => [
  tw`
    2xl:w-32
    w-24
    border-0
    py-3
    px-3
    mx-1
    text-xs
  `,
  color === 'red' && tw`bg-red-500 hover:bg-red-600`,
  color === 'blue' && tw`bg-blue-500 hover:bg-blue-600`,
  color === 'gray' && tw`bg-gray-500 hover:bg-gray-600`,
  color === 'indigo' && tw`bg-indigo-500 hover:bg-indigo-600`,
  color === 'purple' && tw`bg-purple-500 hover:bg-purple-600`,
  color === 'green' && tw`bg-green-500 hover:bg-green-600`,
  color === 'yellow' && tw`bg-yellow-500 hover:bg-yellow-600`,
  color === 'orange' && tw`bg-yellow-700 hover:bg-yellow-800`,
]);

const SingleGameItem = ({ item, index }) => {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);

  const { _id, customId, createdAt, validFor, gameType, firstLanguage, secondLanguage, isStarted, isPaused } = item;

  const { name: firstLanguageName } = firstLanguage || {};
  const { name: secondLanguageName } = secondLanguage || {};
  const { name: gameTypeName } = gameType || {};

  const [openDeleteGameModal, setOpenDeleteGameModal] = useState(false);

  const handleCopyLink = () => {
    const isCopied = copy(`${window.location.origin}/join-game/${customId}`);
    if (isCopied) {
      toast.success('Link Copied To Clipboard');
    } else {
      toast.error('Link Copy Error');
    }
  };

  const handleStartGame = () => {
    socket.emit('startGame', { gameId: _id });
  };

  const handlePauseGame = () => {
    if (isStarted && !isPaused) {
      socket.emit('pauseGame', { gameId: _id });
    }
  };

  const handleContinueGame = () => {
    if (isStarted && isPaused) {
      socket.emit('continueGame', { gameId: _id });
    }
  };

  const handleJoinBoardAsLeader = () => {
    socket.emit('joinGameAsLeader', customId);
  };

  const handleJoinBoardAsFacilitatior = () => {
    window.open(`/observing-game/${customId}`, `Observing GameId: ${customId}`);
  };

  return (
    <StyledWrapper>
      {openDeleteGameModal && (
        <DeleteGameModal open={openDeleteGameModal} setOpen={setOpenDeleteGameModal} id={_id} customId={customId} />
      )}
      <SingleItemWrapper>
        <StyledNameColumnWrapper>
          <div>
            <Paragraph>
              {index + 1}. {t('leader_game_list_page_td_id')}: {customId}
            </Paragraph>
            {!isStarted && (
              <p className="ml-4 text-purple-500 text-sm">{t('leader_game_list_page_td_status_created')}</p>
            )}
            {isStarted && isPaused && (
              <p className="ml-4 text-yellow-500 text-sm">{t('leader_game_list_page_td_status_paused')}</p>
            )}
            {isStarted && !isPaused && (
              <p className="ml-4 text-green-500 text-sm">{t('leader_game_list_page_td_status_active')}</p>
            )}
          </div>
        </StyledNameColumnWrapper>

        <StyledColumnWrapper>
          <StyledDetailsGroupWrapper>
            <Paragraph textXs>
              {t('leader_game_list_page_td_type')}: <strong>{gameTypeName || 'None'}</strong>
            </Paragraph>
            <Paragraph textXs>
              {t('leader_game_list_page_td_created_at')}: <strong>{new Date(createdAt).toLocaleDateString()}</strong>{' '}
            </Paragraph>
            <Paragraph textXs>
              {t('leader_game_list_page_td_valid_for')}: <strong>{new Date(validFor).toLocaleDateString()}</strong>{' '}
            </Paragraph>
          </StyledDetailsGroupWrapper>
          <StyledDetailsGroupWrapper>
            <Paragraph textXs>
              {t('leader_game_list_page_td_language_1')}: <strong>{firstLanguageName || 'None'}</strong>
            </Paragraph>
            <Paragraph textXs>
              {t('leader_game_list_page_td_language_2')}: <strong>{secondLanguageName || 'None'}</strong>
            </Paragraph>
          </StyledDetailsGroupWrapper>
        </StyledColumnWrapper>

        <StyledOptionsColumnWrapper>
          <StyledGameOptionsWrapper>
            <StyledButton color="gray" onClick={handleCopyLink}>
              <LinkIcon className="w-4 h-4 mr-1" /> {t('leader_game_list_page_td_button_copy')}
            </StyledButton>
            <DropdownMenu buttonText={t('leader_game_list_page_td_button_menu')} buttonColor="blue">
              <MenuItem
                text={t('leader_game_list_page_td_button_menu_item_leader_as_player')}
                itemFunction={handleJoinBoardAsLeader}
              />
              <MenuItem
                text={t('leader_game_list_page_td_button_menu_item_leader_as_facilitator')}
                itemFunction={handleJoinBoardAsFacilitatior}
              />
            </DropdownMenu>
            {!isStarted && (
              <StyledButton color="purple" onClick={handleStartGame}>
                <PlayIcon className="w-3 mr-1" /> {t('leader_game_list_page_td_button_start_game')}
              </StyledButton>
            )}
            {isStarted && !isPaused && (
              <StyledButton color="yellow" onClick={handlePauseGame}>
                <PauseIcon className="w-3 mr-1" /> {t('leader_game_list_page_td_button_pause_game')}
              </StyledButton>
            )}
            {isStarted && isPaused && (
              <StyledButton color="green" onClick={handleContinueGame}>
                <PlayIcon className="w-3 mr-1" /> {t('leader_game_list_page_td_button_continue_game')}
              </StyledButton>
            )}

            <StyledButton color="red" onClick={() => setOpenDeleteGameModal(true)}>
              <XIcon className="w-4 h-4 mr-1" /> {t('leader_game_list_page_td_button_delete_game')}
            </StyledButton>
          </StyledGameOptionsWrapper>
        </StyledOptionsColumnWrapper>
      </SingleItemWrapper>
    </StyledWrapper>
  );
};

SingleGameItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default SingleGameItem;
