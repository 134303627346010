import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';

const StyledWrapper = tw.div`
  w-full
  flex
  items-center
`;

const StyledCardWrapper = tw.div`
    inline-block
    w-6
    items-center
    border
    border-gray-800
    mx-auto
`;

const StyledImage = tw.div`
    bg-center
    bg-cover
    bg-no-repeat

`;

const CardBackExample = ({ colorFrom, colorVia, colorTo, image }) => (
  <StyledWrapper>
    <StyledCardWrapper>
      {image && <StyledImage style={{ backgroundImage: `url(${image})` }}>&nbsp;</StyledImage>}
      {!image && colorFrom && !colorVia && !colorTo && (
        <div className={`bg-${colorFrom} items-center text-center`}>&nbsp;</div>
      )}
      {!image && colorFrom && !colorVia && colorTo && (
        <div className={`bg-gradient-to-b from-${colorFrom} to-${colorTo}`}>&nbsp;</div>
      )}
      {!image && colorFrom && colorVia && !colorTo && (
        <div className={`bg-gradient-to-b from-${colorFrom} via-${colorVia}`}>&nbsp;</div>
      )}
      {!image && colorFrom && colorVia && colorTo && (
        <div className={`bg-gradient-to-b from-${colorFrom} via-${colorVia} to-${colorTo}`}>&nbsp;</div>
      )}
      {!image && !colorFrom && (colorVia || colorTo) && <div className="bg-gray-400">&nbsp;</div>}
    </StyledCardWrapper>
  </StyledWrapper>
);

CardBackExample.propTypes = {
  colorFrom: PropTypes.string,
  colorVia: PropTypes.string,
  colorTo: PropTypes.string,
  image: PropTypes.string,
};

CardBackExample.defaultProps = {
  colorFrom: null,
  colorVia: null,
  colorTo: null,
  image: null,
};

export default CardBackExample;
