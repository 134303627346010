/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import Modal, {
  StyledHeading,
  StyledModalContentWrapper,
  StyledButtonsWrapper,
} from 'components/molecules/Modal/Modal';
import ModalButton from 'components/atoms/ModalButton/ModalButton';
import Paragraph from 'components/atoms/Paragraph';
import { createOrder, clearState, ordersSelector } from 'features/leader/orders/OrdersSlice';
import Loader from 'components/atoms/Loader';
import PaysonPayment from 'utils/payson/PaysonPayment';

const StyledParagraph = tw(Paragraph)`
  text-gray-600
`;

const StyledGameTypesParagraph = tw(Paragraph)`
  text-gray-600
  mt-4
  mb-1
`;

const StyledSpanBold = tw.span`
  font-semibold
`;

const StyledListItem = tw.li`
  text-gray-600
  text-sm
  list-disc
  list-inside
`;

const BuyPackagePreOrderModal = ({ open, setOpen, packageToBuy }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orders = useSelector(ordersSelector);

  const { isSuccess, isError, isFetching, errorMessage } = orders;

  const [showPaysonPayment, setShowPaysonPayment] = useState(false);
  const [orderPayload, setOrderPayload] = useState({});

  const { idFromPaymentGateway } = orderPayload;

  const { _id, name, subscriptionValidityPeriod, taxRate, unitPrice, currency, gamesCount, gameTypes } = packageToBuy;

  const handleCreateOrder = () => {
    dispatch(createOrder(_id)).then(({ payload }) => {
      setOrderPayload(payload);
      setShowPaysonPayment(true);
    });
  };

  useEffect(() => () => dispatch(clearState()), []);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  return (
    <Modal open={open} close={setOpen} scroll>
      <StyledModalContentWrapper>
        {isFetching && <Loader />}
        {!showPaysonPayment && !isFetching && (
          <>
            <StyledHeading as="h3">{t('pre_order_modal_heading')}:</StyledHeading>
            <StyledParagraph>
              {t('pre_order_modal_package_name')}: <StyledSpanBold>{name}</StyledSpanBold>
            </StyledParagraph>
            <StyledParagraph>
              {t('pre_order_modal_games_count')}: <StyledSpanBold>{gamesCount}</StyledSpanBold>
            </StyledParagraph>
            <StyledParagraph>
              {t('pre_order_modal_subscription_validity_period')}:{' '}
              <StyledSpanBold>
                {subscriptionValidityPeriod} {t('pre_order_modal_subscription_validity_period_days')}
              </StyledSpanBold>
            </StyledParagraph>
            <StyledParagraph>
              {t('pre_order_modal_price')}:{' '}
              <StyledSpanBold>
                {unitPrice} <span className="uppercase">{currency}</span>
              </StyledSpanBold>
            </StyledParagraph>
            <StyledGameTypesParagraph>{t('pre_order_modal_game_types_in_package')}:</StyledGameTypesParagraph>
            <ul>
              {gameTypes.map((gameType) => (
                <StyledListItem>{gameType.name}</StyledListItem>
              ))}
            </ul>
          </>
        )}
        {showPaysonPayment && !isFetching && (
          <>
            <StyledHeading as="h3">{t('pre_order_modal_heading_pay')}:</StyledHeading>
            <PaysonPayment idFromPaymentGateway={idFromPaymentGateway} />
          </>
        )}
      </StyledModalContentWrapper>
      <StyledButtonsWrapper>
        {showPaysonPayment ? (
          <ModalButton type="button" onClick={() => setOpen(false)}>
            {t('pre_order_modal_button_cancel')}
          </ModalButton>
        ) : (
          <>
            <ModalButton type="button" onClick={() => setOpen(false)}>
              {t('pre_order_modal_button_cancel')}
            </ModalButton>
            <ModalButton type="submit" color="blue" onClick={handleCreateOrder}>
              {t('pre_order_modal_button_go_to_payment')}
            </ModalButton>
          </>
        )}
      </StyledButtonsWrapper>
    </Modal>
  );
};

BuyPackagePreOrderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  packageToBuy: PropTypes.node.isRequired,
};

export default BuyPackagePreOrderModal;
