/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { isLoginAsAdmin, isLoginAsLeader } from 'features/auth/isLogin';
import { routes } from 'routes';

const PublicRoute = ({ component: Component, restricted, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isLoginAsAdmin() && restricted) {
        return <Redirect to={routes.adminBoard.cards} />;
      }
      if (isLoginAsLeader() && restricted) {
        return <Redirect to={routes.leaderBoard.games} />;
      }
      return <Component {...props} />;
    }}
  />
);

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
  restricted: PropTypes.bool,
  rest: PropTypes.node,
};

PublicRoute.defaultProps = {
  restricted: false,
  rest: null,
};

export default PublicRoute;

// restricted ? isLoginAsAdmin() &&
