import React from 'react';
import tw from 'twin.macro';
import heroImage from 'assets/images/hero-image.png';

const StyledWrapper = tw.div`
    w-1/3
    mb-10
    md:mb-0
`;

const Image = tw.img`
    rounded
    mx-auto
`;

const HeroImage = () => (
  <StyledWrapper>
    <Image src={heroImage} alt="Welcome" />
  </StyledWrapper>
);

export default HeroImage;
