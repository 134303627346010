import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { StyledCard, StyledCardWrapper } from 'components/molecules/Card/ShownCard/ShownCard';

const StyledImage = tw.div`
    relative
    w-6
    h-6
    md:w-full
    md:h-full
    bg-center
    bg-contain
    bg-no-repeat
`;
const StyledImageWrapper = tw.div`
    md:p-0
    flex
    m-auto
    md:w-full
    md:h-full
`;
const HiddenCard = ({ colorMain, colorFrom, colorVia, colorTo, image }) => (
  <StyledCardWrapper>
    {image && (
      <StyledCard className={`relative bg-${colorMain}`}>
        <StyledImageWrapper>
          <StyledImage style={{ backgroundImage: `url(${image})` }}>&nbsp;</StyledImage>
        </StyledImageWrapper>
      </StyledCard>
    )}
    {!image && colorFrom && !colorVia && !colorTo && <StyledCard className={`bg-${colorFrom}`}>&nbsp;</StyledCard>}
    {!image && colorFrom && !colorVia && colorTo && (
      <StyledCard className={`bg-gradient-to-b from-${colorFrom} to-${colorTo}`}>&nbsp;</StyledCard>
    )}
    {!image && colorFrom && colorVia && !colorTo && (
      <StyledCard className={`bg-gradient-to-b from-${colorFrom} via-${colorVia}`}>&nbsp;</StyledCard>
    )}
    {!image && colorFrom && colorVia && colorTo && (
      <StyledCard className={`bg-gradient-to-b from-${colorFrom} via-${colorVia} to-${colorTo}`}>&nbsp;</StyledCard>
    )}
    {!image && !colorFrom && (colorVia || colorTo) && <StyledCard className="bg-gray-400">&nbsp;</StyledCard>}
  </StyledCardWrapper>
);

HiddenCard.propTypes = {
  colorMain: PropTypes.string,
  colorFrom: PropTypes.string,
  colorVia: PropTypes.string,
  colorTo: PropTypes.string,
  image: PropTypes.string,
};

HiddenCard.defaultProps = {
  colorMain: null,
  colorFrom: null,
  colorVia: null,
  colorTo: null,
  image: null,
};

export default HiddenCard;
