import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { Switch } from '@headlessui/react';

const StyledWrapper = tw.div`
    flex
    items-center
`;

const StyledSwitchLabel = tw(Switch.Label)`
    mr-4
    my-4
    text-sm
    text-gray-500
`;

const Toggle = ({ enabled, setEnabled }) => (
  <Switch.Group>
    <StyledWrapper>
      <StyledSwitchLabel>Inactive / Active</StyledSwitchLabel>
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={`${
          enabled ? 'bg-blue-600' : 'bg-gray-200'
        } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
      >
        <span
          className={`${
            enabled ? 'translate-x-6' : 'translate-x-1'
          } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
        />
      </Switch>
    </StyledWrapper>
  </Switch.Group>
);

Toggle.propTypes = {
  enabled: PropTypes.bool.isRequired,
  setEnabled: PropTypes.func.isRequired,
};

export default Toggle;
