/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { gameDataSelector } from 'features/game/GameSlice';
import styled from 'styled-components';
import HiddenCard from './HiddenCard/HiddenCard';
import ShowedCard from './ShownCard/ShownCard';
import MyGiftCardMenu from './MyGiftCardMenu/MyGiftCardMenu';
import Popover from '../Popover/Popover';
import CardMenu from './CardMenu/CardMenu';

const StyledWrapper = styled.div(({ inModal }) => [
  tw`
  relative
  inline-block
  w-full
  rounded
  bg-white
  h-9
  lg:h-full
  `,
  inModal && tw`md:h-full md:max-w-[10rem]`,
]);

const Card = ({ card, visible, myGiftCard, otherPlayerGiftCard, inModal }) => {
  const { _id: cardId, cardType: cardTypeId, translations } = card || {};

  const { cardTypes, firstLanguage, secondLanguage } = useSelector(gameDataSelector);

  const firstText =
    translations &&
    translations.map((translation) => {
      if (firstLanguage && translation.language === firstLanguage._id) {
        return translation.text;
      }
      return null;
    });

  const secondText =
    translations &&
    translations.map((translation) => {
      if (secondLanguage && translation.language === secondLanguage._id) {
        return translation.text;
      }
      return null;
    });

  const cardType = cardTypes.find((cardTypeFromAllCardTypes) => cardTypeFromAllCardTypes._id === cardTypeId);

  const {
    backCardColorFrom,
    backCardColorVia,
    backCardColorTo,
    frontCardColor,
    image,
    textColor,
    name: cardTypeName,
  } = cardType || {};

  return (
    <Popover
      content={
        myGiftCard && (
          <MyGiftCardMenu cardId={cardId} title={firstText} titleBackground={frontCardColor} titleColor={textColor} />
        )
      }
      target={
        <StyledWrapper inModal={inModal}>
          {visible ? (
            <ShowedCard colorMain={frontCardColor} text={firstText} textSecond={secondText} textColor={textColor} />
          ) : (
            <HiddenCard
              colorMain={frontCardColor}
              colorFrom={backCardColorFrom}
              colorVia={backCardColorVia}
              colorTo={backCardColorTo}
              image={image}
              text={cardTypeName}
              textColor={textColor}
            />
          )}
        </StyledWrapper>
      }
    />
  );
};

Card.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  card: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  myGiftCard: PropTypes.bool,
  otherPlayerGiftCard: PropTypes.bool,
  inModal: PropTypes.bool,
};

Card.defaultProps = {
  visible: false,
  myGiftCard: false,
  otherPlayerGiftCard: false,
  inModal: false,
};

export default Card;
