import { configureStore } from '@reduxjs/toolkit';
import { cardsSlice } from 'features/admin/cards/CardSlice';
import { cardTypesSlice } from 'features/admin/cardTypes/CardTypesSlice';
import { categoriesSlice } from 'features/admin/categories/CategoriesSlice';
import { gameTypesSlice } from 'features/admin/gameTypes/GameTypesSlice';
import { languagesSlice } from 'features/admin/languages/LanguagesSlice';
import { gamesPackagesSlice } from 'features/admin/packages/PackagesSlice';
import { usersSlice } from 'features/admin/users/UsersSlice';
import { authSlice } from 'features/auth/AuthSlice';
import { gameSlice } from 'features/game/GameSlice';
import { playerBoardSlice } from 'features/game/PlayerBoardSlice';
import { leaderGamesSlice } from 'features/leader/gamesList/LeaderGamesSlice';
import { ordersSlice } from 'features/leader/orders/OrdersSlice';
import { packagesToBuySlice } from 'features/leader/packagesToBuy/PackagesToBuySlice';

export default configureStore({
  reducer: {
    user: authSlice.reducer,
    cardTypes: cardTypesSlice.reducer,
    gameTypes: gameTypesSlice.reducer,
    languages: languagesSlice.reducer,
    cards: cardsSlice.reducer,
    leaderGames: leaderGamesSlice.reducer,
    game: gameSlice.reducer,
    playerBoard: playerBoardSlice.reducer,
    packagesToBuy: packagesToBuySlice.reducer,
    categories: categoriesSlice.reducer,
    gamesPackages: gamesPackagesSlice.reducer,
    orders: ordersSlice.reducer,
    users: usersSlice.reducer,
  },
});
