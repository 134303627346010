import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store/store';
import { Toaster } from 'react-hot-toast';
import Root from 'views/Root';
import './index.css';
import SocketContext, { socket } from 'socket/socket';

ReactDOM.render(
  <React.StrictMode>
    <SocketContext.Provider value={socket}>
      <Provider store={store}>
        <Root />
        <Toaster />
      </Provider>
    </SocketContext.Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
