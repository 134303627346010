import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import ReactFileReader from 'react-file-reader';
import { useTranslation } from 'react-i18next';
import 'translations/i18n';
import { UpoloadIcon } from 'theme/myIcons';
import { XIcon } from '@heroicons/react/solid';
import transparentBackground from '../../../assets/images/transparent-background.jpg';

const StyledWrapper = styled.div(() => [
  `background-image: url(${transparentBackground});`,
  tw`
    bg-contain
    mt-1
    flex
    justify-center
    px-6
    pt-5
    pb-6
    border-2
    border-gray-300
    border-dashed
    rounded-md
`,
]);

const StyledLabel = tw.label`
    relative
    cursor-pointer
    bg-white
    rounded-md
    font-medium
    text-indigo-600
    hover:text-indigo-500
    focus-within:outline-none
    focus-within:ring-2
    focus-within:ring-offset-2
    focus-within:ring-indigo-500
    mx-auto
`;

const ImageWrapper = tw.div`
    relative
    block
    max-h-52
`;

const StyledImage = tw.img`
    max-h-52
`;

const DeleteButton = tw.div`
    absolute
    top-0
    right-0
    h-6
    w-6
    -mt-2
    -mr-2
    border-2
    border-white
    rounded-full
    bg-red-500
    z-30
`;

const ImageUploadBox = ({ image, setImage }) => {
  const { t } = useTranslation();
  const handleFileUpload = (file) => {
    setImage(file.base64);
  };

  const handleDeleteImage = () => {
    setImage('');
  };

  return (
    <StyledWrapper>
      <div className="space-y-1 text-center">
        {image ? (
          <ImageWrapper>
            <StyledImage src={image} alt="" />
            <DeleteButton onClick={handleDeleteImage}>
              <XIcon className="h-4 w-4 mt-0.5 mx-auto text-white " />
            </DeleteButton>
          </ImageWrapper>
        ) : (
          <>
            <UpoloadIcon />
            <div className="flex text-sm text-gray-600">
              <StyledLabel htmlFor="file-upload" StyledLabel>
                <ReactFileReader fileTypes={['.jpg', '.png']} base64 handleFiles={handleFileUpload}>
                  <span>{t('card_type_modal_image_upload_text')}</span>
                </ReactFileReader>
                <input id="image-upload" name="image-upload" type="file" className="sr-only" />
              </StyledLabel>
            </div>
            <p className="text-xs text-gray-500">{t('card_type_modal_allowed_formats')}</p>
          </>
        )}
      </div>
    </StyledWrapper>
  );
};

ImageUploadBox.propTypes = {
  image: PropTypes.string.isRequired,
  setImage: PropTypes.func.isRequired,
};

export default ImageUploadBox;
