/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UsersAPI from './UsersAPI';

export const findUsers = createAsyncThunk('users/find', async (thunkAPI) => {
  try {
    const response = await UsersAPI.find();

    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const deleteUser = createAsyncThunk('users/delete', async ({ id }, thunkAPI) => {
  try {
    const response = await UsersAPI.remove({ userId: id });

    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const addPackageToUser = createAsyncThunk(
  'users/addPackageToUser',
  async ({ userId, gamesPackageId }, thunkAPI) => {
    try {
      const response = await UsersAPI.addPackageToUser({
        userId,
        gamesPackageId,
      });
      const { data } = response;
      if (response.status === 201) {
        return data;
      }
      return thunkAPI.rejectWithValue(data);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    items: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [findUsers.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findUsers.pending]: (state) => {
      state.isFetching = true;
    },
    [findUsers.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [deleteUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = state.items.filter((item) => item._id !== payload._id);
    },
    [deleteUser.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
  },
});

export const { clearState } = usersSlice.actions;

export const usersSelector = (state) => state.users;
