import React from 'react';
import tw from 'twin.macro';
import { Menu } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';

const StyledMenuButton = tw(Menu.Button)`
    inline-flex
    justify-center
    bg-green-500
    text-white
    py-2
    px-2
    w-12
    h-12
    rounded-full
    items-center
    text-center
    focus:outline-none
    z-50

`;

const CornerButton = () => (
  <div>
    <StyledMenuButton>
      <DotsHorizontalIcon className="h-4 w-4 my-auto" aria-hidden="true" />
    </StyledMenuButton>
  </div>
);

export default CornerButton;
