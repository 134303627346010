import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import Header from 'components/organisms/Header/Header';
import AdminBoardMenu from 'components/molecules/AdminBoardMenu/AdminBoardMenu';

const StyledWrapper = tw.section`
    my-auto
    p-1
    relative
    block
    items-center
    h-full
    overflow-scroll
`;

const StyledContainer = tw.div`
    container
    px-5
    py-5
    mx-auto
    mb-8
    bg-white
    bg-opacity-90
    rounded
    flex
    flex-row
    flex-wrap
`;

const StyledLeaderBoardWrapper = tw.div`
    w-full
    lg:pl-4
    lg:py-6
    mb-6
    lg:mb-0
`;

const AdminTemplate = ({ children }) => (
  <div className="bg-wood bg-cover bg-center h-screen w-screen flex flex-col">
    <Header />
    <StyledWrapper>
      <StyledContainer>
        <StyledLeaderBoardWrapper>
          <AdminBoardMenu />
          {children}
        </StyledLeaderBoardWrapper>
      </StyledContainer>
    </StyledWrapper>
  </div>
);

AdminTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AdminTemplate;

export const SingleItemWrapper = tw.div`
  flex
  border-b
  border-gray-300
  py-2
  leading-8
  items-center
`;

export const HeaderItemWrapper = tw.div`
  flex
  border-b
  border-gray-500
  py-1
  mb-2
  leading-8
  items-center
`;
