import React, { useContext } from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import SocketContext from 'socket/socket';

const StyledWrapper = styled.div(({ cardTypesCount }) => [
  tw`
    h-full
    py-1
    mx-auto
    shadow-xl
    w-4/5
    sm:w-2/4
  `,
  cardTypesCount === 1 && tw`lg:w-1/6`,
  cardTypesCount === 2 && tw`lg:w-1/3`,
  cardTypesCount === 3 && tw`lg:w-1/2`,
]);

const StyledCard = tw.div`
    px-1
    py-2
    my-auto
    rounded-lg
    h-full
    shadow-xl
    2xl:ring-offset-2
    ring-offset-1
    ring-offset-white
    ring-opacity-80
    2xl:ring-2
    ring-1
    bg-center
    bg-contain
    bg-no-repeat
`;

const DeckCard = ({ mainColor, _id, colorFrom, colorVia, colorTo, image, cardTypesCount, onlyWatch }) => {
  const socket = useContext(SocketContext);

  const handleTakeCardClick = () => {
    if (!onlyWatch) {
      socket.emit('takeCardFromDeck', { cardTypeId: _id });
    }
  };
  return (
    <StyledWrapper onClick={handleTakeCardClick} cardTypesCount={cardTypesCount}>
      {image && (
        <StyledCard className={`bg-${mainColor}`} style={{ backgroundImage: `url(${image})` }}>
          &nbsp;
        </StyledCard>
      )}
      {!image && colorFrom && !colorVia && !colorTo && (
        <StyledCard className={`bg-${colorFrom} items-center text-center`}>&nbsp;</StyledCard>
      )}
      {!image && colorFrom && !colorVia && colorTo && (
        <StyledCard className={`bg-gradient-to-b from-${colorFrom} to-${colorTo}`}>&nbsp;</StyledCard>
      )}
      {!image && colorFrom && colorVia && !colorTo && (
        <StyledCard className={`bg-gradient-to-b from-${colorFrom} via-${colorVia}`}>&nbsp;</StyledCard>
      )}
      {!image && colorFrom && colorVia && colorTo && (
        <StyledCard className={`bg-gradient-to-b from-${colorFrom} via-${colorVia} to-${colorTo}`}>&nbsp;</StyledCard>
      )}
      {!image && !colorFrom && (colorVia || colorTo) && <StyledCard className="bg-gray-400">&nbsp;</StyledCard>}
    </StyledWrapper>
  );
};

DeckCard.propTypes = {
  mainColor: PropTypes.string,
  _id: PropTypes.string.isRequired,
  colorFrom: PropTypes.string,
  colorVia: PropTypes.string,
  colorTo: PropTypes.string,
  image: PropTypes.string,
  cardTypesCount: PropTypes.number,
  onlyWatch: PropTypes.bool,
};

DeckCard.defaultProps = {
  mainColor: PropTypes.string,
  colorFrom: null,
  colorVia: null,
  colorTo: null,
  image: null,
  cardTypesCount: 2,
  onlyWatch: false,
};

export default DeckCard;
