/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import OrdersAPI from './OrdersAPI';

export const createOrder = createAsyncThunk('orders/create', async (packageId, thunkAPI) => {
  try {
    const response = await OrdersAPI.create({
      packageId,
    });
    const { data } = response;
    if (response.status === 201) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const findOrderById = createAsyncThunk('orders/findById', async (id, thunkAPI) => {
  try {
    const response = await OrdersAPI.getOrderById(id);
    const { data } = response;
    if (response.status === 200) {
      return data;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const findUserOrders = createAsyncThunk('orders/findUserOrders', async (thunkAPI) => {
  try {
    const response = await OrdersAPI.getUserOrders();
    const { data } = response;
    if (response.status === 200) {
      const userOrders = _.orderBy(data, 'createdDate', 'desc');
      return userOrders;
    }
    return thunkAPI.rejectWithValue(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    items: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createOrder.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = [...state.items, payload];
    },
    [createOrder.pending]: (state) => {
      state.isFetching = true;
    },
    [createOrder.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [findOrderById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findOrderById.pending]: (state) => {
      state.isFetching = true;
    },
    [findOrderById.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
    [findUserOrders.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.items = payload;
    },
    [findUserOrders.pending]: (state) => {
      state.isFetching = true;
    },
    [findUserOrders.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload ? payload.message : 'Connection Error';
    },
  },
});

export const { clearState } = ordersSlice.actions;

export const ordersSelector = (state) => state.orders;
