import React from 'react';
import PropTypes from 'prop-types';

const ColorExampleSquare = ({ color }) => <div className={`inline h-4 w-3 px-1 border bg-${color} `}>&nbsp;</div>;

ColorExampleSquare.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ColorExampleSquare;
